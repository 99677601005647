import { IonInput, IonIcon, IonGrid, IonRow, IonCol } from "@ionic/react";
import { checkmarkCircle } from "ionicons/icons";
import clsx from "clsx";
import { Text } from "./Text";
import "./Input.scss";
import { Block, BlockProps } from "./Block";
import { useEffect, useRef } from "react";
import Note from "./Note";
import Button, { ButtonProps } from "./Button";

export type InputProps = Omit<
  React.ComponentProps<typeof IonInput>,
  "onChange"
> &
  Pick<BlockProps, "variant"> & {
    label?: React.ReactNode;
    helper?: string;
    error?: string;
    success?: string;
    errorIcon?: boolean;
    successIcon?: boolean;
    loading?: boolean;
    onChange?: (value: string) => void;
    suffixCta?: ButtonProps;
    otherIcon?: string;
    otherIconColor?:
      | "primary"
      | "secondary"
      | "tertiary"
      | "success"
      | "warning"
      | "danger";
    fullWidth?: boolean;
  };

export const Input = ({
  label,
  helper,
  disabled,
  error,
  success,
  loading,
  errorIcon,
  successIcon,
  onChange,
  suffixCta,
  variant,
  otherIcon,
  otherIconColor,
  fullWidth,
  autofocus,
  className = "",
  ...props
}: InputProps) => {
  const passwordInputRef = useRef<HTMLIonInputElement>(null);

  const blockClassName = clsx("ag-input", className, {
    "ion-valid": success && !error,
    "ion-invalid": error,
    "ag-input-full-width": fullWidth,
  });

  // Set focus on the input when the component is rendered
  // (fixes inconsistent behaviour of the autofocus prop)
  useEffect(() => {
    if (autofocus && passwordInputRef.current) {
      const inputToFocus = passwordInputRef.current;

      // Wait for the component to be rendered before setting focus
      // (iOS requires user interaction to open the virtual keyboard, this won't open the keyboard, only set focus)
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          inputToFocus.setFocus();
        });
      });
    }
  }, [autofocus]);

  return (
    <Block inline variant={variant} className={blockClassName}>
      <IonGrid className="ag-input-grid">
        {label && (
          <IonRow>
            <IonCol>
              <Text type="body" size="large" bold>
                {label}
              </Text>
            </IonCol>
          </IonRow>
        )}

        {helper && (
          <IonRow>
            <IonCol>
              <Text type="body" size="medium" shade="grey">
                {helper}
              </Text>
            </IonCol>
          </IonRow>
        )}

        <IonRow>
          <IonCol>
            <IonInput
              className={`ag-input-input ${errorIcon ? "has-error" : ""}`}
              onIonInput={(e) => onChange && onChange(e.detail.value!)}
              disabled={disabled || loading}
              autofocus={autofocus}
              ref={passwordInputRef}
              {...props}
            >
              {errorIcon && (
                <IonIcon
                  className="ag-input-icon ag-input-error"
                  icon="/assets/error-input.svg"
                  slot="end"
                />
              )}
              {successIcon && (
                <IonIcon
                  className="ag-input-icon ag-input-success"
                  icon={checkmarkCircle}
                  slot="end"
                />
              )}
              {otherIcon && (
                <IonIcon
                  className="ag-input-icon ag-input-other"
                  icon={otherIcon}
                  color={otherIconColor}
                  slot="end"
                />
              )}
            </IonInput>
          </IonCol>
          {suffixCta && (
            <IonCol className="ag-input-inline-cta">
              <Button {...suffixCta}>{suffixCta.children}</Button>
            </IonCol>
          )}
        </IonRow>
      </IonGrid>
      {error && (
        <Note slot="error" error className="margin-top-4">
          {error}
        </Note>
      )}
      {success && (
        <Note slot="error" success className="margin-top-4">
          {success}
        </Note>
      )}
    </Block>
  );
};
