import L from "leaflet";
import { propertyInZoneCardBorderColor } from "./zoneColours";

export const propertyBoundsStyle = (
  isInZone: boolean,
  zoneId: number
): L.PathOptions => {
  return {
    color: "#000000",
    weight: 2,
    fillColor: isInZone ? propertyInZoneCardBorderColor[zoneId] : "#3F97FF",
    fillOpacity: isInZone ? 0.4 : 0.2,
  };
};
