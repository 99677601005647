import { useRef } from "react";
import { Landing } from "./Landing";
import { AddressResult, ByAddress } from "./ByAddress";
import {
  AgResponsePage,
  AgResponsePageRef,
} from "../../components/AgResponsePage";
import { FlowFooter, FlowFooterProps } from "../../components/FlowFooter";
import { useEffect, useState } from "react";
import { AddAssets } from "./AddAssets";
import { SwiperChildNullProps, SwiperFlow } from "../../components/SwiperFlow";
import ConfirmAddress from "./ByAddress/ConfirmAddress";
import { useLocation, useHistory } from "react-router-dom";
import AddPropertyDetails from "./AddPropertyDetails";
import { CheckConfirm } from "./CheckConfirm";
import {
  PropertyDetailsWithBoundary,
  nullPropertyDetailsWithBoundary,
} from "../../project-types";
import L from "leaflet";

export const AddPropertyPage = () => {
  const [property, setProperty] = useState<PropertyDetailsWithBoundary>(
    nullPropertyDetailsWithBoundary
  );
  const [nextClicked, setNextClicked] = useState(false);
  const [backClicked, setBackClicked] = useState(false);

  const [selectedAddress, setSelectedAddress] = useState<AddressResult>();
  const [footerProps, setFooterProps] = useState<Partial<FlowFooterProps>>({});

  const [canExit, setCanExit] = useState(false);
  const [slideJump, setSlideJump] = useState<number | undefined>(undefined);
  const [slideJumpReturn, setSlideJumpReturn] =
    useState<number | undefined>(undefined);

  const location = useLocation();
  const history = useHistory();

  const pageRef = useRef<AgResponsePageRef>(null);

  useEffect(() => {
    if (location) {
      const params = new URLSearchParams(location.search);
      if (!params.has("noClose")) {
        setCanExit(true);
      }
    }
  }, [location]);

  const onClickHandled = () => {
    setNextClicked(false);
    setBackClicked(false);

    pageRef.current?.scrollContentToTop();
  };
  return (
    <AgResponsePage
      ref={pageRef}
      title="Add Property"
      hideButton={!canExit}
      hideCtas
      footer={
        <FlowFooter
          onNext={() => {
            setNextClicked(true);
          }}
          onBack={() => {
            setBackClicked(true);
          }}
          {...footerProps}
        />
      }
      buttonType="close"
      onButtonClick={() => {
        history.push("/properties");
      }}
    >
      <SwiperFlow
        updateFooter={setFooterProps}
        nextClicked={nextClicked}
        backClicked={backClicked}
        nextClickHandled={() => {
          if (slideJumpReturn !== undefined) {
            setSlideJump(slideJumpReturn);
            setSlideJumpReturn(undefined);
          }
          onClickHandled();
        }}
        backClickHandled={onClickHandled}
        slideJump={slideJump}
        onSlideJumpHandled={() => {
          setSlideJump(undefined);
        }}
      >
        <Landing {...SwiperChildNullProps} key="landing" />
        <ByAddress
          key="ByAddress"
          {...SwiperChildNullProps}
          onAddressSelected={(address) => {
            setSelectedAddress(address);
            const {
              propertyBounds,
              geo: { geometry },
            } = address;
            setProperty((property) => {
              return {
                ...property,
                ...(propertyBounds
                  ? {
                      boundary: propertyBounds,
                      boundaryIds: propertyBounds.features.map(({ id }) => {
                        return String(id);
                      }),
                    }
                  : {}),
                location: new L.LatLng(
                  geometry.coordinates[1],
                  geometry.coordinates[0]
                ),
              };
            });
          }}
        />
        <ConfirmAddress
          key="ConfirmAddress"
          {...SwiperChildNullProps}
          selectedAddress={selectedAddress}
          onAddressConfirmed={(propertyAddress) => {
            setProperty((property) => {
              return {
                ...property,
                ...propertyAddress,
              };
            });
          }}
        />
        <AddPropertyDetails
          key="AddPropertyDetails"
          {...SwiperChildNullProps}
          onDetailsAdded={(contactDetails) => {
            setProperty((property) => {
              return {
                ...property,
                ...contactDetails,
              };
            });
          }}
          confirmedAddress={property}
        />
        <AddAssets
          key="addToProfile"
          {...SwiperChildNullProps}
          updateHoldings={(holdings) => {
            setProperty((property) => {
              return {
                ...property,
                holdings,
              };
            });
          }}
          nextClicked={nextClicked}
          nextClickHandled={() => {
            onClickHandled();
          }}
        />
        <CheckConfirm
          {...SwiperChildNullProps}
          property={property}
          selectedAddress={selectedAddress}
          onEdit={(targetSlide, returnSlide) => {
            setSlideJump(targetSlide);
            setSlideJumpReturn(returnSlide);
          }}
        />
      </SwiperFlow>
    </AgResponsePage>
  );
};
