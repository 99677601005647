import {
  IonCard,
  IonCardContent,
  IonCol,
  IonGrid,
  IonIcon,
  IonRow,
  IonButton,
} from "@ionic/react";
import "./ZoneSelector.scss";
import { Text } from "./Text";
import { chevronDownOutline, chevronUpOutline } from "ionicons/icons";

type ZoneSelectorProps = {
  zones: Array<{
    zoneTitle: string;
    zoneSubtitle?: string;
    zoneId: number;
  }>;
  selectedButton?: number;
  onZoneButtonSelected: (button: number) => void;
  showToggleCheveron?: boolean;
};

const ZoneSelector = ({
  zones,
  onZoneButtonSelected,
  selectedButton,
  showToggleCheveron = true,
}: ZoneSelectorProps) => {
  return (
    <IonCard className="ag-zone-selector">
      <IonCardContent className="ion-no-padding">
        {zones.map((zone) => (
          <IonButton
            key={zone.zoneId}
            size="large"
            expand="full"
            className={selectedButton === zone.zoneId ? "selected" : ""}
            onClick={() => onZoneButtonSelected(zone.zoneId)}
          >
            <IonGrid className="ion-no-padding">
              <IonRow className="ion-text-end ion-align-items-center">
                <IonCol size="1">
                  <div
                    className={`ag-coloured-circle zone-color-${
                      zone.zoneId - 1
                    }`}
                  />
                </IonCol>
                <IonCol>
                  <Text
                    type="body"
                    size="large"
                    className="ion-text-start zone-name"
                  >
                    <Text size="large" type="body">
                      {zone.zoneTitle}
                    </Text>
                    {zone.zoneSubtitle !== undefined && (
                      <Text size="medium" type="body">
                        {zone.zoneSubtitle}
                      </Text>
                    )}
                  </Text>
                </IonCol>
                {showToggleCheveron && (
                  <IonCol size="1">
                    <IonIcon
                      icon={
                        selectedButton === zone.zoneId
                          ? chevronUpOutline
                          : chevronDownOutline
                      }
                    />
                  </IonCol>
                )}
              </IonRow>
            </IonGrid>
          </IonButton>
        ))}
      </IonCardContent>
    </IonCard>
  );
};

export default ZoneSelector;
