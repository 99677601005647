import { Text } from "../../components/ui";
import { IonCol, IonGrid, IonIcon, IonRow } from "@ionic/react";
import Divider from "../../components/ui/Divider";
import { arrowForwardOutline, callOutline } from "ionicons/icons";
import { Option } from "./settings";
import { BottomSheetContext } from "../../components/ui/BottomSheet";
import { useContext, useEffect } from "react";
import Button from "../../components/ui/Button";

type HelpProps = {
  onLoad: (options: { title: string }) => void;
};

const Help = ({ onLoad }: HelpProps) => {
  const { setBottomSheetProps } = useContext(BottomSheetContext);

  useEffect(() => {
    onLoad({
      title: "Get help",
    });
  }, []);

  const help: Array<Option> = [
    {
      name: "Urgent emergency support",
      info: "Get immediate support by calling the emergency",
      action: () => {
        setBottomSheetProps({
          isOpen: true,
          title: "Emergency hotline",
          children: (
            <>
              <Text type="body" size="large" as="h2">
                For urgent assistance, contact the Emergency Response Hotline,
                operational Monday to Friday 9am - 5pm.
              </Text>
              <Button
                variant="secondary"
                expand="block"
                icon={callOutline}
                href="tel:1800 814 647"
                onClick={() =>
                  setBottomSheetProps({
                    isOpen: false,
                  })
                }
              >
                1800 814 647
              </Button>
            </>
          ),
        });
      },
    },
  ];

  return (
    <>
      {help.map(({ name, action, info }) => (
        <IonGrid key={name}>
          <IonRow onClick={action}>
            <IonCol>
              <Text type="heading" size="small" bold>
                {name}
              </Text>
            </IonCol>
            <IonCol size="2" className="ion-text-right">
              <IonIcon
                icon={arrowForwardOutline}
                style={{ width: "24px", height: "24px" }}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="10">
              <Text type="body" size="small">
                {info}
              </Text>
            </IonCol>
          </IonRow>
          <Divider variant="secondary" />
        </IonGrid>
      ))}
    </>
  );
};

export default Help;
