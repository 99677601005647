import { useEffect, useState } from "react";
import { SwiperFlowChildProps } from "../../components/SwiperFlow";
import { Input, Text } from "../../components/ui";
import {
  PropertyAddressDetails,
  PropertyDetailsWithBoundaryIds,
} from "../../project-types";
import { getUser } from "../../api/user";
import { useHistory } from "react-router-dom";
import { v4 } from "uuid";

type AddPropertyDetailsProps = SwiperFlowChildProps & {
  onDetailsAdded: (
    address: Pick<
      PropertyDetailsWithBoundaryIds,
      | "primaryContactEmail"
      | "primaryContactName"
      | "primaryContactPhone"
      | "propertyId"
      | "name"
    >
  ) => void;
  confirmedAddress: PropertyAddressDetails;
};

const AddPropertyDetails = ({
  active,
  nextClickHandled,
  nextClicked,
  onStepFinished,
  updateFooter,
  visible,
  confirmedAddress,
  onDetailsAdded,
}: AddPropertyDetailsProps) => {
  const [addressFields, setAddressFields] = useState<PropertyAddressDetails>({
    name: "",
    address: "",
    city: "",
    postcode: "",
    location: {
      lat: 0,
      lng: 0,
    },
  });

  const history = useHistory();

  useEffect(() => {
    if (confirmedAddress) {
      setAddressFields(confirmedAddress);
    }
  }, [confirmedAddress]);

  useEffect(() => {
    if (active && visible) {
      updateFooter({
        disableNext: true,
      });

      if (addressFields) {
        if (addressFields.name !== "") {
          updateFooter({
            disableNext: false,
          });
        } else {
          updateFooter({
            disableNext: true,
          });
        }
      }
    }
  }, [active, visible, addressFields]);

  useEffect(() => {
    if (active && visible && nextClicked) {
      getUser().then((user) => {
        if (user === undefined) {
          history.push("/login");
          return;
        }
        const { email, firstName, lastName, phone } = user;
        onDetailsAdded({
          primaryContactEmail: email,
          primaryContactName: `${firstName} ${lastName}`,
          primaryContactPhone: phone,
          propertyId: v4(),
          name: addressFields.name,
        });
        onStepFinished();
        nextClickHandled();
      });
    }
  }, [nextClicked]);

  return (
    <div>
      <Text size="large" type="body">
        Step 3 of 5
      </Text>

      <div className="margin-left-4">
        <Text
          type="display"
          size="small"
          as="h2"
          bold
          className="margin-top-16 margin-bottom-32"
        >
          Give your property a name
        </Text>

        <Text type="body" size="large" bold as="p" className="margin-bottom-0">
          Nickname
        </Text>
        <Text
          type="body"
          size="medium"
          as="p"
          className="margin-top-8 margin-bottom-32"
        >
          Make it easier to identify your property.
        </Text>
        <Text type="body" size="medium" as="p" className="margin-y-8">
          You can write anything here, like 'Home' or 'North Fields'
        </Text>
      </div>
      <Input
        value={addressFields.name}
        placeholder="Enter nickname"
        onChange={(value) => {
          setAddressFields({
            ...addressFields,
            name: value,
          });
        }}
      />
    </div>
  );
};

export default AddPropertyDetails;
