import { cachedGet } from "./offlineCacher";
import {
  DiseaseInfoDetails,
  EmergencyDetails,
  GlobalContentDetails,
  LatestUpdatesDetails,
  SupportDetails,
} from "../project-types";
import axios from "axios";
import { REACT_APP_API_URL } from "../env";

export const getEmergencies = () => {
  return cachedGet<
    Array<
      Pick<
        EmergencyDetails,
        "id" | "emergencyTitle" | "assets" | "updated" | "mapData"
      >
    >
  >(`${process.env["REACT_APP_API_URL"]}/emergency`, true);
};

export const getEmergencyDetails = (id: string) => {
  return cachedGet<EmergencyDetails>(
    `${REACT_APP_API_URL}/cms/emergency/${id}`,
    true
  );
};

export const getDiseaseInfo = (id: string) => {
  return cachedGet<DiseaseInfoDetails>(
    `${REACT_APP_API_URL}/cms/emergency/${id}/disease-info`,
    true
  );
};

export const getSupport = (id: string) => {
  return cachedGet<SupportDetails>(
    `${REACT_APP_API_URL}/cms/emergency/${id}/support`,
    true
  );
};

export const getLatestUpdates = (id: string) => {
  return cachedGet<LatestUpdatesDetails>(
    `${REACT_APP_API_URL}/cms/emergency/${id}/latest-updates`,
    true
  );
};

export const getGlobalContent = async () => {
  try {
    const result = await axios.get<GlobalContentDetails>(
      `${REACT_APP_API_URL}/cms/global`
    );
    return result.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};
