import axios from "axios";
import { openDB } from "idb";
import { getJWT } from "./localStorage";

export type CacheAndLiveData<T> = {
  cache: Promise<T | undefined>;
  live: Promise<T | undefined>;
};

const storeName = "offlineStore";

const dbPromise = openDB("data.offline", 1, {
  upgrade(db) {
    db.createObjectStore(storeName, {
      keyPath: "key",
    });
  },
});

async function get(key: string) {
  return (await dbPromise).get(storeName, key);
}

async function set(key: string, val: any) {
  return (await dbPromise).put(storeName, {
    ...val,
    fetchTime: new Date().getTime(),
    key,
  });
}

export async function count() {
  return (await dbPromise).count(storeName);
}

export async function listKeys() {
  return (await dbPromise).getAllKeys(storeName);
}
export const cachedGet = <T>(
  url: string,
  isPublicRoute = false
): CacheAndLiveData<T> => {
  const cached = get(url);

  const live = async () => {
    const token = await getJWT()
      .then((token) => token)
      .catch(() => undefined);
    if (!token && !isPublicRoute) return undefined;

    //get the data from the server add the JWT token to the request

    const response = await axios
      .get<T>(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          set(url, { data: response.data });
        }
        return response.data;
      })
      .catch((e) => {
        console.error(e);
        return undefined;
      });
    return response;
  };

  return {
    cache: cached.then((val) => {
      return val ? val.data : undefined;
    }),
    live: live(),
  };
};

export const clearKey = async (key: string) => {
  return (await dbPromise).delete(storeName, key);
};

export const clearCache = async () => {
  return (await dbPromise).clear(storeName);
};
