import EditDetailsCard from "../../components/ui/EditDetailsCard";
import { UserDetails } from "../../project-types";
import { useEffect } from "react";
import { Card, Text } from "../../components/ui";
import FullscreenLoader from "../../components/ui/FullscreenLoader";

type PersonalInformationProps = {
  user: UserDetails;
  onUpdate: (updatedUser: Partial<UserDetails>) => void;
  loading: boolean;
  editContact: boolean;
  onEdit: (editEnabled: boolean) => void;
  onLoad: (options: { title: string }) => void;
};

const PersonalInformation = ({
  onUpdate,
  user,
  loading,
  editContact,
  onEdit,
  onLoad,
}: PersonalInformationProps) => {
  const contactDetails: Pick<
    UserDetails,
    "firstName" | "lastName" | "phone" | "contactAddress"
  > = {
    firstName: user.firstName,
    lastName: user.lastName,
    phone: user.phone,
    contactAddress: user.contactAddress,
  };

  useEffect(() => {
    onLoad({
      title: "Personal information",
    });
  }, []);

  return (
    <>
      {user && (
        <EditDetailsCard<typeof contactDetails>
          onSave={(modifiedData) => {
            onLoad({ title: "Edit contact details" });
            onUpdate(modifiedData);
            onEdit(false);
          }}
          onEdit={() => onEdit(true)}
          title="Contact details"
          editableFields={contactDetails}
          labels={{
            firstName: "First name",
            lastName: "Last name",
            phone: "Phone number",
            contactAddress: "Address",
          }}
          setEditMode={editContact}
          editModeChildren={
            user.snswId === undefined || user.snswId === "" ? null : (
              <Card title="Personal details" variant="info">
                <Text size="medium" type="body">
                  We’ve pre-filled your personal details from your MyServiceNSW
                  Account. Any changes you make here will not update your
                  MyServiceNSW Account.
                </Text>
              </Card>
            )
          }
        />
      )}
      {loading && <FullscreenLoader loadingMessage="Updating..." />}
    </>
  );
};

export default PersonalInformation;
