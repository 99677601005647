import { AgMap, ForwardedMapRef } from "../../components/AgMap";
import { GeoJSON, Marker } from "react-leaflet";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Polygon, MultiPolygon } from "geojson";
import Buffer from "@turf/buffer";
import PointInPolygon from "@turf/boolean-point-in-polygon";
import { point, featureCollection } from "@turf/helpers";
import { IonCol, IonGrid, IonIcon, IonRow } from "@ionic/react";
import { Card, Text } from "../../components/ui";
import { BottomSheetContext } from "../../components/ui/BottomSheet";
import Button from "../../components/ui/Button";
import {
  arrowForward,
  informationCircleOutline,
  navigateOutline,
  navigateCircle,
} from "ionicons/icons";
import {
  propertyInZoneCardColor,
  zoneColors,
  propertyInZoneCardBorderColor,
} from "../../theme/zoneColours";
import { RouteComponentProps, useHistory } from "react-router-dom";
import {
  EmergencyDetails,
  PropertyDetailsWithBoundary,
  ZoneDetails,
} from "../../project-types";
import "./../../components/emergency/Map.scss";
import { LatLng } from "leaflet";
import booleanIntersects from "@turf/boolean-intersects";
import { icons } from "../../components/AgMap";

import "./Map.scss";
import { PropertyOnMap } from "../../components/propertyOnMap";

import { isPropertyAffected } from "../../utils/properties";
import FullscreenLoader from "../../components/ui/FullscreenLoader";
import { useWatchPosition } from "@capacitor-community/geolocation-react";
import { Geolocation } from "@capacitor/geolocation";
import { SnackbarHandlerContext } from "../../components/SnackbarHandler";

export type MapProps = {
  scrollAndSelectZone: (zoneNumber: number) => void;
  emergencyData: EmergencyDetails | undefined;
  properties: PropertyDetailsWithBoundary[] | undefined;
};

type AugmentedPropertiesData = PropertyDetailsWithBoundary & {
  zoneIndex: number;
  isAffected: boolean;
};

const FLYTO_ZOOM = 13;
const FLYTO_DURATION = 0.5;

const Map: React.FC<MapProps & RouteComponentProps<{ id: string }>> = ({
  match,
  scrollAndSelectZone,
  emergencyData,
  properties,
}) => {
  const [mapFocus, setMapFocus] = useState<GeoJSON.FeatureCollection>();
  const { setBottomSheetProps } = useContext(BottomSheetContext);
  const { showDismissableSnackbar } = useContext(SnackbarHandlerContext);
  const [augmentedProperties, setAugmentedProperties] =
    useState<Array<AugmentedPropertiesData>>();

  const [gpsState, setGpsState] = useState<"on" | "loading" | "off">("off");

  const mapRef = useRef<ForwardedMapRef>(null);
  const history = useHistory();
  const { id } = match.params;

  const { currentPosition, startWatch, clearWatch } = useWatchPosition();

  const [gpsPin, setGpsPin] = useState<LatLng | undefined>(
    currentPosition &&
      new LatLng(
        currentPosition?.coords?.latitude,
        currentPosition?.coords?.longitude
      )
  );

  if (gpsState === "loading" && !!gpsPin && mapRef?.current?.canFlyTo()) {
    mapRef.current.flyTo(gpsPin, FLYTO_ZOOM, {
      duration: FLYTO_DURATION,
    });
    setGpsState("on");
  }

  useEffect(() => {
    if (properties && emergencyData) {
      const augmentedProperties = properties
        .map((property) => {
          const zoneIndex: number = emergencyData.mapData
            ? emergencyData.mapData.features.reduce((acc, zone) => {
                if (property.boundary && property.boundary.features) {
                  const intersectsZone = property.boundary.features.reduce(
                    (acc, bound) => {
                      if (acc) {
                        return acc;
                      }
                      const intersects = booleanIntersects(
                        bound,
                        zone.geometry
                      );
                      return intersects;
                    },
                    false
                  );

                  const zoneNo = intersectsZone
                    ? Math.min(zone.properties.zoneType - 1, acc)
                    : acc;
                  return zoneNo;
                }

                return PointInPolygon(
                  point([property.location.lng, property.location.lat]),
                  zone.geometry
                )
                  ? zone.properties.zoneType - 1
                  : acc;
              }, 999)
            : 999;

          return {
            ...property,
            zoneIndex,
          };
        })
        .map((property) => {
          const isAffected =
            property.zoneIndex < 999 &&
            isPropertyAffected(property, emergencyData);

          return {
            ...property,
            isAffected,
          };
        });

      setAugmentedProperties(augmentedProperties);

      const visualBounds = featureCollection<Polygon | MultiPolygon>([
        ...properties
          .map((property) => {
            if (property.boundary && property.boundary.features) {
              return property.boundary.features;
            }
            const buffered = Buffer(
              point([property.location.lng, property.location.lat]),
              0.25,
              { units: "kilometers" }
            );
            return buffered;
          })
          .flat(),
        ...(emergencyData.mapData ? emergencyData.mapData.features : []),
      ]);

      setMapFocus(visualBounds);
    }
  }, [properties, emergencyData]);

  useEffect(() => {
    switch (gpsState) {
      case "off":
        clearWatch();
        break;
    }

    return () => {
      clearWatch();
    };
  }, [gpsState]);

  const showEmergencyBottomSheet = useCallback(
    (property: AugmentedPropertiesData, zoneDetails: ZoneDetails) => {
      setBottomSheetProps({
        isOpen: true,
        title: property.name,
        children: (
          <>
            {!property.isAffected ? (
              <Text type="body" size="large">
                This property does not have animals or crops that are impacted
                by this emergency
              </Text>
            ) : (
              <>
                <Card
                  borderColor={
                    propertyInZoneCardBorderColor[property.zoneIndex]
                  }
                  customColor={propertyInZoneCardColor[property.zoneIndex]}
                >
                  <Text type="heading" size="small" bold as="h2">
                    {`This property is part of the ${zoneDetails["zone-title"]}`}
                  </Text>
                </Card>
                <Button
                  className="margin-top-32"
                  expand="block"
                  iconPosition="end"
                  icon={arrowForward}
                  onClick={() => {
                    setBottomSheetProps({
                      isOpen: false,
                    });
                    scrollAndSelectZone(zoneDetails.zoneId);
                    history.push(`/emergency/${id}`);
                  }}
                >
                  View zone information
                </Button>
              </>
            )}
          </>
        ),
      });
    },
    []
  );

  return (
    <>
      {!emergencyData && <FullscreenLoader />}
      {emergencyData && (
        <>
          <IonGrid className="leaflet-bottom ag-zoom-to-me">
            <IonRow className="leaflet-control ion-justify-content-between">
              <IonCol size="auto">
                <Button
                  variant="light"
                  onClick={() => {
                    setBottomSheetProps({
                      isOpen: true,
                      children: (
                        <>
                          {emergencyData?.zones.map((zone) => (
                            <Card
                              key={`${zone["zone-title"]}--${zone.zoneId}`}
                              variant="secondary"
                              borderColor={
                                propertyInZoneCardBorderColor[zone.zoneId - 1]
                              }
                              cardOnClickAction={() => {
                                setBottomSheetProps({
                                  isOpen: false,
                                });
                                setTimeout(
                                  () =>
                                    setBottomSheetProps({
                                      isOpen: true,
                                      title: zone["zone-title"],
                                      children: (
                                        <>
                                          <Text type="body" size="large">
                                            <span
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  zone["zone-description"],
                                              }}
                                            />
                                          </Text>
                                          <Button
                                            expand="block"
                                            onClick={() => {
                                              setBottomSheetProps({
                                                isOpen: false,
                                              });
                                              scrollAndSelectZone(zone.zoneId);
                                              history.push(`/emergency/${id}`);
                                            }}
                                          >
                                            View zone information
                                          </Button>
                                        </>
                                      ),
                                    }),
                                  300
                                );
                              }}
                            >
                              <IonGrid className="ion-no-padding legend-cards">
                                <IonRow>
                                  <IonCol size="1">
                                    <div
                                      className={`colored-circle color-${zone.zoneId}`}
                                    />
                                  </IonCol>
                                  <IonCol className="margin-left-8">
                                    <Text
                                      type="heading"
                                      size="small"
                                      as="h2"
                                      bold
                                    >
                                      {zone["zone-title"]}
                                    </Text>
                                  </IonCol>
                                  <IonCol size="1">
                                    <Text type="display" size="small" as="span">
                                      <IonIcon
                                        icon={informationCircleOutline}
                                      />
                                    </Text>
                                  </IonCol>
                                </IonRow>
                              </IonGrid>
                            </Card>
                          ))}
                        </>
                      ),
                    });
                  }}
                >
                  Legend
                </Button>
              </IonCol>
              <IonCol size="auto">
                <Button
                  variant={gpsState === "on" ? "success-alt" : "light"}
                  icon={gpsState === "on" ? navigateCircle : navigateOutline}
                  onClick={() => {
                    if (gpsState === "off") {
                      Geolocation.requestPermissions({
                        permissions: ["location"],
                      })
                        .then(({ location }) => {
                          if (location === "granted") {
                            startWatch();
                            setGpsState("loading");
                          }
                        })
                        .catch(() => {
                          Geolocation.getCurrentPosition()
                            .then((position) => {
                              const {
                                coords: { latitude, longitude },
                              } = position;
                              const newGpsPin = new LatLng(latitude, longitude);
                              setGpsPin(newGpsPin);
                              setGpsState("on");
                              if (mapRef.current) {
                                mapRef.current.flyTo(newGpsPin, FLYTO_ZOOM, {
                                  duration: FLYTO_DURATION,
                                });
                              }
                              showDismissableSnackbar(
                                "Watching current location is unsupported on this device."
                              );
                            })
                            .catch((reason) => {
                              showDismissableSnackbar(
                                "Please enable geo location in your browser settings"
                              );
                            });
                        });
                    } else {
                      setGpsState("off");
                    }
                  }}
                  className="zoom-to-me"
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        </>
      )}
      <div className="map-wrapper">
        <AgMap
          style={{
            flexGrow: 1,
          }}
          geojsonFocus={mapFocus}
          ref={mapRef}
          zoomControl={!!emergencyData?.mapData ? "slider" : "none"}
        >
          <>
            {emergencyData?.mapData &&
              emergencyData.mapData.features.map((feature, i) => {
                return (
                  <GeoJSON
                    key={`${feature.properties.zoneType} - ${feature.properties.Zone_Name}`}
                    data={feature}
                    eventHandlers={{
                      click: ({ latlng: { lat, lng } }) => {
                   const zoneIndex = emergencyData!
  .mapData!.features.filter((zone) =>
    PointInPolygon(point([lng, lat]), zone.geometry)
  )
  .sort(
    (a, b) =>
      a.properties.Shape__Area -
      b.properties.Shape__Area
  )[0].properties.zoneType -1;
 

                        setBottomSheetProps({
                          isOpen: true,
                          title: emergencyData!.zones[zoneIndex]["zone-title"],
                          children: (
                            <>
                              <Text
                                type="body"
                                size="large"
                                className="ion-padding-bottom"
                              >
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      emergencyData!.zones[zoneIndex][
                                        "zone-description"
                                      ],
                                  }}
                                ></div>
                              </Text>
                              <Button
                                expand="block"
                                iconPosition="end"
                                icon={arrowForward}
                                onClick={() => {
                                  setBottomSheetProps({
                                    isOpen: false,
                                  });
                                  scrollAndSelectZone(
                                    emergencyData!.zones[zoneIndex].zoneId
                                  );
                                  history.push(`/emergency/${id}`);
                                }}
                              >
                                View zone information
                              </Button>
                            </>
                          ),
                        });
                      },
                    }}
                    style={{
                      fillColor: feature.properties.zoneType
                        ? zoneColors[feature.properties.zoneType - 1]
                        : i < 2
                        ? zoneColors[i]
                        : zoneColors[2],
                      fillOpacity: 0.4,
                      weight: 1.5,
                      color: "#000",
                      opacity: 0.25,
                    }}
                  />
                );
              })}
          </>
          {augmentedProperties &&
            augmentedProperties.map((property) => {
              const { lng, lat } = property.location;
              let zoneName = "";

              if (property.isAffected && emergencyData?.zones) {
                const zone = emergencyData.zones.find(
                  ({ zoneId }) => zoneId === property.zoneIndex + 1
                );
                zoneName = zone ? zone["zone-title"] : "";
              }

              return (
                <PropertyOnMap
                  onClick={() => {
                    const zone = emergencyData?.zones.find(
                      ({ zoneId }) => zoneId === property.zoneIndex + 1
                    );

                    if (zone) showEmergencyBottomSheet(property, zone);

                    return false;
                  }}
                  key={`${lat}-${lng}`}
                  pinPosition={property.location}
                  property={property}
                  zoneIndex={property.zoneIndex}
                  isAffected={property.isAffected}
                  zoneName={zoneName}
                />
              );
            })}
          {gpsState === "on" && gpsPin && (
            <Marker position={gpsPin} icon={icons.property.blue} />
          )}
        </AgMap>
      </div>
    </>
  );
};

export default Map;
