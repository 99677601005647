import { GeoJSON, Marker, Popup } from "react-leaflet";
import { Text } from "./ui";
import L, { Marker as LeafletMarker, Popup as LeafletPopup } from "leaflet";
import { PropertyDetailsWithBoundary } from "../project-types";
import { icons } from "./AgMap";
import { propertyBoundsStyle } from "../theme/geoStyles";
import { useEffect, useRef, useMemo, useCallback } from "react";

import "./propertyOnMap.scss";

type PropertyOnMapProps = {
  onClick?: () => void;
  pinPosition: L.LatLngLiteral;
  property: PropertyDetailsWithBoundary;
  zoneIndex: number;
  zoneName: string;
  isAffected: boolean;
};

export const PropertyOnMap = ({
  onClick,
  pinPosition,
  property,
  zoneIndex,
  isAffected,
  zoneName,
}: PropertyOnMapProps) => {
  const MarkerRef = useRef<LeafletMarker>(null);
  const PopupRef = useRef<LeafletPopup>(null);

  const clickCallback = useCallback(() => {
    onClick && onClick();
  }, [onClick]);

  useEffect(() => {
    if (MarkerRef.current && PopupRef.current && isAffected) {
      const marker = MarkerRef.current.bindPopup(PopupRef.current);
      // open the popup
      marker.openPopup();

      // disable all the marker click callbacks. one of them will close the popup
      marker.off("click");
      // then rebind the click callback
      marker.on("click", clickCallback);
    }
  }, [MarkerRef, PopupRef, clickCallback]);

  const propertyPin = useMemo(() => {
    let pin = icons.property.white;

    if (!isAffected) {
      return pin;
    }

    switch (zoneIndex + 1) {
      case 1:
        pin = icons.property.red;
        break;
      case 2:
        pin = icons.property.orange;
        break;
      case 3:
        pin = icons.property.yellow;
        break;
    }
    return pin;
  }, [zoneIndex, isAffected]);

  const popup = useMemo(() => {
    if (!isAffected) {
      return null;
    }

    let text = (
      <Text type="body" size="small">
        {zoneName}
      </Text>
    );
    if (onClick) {
      text = (
        <button type="button" onClick={clickCallback}>
          {text}
        </button>
      );
    }

    return (
      <Popup
        ref={PopupRef}
        className="affected-property-popup"
        autoClose={false}
        closeButton={false}
        closeOnClick={false}
        closeOnEscapeKey={false}
        autoPan={false}
        interactive={false}
        keepInView={false}
        offset={L.point(0, -30)}
        maxWidth={200}
      >
        {text}
      </Popup>
    );
  }, [isAffected, onClick, clickCallback, zoneName]);

  return (
    <>
      <Marker
        ref={MarkerRef}
        eventHandlers={{
          click: clickCallback,
        }}
        position={pinPosition}
        icon={propertyPin}
      >
        {popup}
      </Marker>
      {property.boundary && (
        <GeoJSON
          eventHandlers={{
            click: () => {
              onClick && onClick();
            },
          }}
          key={property.propertyId}
          data={property.boundary}
          style={propertyBoundsStyle(isAffected, zoneIndex)}
        />
      )}
    </>
  );
};
