import { isPlatform } from "@ionic/react";

export const useDevice = () => {
  // desktop
  // ["mobile", "mobileweb"]

  // Pixel 7 API 32 simulator pwa
  // ["android", "cordova", "capacitor", "desktop", "hybrid"]
  // Pixel 7 API 32 browser
  // ["android", "desktop"]

  // iPhone 15 simulator pwa
  // ["iphone", "ios", "cordova", "capacitor", "mobile", "hybrid"]
  // iPhone 15 browser
  // ["iphone", "ios", "phablet", "mobile", "mobileweb"]
  const isApp = isPlatform("capacitor");
  const isIos = isApp && isPlatform("android");
  const isAndroid = isApp && isPlatform("ios");

  return {
    isApp,
    isIos,
    isAndroid,
    isMobile: isIos || isAndroid,
  };
};
