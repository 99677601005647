import { Input, Text } from "../../components/ui";
import { AgResponsePage } from "../../components/AgResponsePage";
import Button from "../../components/ui/Button";
import { useEffect, useState } from "react";
import { TextBreak } from "../../components/ui/TextBreak";
import { usersByEmail } from "../../api/user";
import { Register } from "./register";
import { Login } from "./Login";
import ServiceNswLoginButton, {
  loginWithSnsw,
} from "../../components/ui/ServiceNswLoginButton";
import { GuestButton } from "../../components/ui/GuestButton";
import { setGuestMode } from "../../api/localStorage";

const loginStates = [
  "landed",
  "email",
  "email-register",
  "email-confirm",
  "email-login",
  "snsw",
  "snsw-return",
] as const;

type LoginState = (typeof loginStates)[number];

// TODO: Is there a better way to type this?
// (using RouteComponentProps from react-router-dom?)
type LoginProps = {
  location: {
    state: {
      email?: string;
      state?: LoginState;
    };
  };
};

export const LoginPage = ({ location }: LoginProps) => {
  const [state, setState] = useState<LoginState>("landed");
  const [email, setEmail] = useState<string>(location?.state?.email || "");
  const [validEmail, setValidEmail] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isGuestLoginShown, setIsGuestLoginShown] = useState<boolean>(true);

  useEffect(() => {
    if (location.state?.email) {
      setEmail(location.state.email);
    }
    if (location?.state?.state && loginStates.includes(location.state.state)) {
      setState(location.state.state);
    }
  }, [location.state]);

  useEffect(() => {
    if (email.length > 0) {
      //check if the email is a valid email address with regex
      const re = /\S+@\S+\.\S+/;
      if (re.test(email)) {
        setValidEmail(true);
      } else {
        setValidEmail(false);
      }
    }
  }, [email]);

  useEffect(() => {
    setGuestMode(false);
    const queryParam = new URLSearchParams(window.location.search).get(
      "hideGuestLogin",
    );
    setIsGuestLoginShown(queryParam !== "1");
  }, []);

  return (
    <AgResponsePage
      showHeader
      onButtonClick={() => {
        setState("landed");
      }}
      hideButton={state === "landed"}
      buttonType={state !== "landed" ? "back" : undefined}
    >
      <Text
        type="display"
        color="primary"
        size="medium"
        className="margin-bottom-32"
      >
        {["landed", "email"].includes(state) && "Sign in or create account"}
        {state === "email-register" && "Create account"}
        {state === "email-login" && "Log in"}
        {state === "email-confirm" && "Enter code"}
      </Text>
      {state === "landed" && (
        <>
          <ServiceNswLoginButton />
          <TextBreak text="OR" />
          <Button
            onClick={() => setState("email")}
            variant="secondary"
            size="large"
            icon="/assets/email.svg"
            expand="block"
          >
            Continue with Email
          </Button>

          {isGuestLoginShown && (
            <>
              <TextBreak text="OR" />
              <GuestButton />
            </>
          )}
        </>
      )}
      {state === "email" && (
        <>
          <form
            method="post"
            onSubmit={(e) => {
              e.preventDefault();
              setLoading(true);
              usersByEmail(email)
                .then((res) => {
                  if (!res || res.length === 0) {
                    setState("email-register");
                    setLoading(false);
                    return;
                  }

                  if (res.some((user) => user.snswId)) {
                    loginWithSnsw();
                    return;
                  }

                  setState("email-login");
                  setLoading(false);
                })
                .catch((err) => {
                  console.error({ err });
                });
            }}
          >
            <Input
              label="Email address"
              placeholder="Enter email address"
              type="email"
              value={email}
              onChange={(value) => setEmail(value)}
              fullWidth
              autofocus
            />
            <Button
              type="submit"
              disabled={!validEmail || loading}
              loading={loading}
              variant="secondary"
              size="large"
              expand="block"
              className="margin-top-32"
            >
              Continue
            </Button>
          </form>
          <TextBreak text="OR" />
          <ServiceNswLoginButton />
        </>
      )}
      {state === "email-register" && <Register email={email} />}
      {state === "email-confirm" && (
        <Register email={email} initialState="confirm" />
      )}
      {state === "email-login" && <Login email={email} />}
    </AgResponsePage>
  );
};
