import { IonCol, IonGrid, IonIcon, IonRow } from "@ionic/react";
import { ReactNode, useCallback, useContext, useMemo } from "react";
import { ButtonProps, Text } from "../ui";
import { BottomSheetContext } from "../ui/BottomSheet";
import Button from "../ui/Button";
import clsx from "clsx";

type BottomSheetWithCtaProps = {
  icon?: string | ReactNode;
  color?: "info" | "error";
  header?: string;
  body?: string;
  buttons?: Array<ButtonProps & { children: string }>;
};

type CtaEvent = React.MouseEvent<HTMLIonButtonElement, MouseEvent>;

export const BottomSheetWithCta = ({
  icon,
  header,
  body,
  buttons,
  color = "info",
}: BottomSheetWithCtaProps) => {
  const { setBottomSheetProps } = useContext(BottomSheetContext);

  const ctaAction = useCallback(
    async (event: CtaEvent, onClick?: (event: CtaEvent) => any) => {
      if (onClick) {
        await onClick(event);
      }
      setBottomSheetProps({
        isOpen: false,
      });
    },
    [buttons]
  );

  const ctaButtons = useMemo(() => {
    return (
      buttons &&
      buttons.map((button) => {
        return (
          <IonRow key={button.children} className="margin-top-16">
            <IonCol>
              <Button
                expand="block"
                {...button}
                onClick={(event) => ctaAction(event, button?.onClick)}
                className="margin-0"
              >
                {button.children}
              </Button>
            </IonCol>
          </IonRow>
        );
      })
    );
  }, [buttons]);

  return (
    <IonGrid className="ion-text-center margin-bottom-24">
      {icon && (
        <IonRow>
          <IonCol>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100px",
                height: "100px",
                borderRadius: "50%",
                backgroundColor: color === "info" ? "#FFE2C8" : "#FFDADA",
                margin: "auto",
              }}
            >
              {typeof icon === "string" ? (
                <IonIcon
                  icon={icon}
                  color="primary"
                  style={{ fontSize: "50px" }}
                />
              ) : (
                icon
              )}
            </div>
          </IonCol>
        </IonRow>
      )}
      {header && (
        <IonRow
          className={clsx({
            "margin-top-12": !icon,
            "margin-top-24": !!icon,
          })}
        >
          <IonCol>
            <Text size="medium" type="display">
              {header}
            </Text>
          </IonCol>
        </IonRow>
      )}
      {body && (
        <IonRow className={"margin-top-24"}>
          <IonCol>
            <Text size="large" type="body">
              {body}
            </Text>
          </IonCol>
        </IonRow>
      )}
      {ctaButtons && <div className={"margin-top-8"}>{ctaButtons}</div>}
    </IonGrid>
  );
};
