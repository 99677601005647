import React from "react";
import "./Divider.scss";

export type DividerProps = {
  variant?: "primary" | "secondary" | "tertiary";
  className?: string;
};

const Divider: React.FC<DividerProps> = ({
  variant = "primary",
  className,
}) => {
  return (
    <hr
      className={`ag-divider ag-divider-${variant} ${
        className ? className : ""
      }`}
    />
  );
};

export default Divider;
