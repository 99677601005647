import { IonIcon, IonLabel, IonToolbar } from "@ionic/react";
import { useState, useEffect, useCallback, useMemo } from "react";
import "./NavFooter.scss";
import { Text } from "./ui";
import { getNotifications } from "../api/user";
import { settingsOutline } from "ionicons/icons";
import { Emergencies, Notifications, Properties } from "./svgs";
import { useCacheLiveData } from "../hooks/useCacheLiveData";
import { Network } from "@capacitor/network";
import { PluginListenerHandle } from "@capacitor/core";
import { useHistory, useRouteMatch } from "react-router";
import Button from "./ui/Button";
import clsx from "clsx";

export const NavFooter = () => {
  const [unreadNotifications, setUnreadNotifications] = useState<boolean>();
  const [notifications, setNotifications] =
    useState<
      Array<{
        body: string;
        title: string;
        sent: string;
        emergencyId: string;
        hasRead: boolean;
        notificationId: string;
        deepLink: string;
      }>
    >();
  const [isConnected, setIsConnected] = useState<boolean>();
  const history = useHistory();
  const { path } = useRouteMatch();

  // can't use useStatus because there is a bug where the package
  // Network.addListener returns a Promise<PluginListenerHandle> & PluginListenerHandle
  // but the hook packages calls .remove on the promise, so it causes a run time error
  useEffect(() => {
    let listener: PluginListenerHandle;

    const getNetworkStatus = async () => {
      const { connected } = await Network.getStatus();
      setIsConnected(connected);
      listener = await Network.addListener(
        "networkStatusChange",
        ({ connected }) => {
          setIsConnected(connected);
        }
      );
    };

    getNetworkStatus();

    return () => {
      if (listener) {
        listener.remove();
      }
    };
  }, []);

  const { data: notificationsData } = useCacheLiveData(getNotifications);

  useEffect(() => {
    if (notificationsData === undefined) {
      return;
    }

    const lastThirtyNotifications = notificationsData.slice(-30);
    setNotifications(lastThirtyNotifications);
  }, [notificationsData]);

  useEffect(() => {
    if (notifications && notifications.length > 0) {
      const thereAreUnReadNotifications = notifications.some(
        (notification) => !notification.hasRead
      );
      if (thereAreUnReadNotifications) {
        setUnreadNotifications(true);
      } else {
        setUnreadNotifications(false);
      }
    }
  }, [notifications]);

  const navTo = useCallback(
    (goTo: string, e: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => {
      e.stopPropagation();
      e.preventDefault();

      history.push(goTo);
    },
    []
  );

  const navItems = useMemo(
    () => [
      {
        href: "/emergencies",
        content: (
          <>
            {" "}
            <Emergencies
              {...{ "aria-label": "Emergencies" }}
              className={"ion-icon"}
            />
            <IonLabel>
              <Text type="body" size="small">
                Emergencies
              </Text>
            </IonLabel>
          </>
        ),
      },
      {
        href: "/properties",
        content: (
          <>
            <Properties
              {...{ "aria-label": "Properties" }}
              className={"ion-icon"}
            />
            <IonLabel>
              <Text type="body" size="small">
                Properties
              </Text>
            </IonLabel>
          </>
        ),
      },
      {
        href: "/notifications",
        content: (
          <>
            {unreadNotifications && <div className="notifications-unread" />}
            <Notifications
              {...{ "aria-label": "Notifications" }}
              className={"ion-icon"}
            />
            <IonLabel>
              <Text type="body" size="small">
                Notifications
              </Text>
            </IonLabel>
          </>
        ),
      },
      {
        href: "/settings",
        isDisabled: !isConnected,
        content: (
          <>
            <IonIcon
              icon={settingsOutline}
              aria-label="More"
              className="ion-icon settings"
            />
            <IonLabel>
              <Text type="body" size="small">
                Settings
              </Text>
            </IonLabel>
          </>
        ),
      },
    ],
    [unreadNotifications, isConnected]
  );

  return (
    // <IonFooter>
    <IonToolbar>
      <div className="ag-nav-footer">
        <div className="ag-nav-footer-items">
          {navItems.map(({ href, content, isDisabled }) => {
            // dodgy way of matching singular and plural
            const subPath = href.substring(0, 5);
            const isSelected = path.startsWith(subPath);
            return (
              <Button
                key={href}
                variant="clear"
                href={href}
                disabled={isDisabled}
                onClick={(e) => {
                  navTo(href, e);
                }}
                className={clsx("ag-nav-footer-item", {
                  selected: isSelected,
                })}
              >
                {content}
              </Button>
            );
          })}
        </div>
      </div>
    </IonToolbar>
    // </IonFooter>
  );
};
