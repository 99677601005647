import { IonButton, IonItem, IonLabel } from "@ionic/react";
import { Text } from "./Text";
import { PropsWithChildren, useState } from "react";
import "./ShowHideContent.scss";

type DisplayHideContentProps = {
  title: string;
};

const ShowHideContent = ({
  children,
  title,
}: PropsWithChildren<DisplayHideContentProps>) => {
  const [show, setShow] = useState<boolean>(false);

  return (
    <>
      <IonItem
        lines="full"
        className={`ag-show-hide-content ion-no-padding`}
        onClick={() => setShow(!show)}
      >
        <IonLabel>
          <Text type="heading" size="medium" as="p">
            {title}
          </Text>
        </IonLabel>
        <IonButton>
          [<span>{show ? "hide" : "show"}</span>]
        </IonButton>
      </IonItem>
      {show && <div className="content">{children}</div>}
    </>
  );
};

export default ShowHideContent;
