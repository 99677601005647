import {
  IonCard,
  IonCardContent,
  IonImg,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/react";
import Fab from "./Fab";
import "./NavCard.scss";

type Buttons = Array<{
  name: string;
  icon: string;
  action?: () => void;
}>;

type NavCardProps = {
  image?: string;
  imageAltText?: string;
  buttons: Buttons;
};

const NavCard: React.FC<NavCardProps> = ({ image, imageAltText, buttons }) => {
  return (
    <IonCard className="ag-nav-card">
      {image && <IonImg src={image} alt={imageAltText} />}
      <IonCardContent>
        <IonGrid>
          <IonRow>
            {buttons.map((button) => (
              <IonCol key={button.name}>
                <Fab
                  size="small"
                  icon={button.icon}
                  label={button.name}
                  onClick={button.action && button.action}
                />
              </IonCol>
            ))}
          </IonRow>
        </IonGrid>
      </IonCardContent>
    </IonCard>
  );
};

export default NavCard;
