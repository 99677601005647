import { useContext, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import { LatestUpdates } from "../../project-types";
import { getLatestUpdates } from "../../api/emergencies";
import { Tag, Text } from "../../components/ui";
import formatDate from "../../utils/formatDate";
import Accordion from "../../components/ui/Accordion";
import { SnackbarHandlerContext } from "../../components/SnackbarHandler";
import FullscreenLoader from "../../components/ui/FullscreenLoader";
import { useCacheLiveData } from "../../hooks/useCacheLiveData";
import EmptyState from "../../components/ui/EmptyState";
import { warning, warningOutline } from "ionicons/icons";

const Latest: React.FC<RouteComponentProps<{ id: string }>> = ({ match }) => {
  const id = match.params.id;
  const [sortedLatestUpdates, setSortedLatestUpdates] =
    useState<Array<LatestUpdates> | undefined>();
  const { showDismissableSnackbar } = useContext(SnackbarHandlerContext);

  const {
    data: latestUpdatesData,
    state: { hasLiveErrored, haveBothLoaded },
  } = useCacheLiveData(getLatestUpdates, [id]);

  useEffect(() => {
    if (hasLiveErrored) {
      showDismissableSnackbar("Error retrieving new latest updates");
      return;
    }

    if (latestUpdatesData === undefined) return;

    const filter = latestUpdatesData.latestUpdates.filter((update) => {
      const numKeys = Object.keys(update).length;
      const hasContent = update["update-description"] !== "<p></p>";
      return numKeys > 1 && hasContent;
    });

    const updatesSortedByDate = () => {
      return filter.sort((a, b) => {
        const dateA = a["update-date"].split("-").reverse().join("-");
        const dateB = b["update-date"].split("-").reverse().join("-");
        return new Date(dateB).getTime() - new Date(dateA).getTime();
      });
    };

    setSortedLatestUpdates(updatesSortedByDate());
  }, [latestUpdatesData, id]);

  return (
    <>
      <div className="margin-y-32 margin-x-24">
        {sortedLatestUpdates === undefined && haveBothLoaded && (
          <EmptyState
            {...(hasLiveErrored
              ? {
                  icon: warning,
                  header: "Offline Mode",
                  body: "We are unable to retrieve latest emergency info. Please check your internet connection.",
                }
              : {
                  icon: warningOutline,
                  body: "There is no latest emergency info available.",
                })}
          />
        )}
        {sortedLatestUpdates === undefined && !haveBothLoaded && (
          <FullscreenLoader />
        )}
        {sortedLatestUpdates !== undefined && (
          <>
            {sortedLatestUpdates.length === 0 && (
              <div>
                <Text type="heading" size="large" as="h2">
                  No updates yet
                </Text>
                <Text type="body" size="large">
                  There are no updates yet for this emergency.
                </Text>
              </div>
            )}

            {sortedLatestUpdates.length > 0 && (
              <>
                <div>
                  <Text
                    type="heading"
                    size="large"
                    as="h2"
                    className="margin-bottom-2"
                  >
                    Latest update
                  </Text>
                  <Tag variant="secondary">
                    {formatDate(sortedLatestUpdates[0]["update-date"])}
                  </Tag>
                  <Text type="body" size="large" as="p">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: sortedLatestUpdates[0]["update-description"],
                      }}
                    />
                  </Text>
                </div>

                {sortedLatestUpdates && sortedLatestUpdates.length > 0 && (
                  <div className="margin-y-32">
                    <Text
                      type="heading"
                      size="medium"
                      as="h2"
                      className="margin-bottom-16"
                    >
                      Past updates
                    </Text>
                    <Accordion
                      headerSlug="date"
                      split
                      items={
                        sortedLatestUpdates.length > 1
                          ? sortedLatestUpdates.slice(1).map((update) => ({
                              ...update,
                              "update-date": formatDate(update["update-date"]),
                            }))
                          : sortedLatestUpdates
                      }
                    />
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Latest;
