import {
  CognitoUserPool,
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoRefreshToken,
} from "amazon-cognito-identity-js";
import {
  setJWT,
  setUserSub,
  setPropertyIds,
  setJwtExpiry,
  setRefreshToken,
  getRefreshToken,
  getGuestMode,
} from "./localStorage";
import { getUser, addUser, getProperties, updateUser } from "./user";
import { clearCache } from "./offlineCacher";
import { logAnalyticsEvent } from "./firebase";

const {
  REACT_APP_COGNITO_USER_POOL_ID = "",
  REACT_APP_COGNITO_USER_POOL_CLIENT_ID = "",
} = process.env;

const getUserPool = () =>
  new CognitoUserPool({
    UserPoolId: REACT_APP_COGNITO_USER_POOL_ID,
    ClientId: REACT_APP_COGNITO_USER_POOL_CLIENT_ID,
  });

const getCognitoUser = (email: string) => {
  const userPool = getUserPool();
  const userData = {
    Username: email,
    Pool: userPool,
  };
  return new CognitoUser(userData);
};

export const handleLogin = async (
  email: string,
  password: string
): Promise<boolean> => {
  const authenticationData = {
    Username: email,
    Password: password,
  };
  const authenticationDetails = new AuthenticationDetails(authenticationData);

  const cognitoUser = getCognitoUser(email);

  return new Promise((resolve, reject) => {
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: async (result) => {
        const accessToken = result.getAccessToken().getJwtToken();
        const refreshToken = result.getRefreshToken().getToken();

        setJWT(accessToken);
        setRefreshToken(refreshToken);

        await updateUser({
          manualLogout: false,
        });

        const userData = await getUser(accessToken);

        if (userData) {
          const { jwtExpiry } = userData;
          await setUserSub(result.getIdToken().payload.sub as string);
          await setJwtExpiry(jwtExpiry * 1000);

          const propertyIds = await getProperties()
            .live.then((res) => {
              if (res === undefined) {
                console.error("Failed to get properties");
                return [];
              }

              return res ? res.map((p) => p.propertyId) : [];
            })
            .catch((e) => {
              console.error(e);
            });

          if (propertyIds) {
            setPropertyIds(propertyIds);
          }
        }
        resolve(true);
      },
      onFailure: (err) => {
        console.error("Handle Login error", { err });
        reject(err);
      },
    });
  });
};

export const handleSignup = async (
  email: string,
  password: string
): Promise<string> => {
  const userPool = getUserPool();
  const attributeList: Array<CognitoUserAttribute> = [];
  const attributeEmail = new CognitoUserAttribute({
    Name: "email",
    Value: email,
  });
  attributeList.push(attributeEmail);

  return new Promise((resolve, reject) => {
    userPool.signUp(email, password, attributeList, [], (err, result) => {
      if (err) {
        reject(err);
      } else if (result) {
        resolve(result.userSub);
      }
    });
  });
};

export const handleRefresh = async (): Promise<boolean> => {
  return new Promise<boolean>(async (resolve, reject) => {
    const guest = await getGuestMode();
    if (guest) {
      resolve(false);
      return;
    }

    let token;

    try {
      token = await getRefreshToken();
    } catch (err) {
      console.error(err);
      resolve(false);
      return;
    }

    if (token === undefined || token === "") {
      resolve(false);
      return;
    }

    let cognitoUser: CognitoUser | null;

    try {
      cognitoUser = getUserPool().getCurrentUser();
    } catch (err) {
      console.error(err);
      resolve(false);
      return;
    }

    try {
      const refreshToken = new CognitoRefreshToken({
        RefreshToken: token,
      });

      const res = await new Promise<[string, string] | undefined>(
        (resolve, reject) => {
          if (!cognitoUser || cognitoUser === null) {
            reject(undefined);
            return;
          }

          cognitoUser.refreshSession(refreshToken, (err, session) => {
            if (err) {
              reject(undefined);
            } else {
              const accessToken: string = session
                .getAccessToken()
                .getJwtToken();
              const refreshToken: string = session.getRefreshToken().getToken();
              resolve([accessToken, refreshToken]);
            }
          });
        }
      );

      if (res) {
        setJWT(res[0]);
        setRefreshToken(res[1]);
        resolve(true);
      }
    } catch (err) {
      console.error(err);
    }

    resolve(false);
  });
};

export const handleEmailVerification = async (
  userSub: string,
  email: string,
  firstName: string,
  lastName: string,
  phone: string,
  code: string,
  emailPreferred: boolean,
  snswId: string,
  ABN: string,
  raaDataShareOptIn: boolean,
  pushTokens: string
): Promise<boolean> => {
  if (userSub === "") {
    return Promise.resolve(false);
  }

  const cognitoUser = getCognitoUser(email);

  return new Promise((resolve, reject) => {
    cognitoUser.confirmRegistration(code, true, async (err, res) => {
      //return false if err is not null
      if (err) {
        resolve(false);
      } else {
        await addUser(userSub, {
          email,
          firstName,
          lastName,
          phone,
          emailPreferred,
          snswId,
          ABN,
          raaDataShareOptIn,
          pushTokens,
        });
        resolve(true);
      }
    });
  });
};

export const handleForgotPassword = async (email: string): Promise<boolean> => {
  const userPool = getUserPool();

  const cognitoUser =
    userPool.getCurrentUser() ||
    new CognitoUser({
      Username: email,
      Pool: userPool,
    });

  return new Promise((resolve) => {
    if (!cognitoUser) {
      resolve(false);
    }

    cognitoUser.forgotPassword({
      onSuccess: () => {
        resolve(true);
      },
      onFailure: (err) => {
        console.error(err);
        resolve(false);
      },
    });
  });
};

export const handleResetPassword = async (
  email: string,
  code: string,
  password: string
): Promise<boolean> => {
  const userPool = getUserPool();

  const cognitoUser =
    userPool.getCurrentUser() ||
    new CognitoUser({
      Username: email,
      Pool: userPool,
    });

  if (cognitoUser) {
    return new Promise((resolve, reject) => {
      cognitoUser.confirmPassword(code, password, {
        onSuccess: () => {
          resolve(true);
        },
        onFailure: (err) => {
          console.error(err);
          reject(err);
        },
      });
    });
  }

  return Promise.resolve(false);
};

export const handleLogout = async (): Promise<boolean> => {
  const cognitoUser = getUserPool().getCurrentUser();

  // reset frontend regardless if there is a current user or not
  setJWT("");
  setUserSub(null);
  setPropertyIds([]);
  clearCache();

  if (cognitoUser) {
    await updateUser({
      manualLogout: true,
    });
    logAnalyticsEvent("Logout", {});
    cognitoUser.signOut();
    return Promise.resolve(true);
  } else {
    return Promise.resolve(false);
  }
};
