import React, { PropsWithChildren } from "react";
import "./BottomSheet.scss";
import Button from "./Button";
import { Text } from "./Text";

export type BottomSheetProps = {
  renderBottomSheet?: boolean;
  isOpen: boolean;
  title?: string;
  closeCta?: string;
  isCloseCtaVisible?: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
};

type BottomSheetContextProps = {
  props: BottomSheetProps;
  setBottomSheetProps: React.Dispatch<React.SetStateAction<BottomSheetProps>>;
};

export const BottomSheetContext = React.createContext<BottomSheetContextProps>(
  {} as BottomSheetContextProps
);

const BottomSheet = ({ children: outerChildren }: PropsWithChildren) => {
  const [
    {
      renderBottomSheet,
      isOpen,
      children,
      onClose,
      title,
      closeCta,
      isCloseCtaVisible,
    },
    setBottomSheetProps,
  ] = React.useState<BottomSheetProps>({
    renderBottomSheet: false,
    isOpen: false,
    closeCta: "Cancel",
  });

  const [stateClassName, setStateClassName] = React.useState<string>("");

  React.useEffect(() => {
    setStateClassName("");

    if (isOpen) {
      setBottomSheetProps((values) => ({
        ...values,
        closeCta: values?.closeCta || "Cancel",
        isCloseCtaVisible:
          values?.isCloseCtaVisible === undefined || values?.isCloseCtaVisible,
        renderBottomSheet: true,
      }));

      // Wait for the next frame to apply the animation
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          setStateClassName("is-open");
        });
      });
    }
  }, [isOpen]);

  return (
    <BottomSheetContext.Provider
      value={{
        props: {
          isOpen,
          children,
          onClose,
          title,
        },
        setBottomSheetProps,
      }}
    >
      {isOpen ? (
        <div
          onClick={() => {
            setBottomSheetProps({
              isOpen: false,
              children,
              title,
            });
          }}
          className="bottom-sheet-overlay"
        />
      ) : null}
      {renderBottomSheet !== false && (
        <div className={`ag-bottom-sheet ${stateClassName}`}>
          {title && (
            <Text
              type="heading"
              size="large"
              bold
              className="ion-padding-bottom"
            >
              {title}
            </Text>
          )}
          <div className={!isCloseCtaVisible ? "" : "ag-bottom-sheet-children"}>
            {children}
          </div>
          {isCloseCtaVisible && (
            <div className="ion-text-center cancel-btn-wrapper">
              <Button
                expand="block"
                variant={"outline"}
                onClick={
                  onClose
                    ? onClose
                    : () => {
                        setBottomSheetProps({
                          isOpen: false,
                          children,
                          title,
                        });
                      }
                }
              >
                {closeCta || "Cancel"}
              </Button>
            </div>
          )}
        </div>
      )}
      {outerChildren}
    </BottomSheetContext.Provider>
  );
};

export default BottomSheet;
