import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
} from "@ionic/react";
import "./NotificationCard.scss";
import { Text } from "./Text";

export type NotificationCardProps = {
  cardOnClick?: () => void;
  read?: boolean;
  content: string;
  subtitle?: string;
};

export const NotificationCard = ({
  cardOnClick,
  content,
  subtitle,
  read = false,
}: NotificationCardProps) => {
  return (
    <IonCard
      className={`ag-notification-card ${
        read ? "read-notification" : "unread-notification"
      }`}
      onClick={cardOnClick}
    >
      {(content || subtitle) && (
        <>
          <IonCardHeader className="ag-notification-card-header">
            <IonCardSubtitle>
              <Text
                type="body"
                size="small"
                className="ag-notification-subtitle"
              >
                {subtitle}
              </Text>
            </IonCardSubtitle>
          </IonCardHeader>

          <IonCardContent className="ag-notification-card-content">
            <Text
              type="body"
              size="large"
              as="span"
              className="ag-notification-content"
            >
              {content}
            </Text>
          </IonCardContent>
        </>
      )}
    </IonCard>
  );
};

export default NotificationCard;
