import { ReactElement } from "react";
import { IonLabel, IonList } from "@ionic/react";

import "./List.scss";
import { Block } from "./Block";

export type ListProps = {
  className?: string;
  variant?: "primary" | "secondary" | "transparent";
  childrenVariant?: "primary" | "secondary" | "transparent";
  items: Array<ReactElement>;
  clickable?: boolean;
  onItemClicked?: (key: string) => void;
};

export const List = ({
  items,
  clickable,
  childrenVariant,
  className,
  variant,
  onItemClicked,
}: ListProps) => {
  return (
    <IonList
      className={`ag-list ag-list-${variant ? variant : "primary"} ${
        className ? className : ""
      }`}
    >
      {items.map((child) => (
        <Block
          key={child.key}
          clickable={clickable}
          onClick={() => {
            if (clickable) {
              onItemClicked && onItemClicked(child.key as string);
            }
          }}
          variant={
            childrenVariant ? childrenVariant : variant ? variant : "primary"
          }
          className="ag-list-item"
        >
          <IonLabel className="ag-list-item-label">{child}</IonLabel>
        </Block>
      ))}
    </IonList>
  );
};
