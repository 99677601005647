import {
  IonCol,
  IonFooter,
  IonGrid,
  IonRow,
  IonSpinner,
  IonToolbar,
} from "@ionic/react";
import Button, { ButtonProps } from "./ui/Button";
import { arrowForward } from "ionicons/icons";

export type FlowFooterProps = {
  onBack: () => void;
  onNext: () => void;
  showBack?: boolean;
  showNext?: boolean;
  disableBack?: boolean;
  disableNext?: boolean;
  backText?: string;
  nextText?: string;
  overrideNextIcon?: string;
  overrideNextIconPosition?: "start" | "end" | undefined;
  showLoading?: boolean;
};

export const FlowFooter = ({
  onBack,
  onNext,
  backText,
  nextText,
  showBack,
  showNext,
  disableBack,
  disableNext,
  overrideNextIcon: overrrideNextIcon,
  overrideNextIconPosition,
  showLoading,
}: FlowFooterProps) => {
  const nextIcon: Pick<ButtonProps, "icon" | "iconPosition"> = overrrideNextIcon
    ? {
        icon: overrrideNextIcon,
        iconPosition: overrideNextIconPosition || "end",
      }
    : nextText
    ? {}
    : {
        icon: arrowForward,
        iconPosition: "end",
      };

  return (
    <IonFooter>
      <IonToolbar>
        <IonGrid>
          {showLoading ? (
            <IonSpinner
              name="crescent"
              style={{
                margin: "auto",
              }}
            />
          ) : (
            <IonRow class="ion-justify-content-between">
              <IonCol size="6">
                {(showBack !== undefined ? showBack : true) && (
                  <Button
                    variant="tertiary"
                    expand="block"
                    onClick={onBack}
                    disabled={disableBack}
                  >
                    {backText ? backText : "Back"}
                  </Button>
                )}
              </IonCol>
              <IonCol size="6">
                {(showNext !== undefined ? showNext : true) && (
                  <Button
                    expand="block"
                    onClick={onNext}
                    disabled={disableNext}
                    {...nextIcon}
                  >
                    {nextText ? nextText : "Next"}
                  </Button>
                )}
              </IonCol>
            </IonRow>
          )}
        </IonGrid>
      </IonToolbar>
    </IonFooter>
  );
};
