import { Block, Input, Text } from "../../../components/ui";
import { Dispatch, SetStateAction } from "react";
import { PropertyDetailsWithBoundary } from "../../../project-types";
import { AgResponsePage } from "../../../components/AgResponsePage";
import { useHistory } from "react-router";
import { FlowFooter } from "../../../components/FlowFooter";
import { saveOutline } from "ionicons/icons";
import { UpdatePropertyType } from "..";
import { SelectAssets } from "../../../components/SelectAssets";

type EditPropertyDetailsProps = {
  title: string;
  property: PropertyDetailsWithBoundary;
  updatedPropertyDetails: PropertyDetailsWithBoundary;
  setUpdatedPropertyDetails: (
    updated: Partial<PropertyDetailsWithBoundary>
  ) => void;
  selectedCrops: string[];
  setSelectedCrops: Dispatch<SetStateAction<string[]>>;
  selectedAnimals: string[];
  setSelectedAnimals: Dispatch<SetStateAction<string[]>>;
  isSaving: boolean;
  onSave: (saveType?: UpdatePropertyType) => void;
};

export const EditPropertyDetailsPage = ({
  title,
  property,
  updatedPropertyDetails,
  setUpdatedPropertyDetails,
  selectedCrops,
  setSelectedCrops,
  selectedAnimals,
  setSelectedAnimals,
  isSaving,
  onSave,
}: EditPropertyDetailsProps) => {
  const history = useHistory();

  return (
    <AgResponsePage
      title={title}
      hideCtas
      buttonType="back"
      onButtonClick={() => {
        history.push("/properties");
      }}
      footer={
        <FlowFooter
          onBack={() => {
            history.push("/property/" + property?.propertyId);
          }}
          onNext={() => {
            setUpdatedPropertyDetails({
              location: property.location,
            });
            onSave("details");
          }}
          nextText="Save changes"
          overrideNextIcon={saveOutline}
          overrideNextIconPosition="start"
          showLoading={isSaving}
        />
      }
    >
      <Input
        value={updatedPropertyDetails.name}
        label="Name"
        onChange={(value) => {
          setUpdatedPropertyDetails({ name: value });
        }}
      />

      <div className="margin-top-24">
        <Block inline>
          <Text size="medium" type="heading">
            What you're farming
          </Text>
        </Block>
        <SelectAssets
          selectedCrops={selectedCrops}
          setSelectedCrops={setSelectedCrops}
          selectedAnimals={selectedAnimals}
          setSelectedAnimals={setSelectedAnimals}
        />
      </div>
    </AgResponsePage>
  );
};
