import { IonItem } from "@ionic/react";
import "./Block.scss";

export type BlockProps = {
  className?: string;
  variant?: "primary" | "secondary" | "transparent";
  clickable?: boolean;
  onClick?: () => void;
  inline?: boolean;
  fullWidth?: boolean;
  slot?: string;
};

export const Block = ({
  children,
  className,
  clickable,
  variant,
  onClick,
  inline,
  fullWidth,
  slot,
}: React.PropsWithChildren<BlockProps>) => {
  return (
    <div>
      <IonItem
        onClick={() => {
          if (clickable) {
            onClick && onClick();
          }
        }}
        button={clickable}
        className={`ag-block ag-block-${variant ? variant : "primary"} ${
          className ? className : ""
        } ${fullWidth ? "ag-block-full" : ""}`}
        lines="none"
        slot={slot}
      >
        {inline ? children : <div>{children}</div>}
      </IonItem>
    </div>
  );
};
