import {
  IonAccordion,
  IonAccordionGroup,
  IonCard,
  IonItem,
  IonLabel,
} from "@ionic/react";
import "./Accordion.scss";
import { PropsWithChildren } from "react";

export type AccordionProps = {
  items: Array<{
    [key: string]: string;
  }>;
  buttons?: Array<React.ReactNode>;
  multiple?: boolean;
  split?: boolean;
  className?: string;

  headerSlug?: string;
};

function changeHeaderAndContentNames(
  items: AccordionProps["items"],
  headerSlug: string
) {
  return items.map((item) => {
    const keys = Object.keys(item);
    const keySlugs = keys.map((key) => key.split("-"));

    return keySlugs.reduce(
      (acc, slugs) => {
        const key = slugs.join("-");
        return {
          ...acc,
          header: slugs.includes(headerSlug) ? item[key] : acc.header,
          content: slugs.includes("description") ? item[key] : acc.content,
        };
      },
      {
        header: "",
        content: "",
      }
    );
  });
}

const Accordion = ({
  items,
  buttons,
  multiple,
  split,
  className,
  children,
  headerSlug = "name",
}: PropsWithChildren<AccordionProps>) => {
  return (
    <IonAccordionGroup
      multiple={multiple}
      className={`ag-accordion ${className ? className : ""}`}
    >
      {items &&
        changeHeaderAndContentNames(items, headerSlug).map((item, index) => {
          const { header, content } = item;
          const hasHeader = !!header;
          const hasContent =
            !!content || !!children || !!(buttons && buttons[index]);

          if (!hasHeader || !hasContent) return null;

          return !split ? (
            <IonAccordion
              value={header ? header : "Untitled"}
              key={header ? `${header}-${index}` : index}
            >
              <IonItem
                slot="header"
                className="ag-accordion-header ion-no-padding"
              >
                <IonLabel style={{ whiteSpace: "normal" }}>
                  {`${header ? header : ""}`}
                </IonLabel>
              </IonItem>
              <div className="ag-accordion-content" slot="content">
                <span dangerouslySetInnerHTML={{ __html: content }} />
                {children}
                {buttons && buttons[index]}
              </div>
            </IonAccordion>
          ) : (
            <IonCard
              key={header ? `${header}-${index}` : index}
              className="ag-accordion-split-mode"
            >
              <IonAccordion value={header}>
                <IonItem slot="header" className="ag-accordion-header">
                  <IonLabel style={{ whiteSpace: "normal" }}>{header}</IonLabel>
                </IonItem>
                <div className="ag-accordion-content" slot="content">
                  <span dangerouslySetInnerHTML={{ __html: content }} />
                  {children}
                  {buttons && buttons[index]}
                </div>
              </IonAccordion>
            </IonCard>
          );
        })}
    </IonAccordionGroup>
  );
};

export default Accordion;
