import { UserDetails } from "../project-types";
import { openDB } from "idb";
import { handleRefresh } from "./cognito";

export type UserDetailsWithSub = UserDetails & { sub: string };

const storeName = "shortStorage";

const dbPromise = openDB("data.local", 1, {
  upgrade(db) {
    db.createObjectStore(storeName, {
      keyPath: "key",
    });
  },
});

async function get(key: string) {
  return (await (await dbPromise).get(storeName, key))?.val;
}

async function set(key: string, val: any) {
  return (await dbPromise).put(storeName, {
    val,
    key,
  });
}

type NotificationsFlag = "no" | "maybe" | "yes";
export const setNotificationsFlag = (flag: NotificationsFlag) => {
  set("notificationsBottomSheetShown", flag);
};
export const getNotificationsFlag = (): Promise<NotificationsFlag> => {
  return get("notificationsBottomSheetShown").then((res) => {
    if (res === undefined) {
      return "maybe";
    }
    return res;
  });
};

export const setGuestMode = (guestMode: boolean) => {
  set("guestMode", guestMode);
};

export const getGuestMode = (): Promise<boolean> => {
  return get("guestMode").then((res) => {
    if (res === undefined) {
      return false;
    }
    return res;
  });
};

export const setPropertyIds = (ids: string[]) => {
  set("propertyIds", JSON.stringify(ids));
};

export const getPropertyIds = (): Promise<string[]> => {
  return get("propertyIds").then((res) => {
    if (res === undefined) {
      return [];
    }
    return JSON.parse(res);
  });
};

export const setRefreshToken = (token: string) => {
  set("refreshToken", token);
};

export const getRefreshToken = (): Promise<string> => {
  return get("refreshToken");
};

export const setJWT = (token: string) => {
  set("accessToken", token);
};

export const getJWTFromStorage = async (): Promise<string> => {
  return get("accessToken").then((res) => {
    if (res === undefined) {
      return "";
    }
    return res;
  });
};

export const getJWT = async (): Promise<string> => {
  const token = await getJWTFromStorage();

  const jwtExp = await getJwtExpiry();
  const { expiry } = jwtExp;

  //check if the token has 5 minutes left to expire
  if (expiry - new Date().getTime() < 5 * 60 * 1000) {
    const res = await handleRefresh();

    if (res) {
      const newToken = await get("accessToken").then((res) => {
        if (res === undefined) {
          return "";
        }
        return res;
      });
      return Promise.resolve(newToken);
    }

    return Promise.reject("Token expired");
  }

  return Promise.resolve(token);
};

export const setJwtExpiry = (expiry: number) => {
  return set("jwtExpiry", { expiry });
};

export const getJwtExpiry = () => {
  const expiry: Promise<{ expiry: number }> = get("jwtExpiry");
  return expiry;
};

export const setUserSub = (sub: string | null) => {
  return set("sub", sub);
};

export const getUserSub = () => {
  const sub: Promise<string> = get("sub");
  return sub;
};
