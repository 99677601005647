import { AgResponsePage } from "../../components/AgResponsePage";
import { Block, Text } from "../../components/ui";
import { useHistory } from "react-router-dom";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import Button from "../../components/ui/Button";
import { NavFooter } from "../../components/NavFooter";
import { handleLogout } from "../../api/cognito";
import Divider from "../../components/ui/Divider";
import {
  IonCol,
  IonGrid,
  IonIcon,
  IonRouterOutlet,
  IonRow,
} from "@ionic/react";
import { Route } from "react-router-dom";
import PersonalInformation from "./PersonalInformation";
import { FullscreenLoader } from "../../components/ui/FullscreenLoader";
import { arrowForwardOutline, personOutline } from "ionicons/icons";
import { MainSettings, mainSettings } from "./settings";
import LoginAndSecurity from "./LoginAndSecurity";
import Notifications from "./Notifications";
import PrivacyAndSharing from "./PrivacyAndSharing";
import Help from "./Help";
import { getUser, updateUser } from "../../api/user";
import { UserDetails } from "../../project-types";
import { isGuestUser } from "../../api/guest";
import { BottomSheetContext } from "../../components/ui/BottomSheet";
import { GenericFail } from "../../components/bottomSheet/GenericFail";

export const SettingsPage = () => {
  const history = useHistory();
  const [userData, setUserData] = useState<UserDetails>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingMessage, setLoadingMessage] = useState<string>("");
  const [pageTitle, setTitle] = useState<string>("");

  const [showGuestSettings, setShowGuestSettings] = useState<boolean>(false);
  const settings: MainSettings = useMemo(() => {
    return mainSettings
      .map(({ menus, ...rest }) => {
        const filteredMenu = menus.filter(({ isGuestViewable, isHidden }) => {
          return (isGuestViewable || !showGuestSettings) && isHidden !== true;
        });

        return {
          ...rest,
          menus: filteredMenu,
        };
      })
      .filter(({ menus }) => menus.length > 0);
  }, [showGuestSettings]);

  useEffect(() => {
    isGuestUser().then((isGuest) => {
      if (!isGuest) {
        getUser().then((data) => {
          if (data === undefined) {
            history.push("/login");
          }
          setUserData(data);
        });
      } else {
        setShowGuestSettings(true);
        setUserData({} as UserDetails);
      }
    });
  }, []);

  useEffect(() => {
    if (!loading) {
      setLoadingMessage("");
    }
  }, [loading]);

  const [editContact, setEditContact] = useState<boolean>(false);
  const { setBottomSheetProps } = useContext(BottomSheetContext);

  const doUpdateUser = useCallback(
    ({
      data,
      loadingMessage,
      onErrorMessage,
    }: {
      data: Partial<UserDetails>;
      loadingMessage: string;
      onErrorMessage: string;
    }) => {
      setLoadingMessage(loadingMessage);
      setLoading(true);
      updateUser(data)
        .then((res) => {
          if (res) {
            getUser().then((data) => {
              if (data === undefined) {
                setLoading(false);
                history.push("/login");
              }
              setUserData(data);
              setLoading(false);
            });
          } else {
            throw new Error(onErrorMessage);
          }
        })
        .catch((e) => {
          console.error("Error", e);

          setBottomSheetProps({
            isOpen: true,
            isCloseCtaVisible: false,
            children: <GenericFail />,
          });
        });
    },
    []
  );

  return (
    <AgResponsePage
      onButtonClick={() => {
        if (editContact) {
          setEditContact(false);
        } else {
          history.push("/settings");
          setTitle("");
        }
      }}
      showHeader={editContact}
      title={pageTitle}
      buttonType={editContact ? "close" : "back"}
      footer={<NavFooter />}
      hideCtas
    >
      {(!userData || loading) && (
        <FullscreenLoader loadingMessage={loadingMessage} />
      )}
      {userData && (
        <IonRouterOutlet
          style={{ overflowY: "scroll" }}
          className="ion-no-padding"
        >
          <Route path={"/settings"} exact>
            <main className="margin-y-32 margin-x-24">
              <Text
                type="display"
                size="large"
                as="h1"
                className="margin-bottom-32 margin-top-0"
              >
                Profile
              </Text>
              {!showGuestSettings && <LoggedInAs userData={userData} />}
              {showGuestSettings && <LogInCta />}

              <IonGrid className="ion-no-padding">
                {settings.map(({ title, menus }) => (
                  <div key={title}>
                    <Divider className="margin-y-32" />
                    <IonRow>
                      <IonCol>
                        <Text
                          type="display"
                          size="small"
                          as="h2"
                          bold
                          className="margin-top-0 margin-bottom-16"
                        >
                          {title}
                        </Text>
                      </IonCol>
                    </IonRow>
                    {menus.map((menu, index) => {
                      const formattedName = menu.name.replaceAll("&", "and");
                      return (
                        <div key={menu.name}>
                          <IonRow
                            onClick={() => {
                              if (menu.callback) {
                                menu.callback();
                              } else if (menu.externalURL) {
                                window.open(menu.externalURL, "_blank");
                              } else if (menu.email) {
                                window.open(`mailto:${menu.email}`, "_blank");
                              } else {
                                history.push(
                                  `/settings/${formattedName
                                    .toLowerCase()
                                    .split(" ")
                                    .join("-")}`
                                );
                              }
                            }}
                          >
                            <IonCol>{menu.name}</IonCol>
                            <IonCol size="1" className="ion-text-end">
                              <IonIcon
                                icon={arrowForwardOutline}
                                style={{ width: "22px", height: "22px" }}
                              />
                            </IonCol>
                          </IonRow>
                          {index !== menus.length - 1 && (
                            <IonRow>
                              <IonCol>
                                <Divider
                                  variant="secondary"
                                  className="margin-y-16"
                                />
                              </IonCol>
                            </IonRow>
                          )}
                        </div>
                      );
                    })}
                  </div>
                ))}
              </IonGrid>

              {!showGuestSettings && (
                <>
                  <Divider className="margin-y-32" />

                  <Block>
                    <Button
                      variant="outline"
                      onClick={() => {
                        handleLogout().then(() => {
                          history.push("/login");
                        });
                      }}
                    >
                      Logout
                    </Button>
                  </Block>
                </>
              )}

              <Block fullWidth className="ion-text-center">
                <Text type="body" size="medium" className="margin-bottom-16">
                  Version 1.0.30
                </Text>
              </Block>
            </main>
          </Route>
          <Route path={"/settings/personal-information"} exact>
            <main className="margin-y-32 margin-x-24">
              <PersonalInformation
                loading={loading}
                user={userData}
                onUpdate={(updatedUser) => {
                  doUpdateUser({
                    data: updatedUser,
                    loadingMessage: "Updating user's contact details",
                    onErrorMessage: "Unable to update user's contact details",
                  });
                }}
                editContact={editContact}
                onEdit={(editEnabled) => {
                  if (editEnabled) setTitle("Edit contact details");
                  setEditContact(editEnabled);
                }}
                onLoad={({ title }) => {
                  setTitle(title);
                }}
              />
            </main>
          </Route>

          <Route path={"/settings/login-and-security"} exact>
            <main className="margin-y-32 margin-x-24">
              <LoginAndSecurity
                user={userData}
                onLoad={({ title }) => {
                  setTitle(title);
                }}
                onEdit={(editEnabled) => {
                  if (editEnabled) setTitle("Edit password");
                  setEditContact(editEnabled);
                }}
              />
            </main>
          </Route>

          <Route path={"/settings/notifications"} exact>
            <main className="margin-y-32 margin-x-24">
              <Notifications
                user={userData}
                onUpdate={(updatedNotifications) => {
                  doUpdateUser({
                    data: updatedNotifications,
                    loadingMessage: "Updating user's notification settings",
                    onErrorMessage:
                      "Unable to update user's notifications settings",
                  });
                }}
                onLoad={({ title }) => {
                  setTitle(title);
                }}
              />
            </main>
          </Route>

          <Route path={"/settings/privacy-and-sharing"} exact>
            <main className="margin-y-32 margin-x-24">
              <PrivacyAndSharing
                user={userData}
                onUpdate={(updatedDataSharing) => {
                  doUpdateUser({
                    data: updatedDataSharing,
                    loadingMessage: "Updating user's privacy settings",
                    onErrorMessage: "Unable to update user's privacy settings",
                  });
                }}
                onLoad={({ title }) => {
                  setTitle(title);
                }}
              />
            </main>
          </Route>

          <Route path={"/settings/get-help"} exact>
            <main className="margin-y-32 margin-x-24">
              <Help
                onLoad={({ title }) => {
                  setTitle(title);
                }}
              />
            </main>
          </Route>
        </IonRouterOutlet>
      )}
    </AgResponsePage>
  );
};

const LogInCta = () => {
  const history = useHistory();
  const handleCtaClick = useCallback(() => {
    history.push(`/login?hideGuestLogin=1&nextRoute=settings`);
  }, []);
  return (
    <>
      <Text
        type="heading"
        size="small"
        bold
        className="margin-bottom-32 margin-top-0"
        as="p"
      >
        You're not signed in
      </Text>
      <Button
        variant="tertiary"
        icon={personOutline}
        expand="block"
        onClick={handleCtaClick}
      >
        Sign in or create account
      </Button>
    </>
  );
};

const LoggedInAs = ({ userData }: { userData: UserDetails }) => {
  return (
    <Text
      type="heading"
      size="small"
      bold
      className="margin-bottom-32 margin-top-0"
      as="p"
    >
      {`Logged in as ${userData.firstName} ${userData.lastName}`}
    </Text>
  );
};
