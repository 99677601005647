import React from "react";
import { IonToggle } from "@ionic/react";
import "./Toggle.scss";

export type ToggleProps = {
  variant?: "primary" | "secondary";
  disabled?: boolean;
  checked?: boolean;
  // onChange?: () => void;
  onChange?: (checked: boolean) => void;
};

const Toggle: React.FC<ToggleProps> = ({
  variant,
  disabled,
  checked,
  onChange,
}) => {
  return (
    <IonToggle
      className={`ag-toggle ag-toggle-${variant}`}
      disabled={disabled}
      checked={checked}
      onIonChange={(e) => onChange && onChange(e.detail.checked)}
    />
  );
};

export default Toggle;
