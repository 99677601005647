import { Text } from "./Text";
import "./TextBreak.scss";

type TextBreakProps = {
  text: string;
  className?: string;
};

export const TextBreak = ({ text, className }: TextBreakProps) => {
  return (
    <div className={`ag-text-break ${className ? className : ""}`}>
      <Text
        type="body"
        size="large"
        shade="grey"
        as="span"
        className={`ag-text-break-text`}
      >
        {text}
      </Text>
    </div>
  );
};
