import { useCallback, useContext, useMemo } from "react";
import {
  EmergencyDetails,
  PropertyDetailsWithBoundary,
  ZoneActionDetails,
} from "../../project-types";
import { Card, Tag, Text } from "../ui";
import { BottomSheetContext } from "../ui/BottomSheet";
import { arrowForwardOutline } from "ionicons/icons";
import {
  propertyInZoneCardColor,
  propertyInZoneCardBorderColor,
} from "../../theme/zoneColours";
import "./Properties.scss";
import { useHistory } from "react-router";
import Button from "../ui/Button";

type PropertiesProps = {
  affectedProperties: Array<PropertyDetailsWithBoundary>;
  tasks: Array<ZoneActionDetails>;
  selectedZone: number;
  assets: EmergencyDetails["assets"];
};

const Properties = ({
  affectedProperties,
  tasks,
  selectedZone,
  assets,
}: PropertiesProps) => {
  const { setBottomSheetProps } = useContext(BottomSheetContext);
  const history = useHistory();

  const checkTheMapCta = useCallback(() => {
    setBottomSheetProps({
      isOpen: true,
      title: "Check the map",
      children: (
        <>
          <Text type="body" size="large" as="p">
            The app uses your property boundary to determine which zone you're
            in.
          </Text>

          <Text type="body" size="large" as="p">
            If you think your property is in a higher priority zone, then you
            must complete the tasks and follow the restrictions from that zone
            instead.
          </Text>
        </>
      ),
    });
  }, []);

  const tasksInAffectedZone = useMemo(() => {
    return tasks
      .map(
        (task) =>
          task["action-assigned-zones"].includes(`Zone ${selectedZone + 1}`) &&
          task["action-title"]
      )
      .filter((taskInZone) => taskInZone);
  }, [tasks, selectedZone]);

  const numTasksInAffectedZone = useMemo(() => {
    return tasksInAffectedZone.length;
  }, [tasksInAffectedZone]);

  const isTaskBottomSheetClickable = useMemo(() => {
    return numTasksInAffectedZone > 0;
  }, [numTasksInAffectedZone]);

  return (
    <>
      <Text className="margin-y-16" type="body" size="large" as="p">
        Your property might be in a different zone.{" "}
        <Button variant="link" onClick={checkTheMapCta}>
          {" "}
          Click here
        </Button>
        {` for more information.`}
      </Text>
      {affectedProperties && affectedProperties.length > 0 ? (
        affectedProperties.map((property) => (
          <Card
            key={property.name}
            title={property.name}
            customColor={propertyInZoneCardColor[selectedZone - 1]}
            borderColor={propertyInZoneCardBorderColor[selectedZone - 1]}
            rightIcon
            titleIcon={
              isTaskBottomSheetClickable ? arrowForwardOutline : undefined
            }
            cardOnClickAction={() => {
              if (!isTaskBottomSheetClickable) {
                return;
              }

              setBottomSheetProps({
                isOpen: true,
                title: `${property.name}`,
                children: (
                  <>
                    {tasks.map(
                      (task, i) =>
                        task["action-assigned-zones"].includes(
                          `Zone ${selectedZone + 1}`
                        ) &&
                        task["action-title"] && (
                          <Card
                            key={task["action-id"] || i}
                            rightIcon
                            titleIcon={arrowForwardOutline}
                            title={task["action-title"]}
                            variant="secondary"
                            borderColor="#22272B"
                            cardOnClickAction={() => {
                              setBottomSheetProps({
                                isOpen: false,
                              });
                              history.push(
                                `/form/${task["action-id"]}?propertyID=${property.propertyId}`
                              );
                            }}
                          />
                        )
                    )}
                  </>
                ),
              });
            }}
          >
            {isTaskBottomSheetClickable && (
              <Tag variant="light">
                <span className="task-number">{numTasksInAffectedZone}</span>
                {numTasksInAffectedZone === 1 ? " task" : " tasks"}
              </Tag>
            )}
          </Card>
        ))
      ) : (
        <Card
          title={`You have no properties ${
            assets.includes("All properties")
              ? ""
              : `with ${assets.map((asset) => " " + asset.toLocaleLowerCase())}`
          } in this zone`}
          customColor={propertyInZoneCardColor[selectedZone]}
          borderColor={propertyInZoneCardBorderColor[selectedZone]}
        />
      )}
    </>
  );
};

export default Properties;
