import { personOutline } from "ionicons/icons";
import EmptyState from "./EmptyState";
import { useHistory } from "react-router";
import { useCallback } from "react";

type GuestStateProps = {
  body: string;
  nextRoute?: string;
};

export const GuestState = ({ body, nextRoute = "" }: GuestStateProps) => {
  const history = useHistory();

  const handleCtaClick = useCallback(() => {
    history.push(`/login?hideGuestLogin=1&nextRoute=${nextRoute}`);
  }, []);

  return (
    <EmptyState
      icon={personOutline}
      header="You're not signed in"
      body={body}
      buttons={[
        {
          text: "Sign in or create account",
          action: handleCtaClick,
          icon: personOutline,
        },
      ]}
    />
  );
};
