import axios from "axios";
import { getJWT } from "./localStorage";
import { REACT_APP_API_URL } from "../env";
import { PropertyBounds } from "../project-types";

export type AddressSearchResult = {
  address: string;
  id: string;
  rank: number;
};

export const search = async (searchRequest: string, maxResults?: number) => {
  const token = await getJWT()
    .then((token) => token)
    .catch(() => undefined);

  if (!token) return undefined;

  try {
    const result = await axios.get<Array<AddressSearchResult>>(
      `${REACT_APP_API_URL}/utils/address-search/${searchRequest}` +
        (maxResults ? `?maxResults=${maxResults}` : ""),
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return result.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export type AddressLocateResult = {
  hasGeo: boolean;
  geo: {
    geoFeature: string;
    geometry: {
      coordinates: [number, number];
      type: "Point";
    };
  };
  addressDetails: {
    complexUnitType?: string;
    complexUnitIdentifier?: string;
    cadastralIdentifier: string;
    formattedAddress: string;
    localityName: string;
    postcode: string;
    siteName?: string;
    stateTerritory: string;
    streetName: string;
    streetNumber1: string;
    streetNumber2?: string;
    streetType?: string;
  };
};

export const locate = async (addressId: string) => {
  const token = await getJWT()
    .then((token) => token)
    .catch(() => undefined);

  if (!token) return undefined;

  try {
    const result = await axios.get<Omit<AddressLocateResult, "hasGeo">>(
      `${REACT_APP_API_URL}/utils/address-locate/${addressId}`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    return { hasGeo: result.status === 200, ...result.data };
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const verifyPoint = async (latlng: { lat: number; lng: number }) => {
  const token = await getJWT()
    .then((token) => token)
    .catch(() => undefined);

  if (!token) return undefined;
  const result = await axios.post<string>(
    `${REACT_APP_API_URL}/utils/verify-point`,
    { latlng },
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  return result.data;
};

export const getPropertyBounds = async (latlng: {
  lat: number;
  lng: number;
}) => {
  const token = await getJWT()
    .then((token) => token)
    .catch(() => undefined);

  if (!token) return undefined;
  const result = await axios.post<PropertyBounds>(
    `${REACT_APP_API_URL}/utils/property-bounds`,
    { latlng },
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  return result.data;
};
