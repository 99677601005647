import { Text } from "../../components/ui";
import { IonCol, IonGrid, IonRow } from "@ionic/react";
import Toggle from "../../components/ui/Toggle";
import Divider from "../../components/ui/Divider";
import { UserDetails } from "../../project-types";
import { logAnalyticsEvent } from "../../api/firebase";
import { useEffect } from "react";

type PrivacyAndSharingProps = {
  user: UserDetails;
  onUpdate: (updatedUser: Partial<UserDetails>) => void;
  onLoad: (options: { title: string }) => void;
};

const PrivacyAndSharing = ({
  onUpdate,
  user,
  onLoad,
}: PrivacyAndSharingProps) => {
  useEffect(() => {
    onLoad({
      title: "Privacy and sharing",
    });
  }, []);

  return (
    <IonGrid>
      <IonRow>
        <IonCol>
          <Text type="heading" size="small" bold>
            Rural Assistance Authority (RAA)
          </Text>
        </IonCol>
        <IonCol size="2">
          <Toggle
            checked={user?.raaDataShareOptIn}
            onChange={(checked) => {
              onUpdate({ raaDataShareOptIn: checked });
              if (checked) logAnalyticsEvent("RAA-data-sharing opt-in", {});
            }}
          />
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol size="10">
          <Text type="body" size="small">
            Opt-in to share your profile data with the{" "}
            <a
              href="https://www.raa.nsw.gov.au/"
              target="_blank"
              rel="noreferrer"
            >
              Rural Assistance Authority
            </a>{" "}
            for the purpose of recovery support. This information will only be
            accessed if you choose to contact the recovery agency directly.
          </Text>
        </IonCol>
      </IonRow>
      <Divider />
    </IonGrid>
  );
};

export default PrivacyAndSharing;
