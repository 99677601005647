import { isPlatform } from "@ionic/react";
import "./DesktopCta.scss";

export const DesktopCta = () => {
  return (
    <section className="ion-hide-lg-down ag-desktop-cta">
      <div>
        <img
          src="/assets/nsw-government-logo.webp"
          alt="NSW Government logo"
          className="margin-right-24"
        />
        <h2 className="ion-no-padding ion-no-margin">BioResponse NSW</h2>
      </div>
      {!isPlatform("capacitor") && (
        <div>
          <a href="/" target="_blank">
            <img src="/assets/app-store-ios.png" alt="iOS App Download" />
          </a>
          <a href="/" target="_blank" className="margin-left-16">
            <img
              src="/assets/app-store-android.png"
              alt="Android App Download"
            />
          </a>
        </div>
      )}
    </section>
  );
};

export default DesktopCta;
