import {
  Fragment,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import { ButtonProps, Card, Input, Text } from "./ui";
import { useDebounce } from "use-debounce";
import { search as SearchIcon } from "ionicons/icons";
import "./AgAddressSearch.scss";
import {
  AddressLocateResult,
  AddressSearchResult,
  locate,
  search,
} from "../api/addressSearch";
import { List } from "./ui/List";
import { useHistory } from "react-router-dom";
import spinner from "./spinner.svg";
import { SnackbarHandlerContext } from "./SnackbarHandler";
import { useDevice } from "../hooks/useDevice";

type AgAddressSearchProps = {
  inMap?: boolean;
  clearSearch?: boolean;
  clearSearchHandled?: () => void;
  guidance?: boolean;
  onAddressSelected: (
    address: AddressSearchResult & AddressLocateResult
  ) => void;
  forceLoading?: boolean;
  overrideBackground?: string;
  onAddressClicked?: () => void;
  suffixCta?: ButtonProps;
};

const isLoadingTimeout = 5000;

const InMapWrap = ({
  children,
  wrap,
}: PropsWithChildren<{ wrap: boolean }>) => {
  return wrap ? (
    <div className="ag-address-search leaflet-top ">
      <div className="leaflet-control ion-padding-horizontal">{children}</div>
    </div>
  ) : (
    <>{children}</>
  );
};

export const AgAddressSearch = ({
  inMap = false,
  onAddressSelected,
  forceLoading,
  guidance = false,
  clearSearch,
  clearSearchHandled,
  overrideBackground,
  onAddressClicked,
  suffixCta,
}: AgAddressSearchProps) => {
  const [address, setAddress] = useState("");
  const [justSelected, setJustSelected] = useState(false);
  const [value] = useDebounce(address, 500);
  const [results, setResults] = useState<Array<AddressSearchResult>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [addressNotFound, setAddressNotFound] = useState<boolean>(false);
  const [isStillSearching, setIsStillSearching] = useState<boolean>(false);
  const { showDismissableSnackbar } = useContext(SnackbarHandlerContext);

  const history = useHistory();
  const { isMobile } = useDevice();

  useEffect(() => {
    if (clearSearch) {
      setAddress("");
      setResults([]);
      clearSearchHandled && clearSearchHandled();
    }
  }, [clearSearch]);

  useEffect(() => {
    if (value) {
      if (value.length < 4) setResults([]);
      if (value.length > 4 && !justSelected) {
        setLoading(true);
        setAddressNotFound(false);
        search(value)
          .then((result) => {
            if (result?.length === 0) setAddressNotFound(true);
            if (result === undefined) {
              setAddressNotFound(true);
              history.push("/login");
              setAddressNotFound(true);
              return;
            }
            setLoading(false);
            setIsStillSearching(false);
            setResults(result);
          })
          .catch((e) => {
            setResults([]);
            setIsStillSearching(false);

            console.error(e);

            showDismissableSnackbar(
              "We couldn't find your address. Drop a pin on the map instead."
            );
          });
      }
    }
    setJustSelected(false);
    setAddressNotFound(false);
    setIsStillSearching(false);
  }, [value]);

  useEffect(() => {
    if (clearSearch) {
      setAddress("");
      setResults([]);
      clearSearchHandled && clearSearchHandled();
    }
  }, [clearSearch]);

  useEffect(() => {
    let searchTimeout: number;
    const isLoading = loading || forceLoading;
    if (isLoading) {
      searchTimeout = window.setTimeout(() => {
        setIsStillSearching(true);
      }, isLoadingTimeout);
    } else {
      setIsStillSearching(false);
    }

    return () => {
      window.clearTimeout(searchTimeout);
    };
  }, [loading, forceLoading]);

  return (
    <InMapWrap wrap={inMap}>
      <Input
        {...(overrideBackground
          ? {
              style: {
                backgroundColor: overrideBackground,
              },
            }
          : {})}
        variant="transparent"
        placeholder="Enter Address"
        value={address}
        onChange={(value) => setAddress(value)}
        otherIcon={!(loading || forceLoading) ? SearchIcon : spinner}
        // onFocus={() => setAddress("")}
        suffixCta={suffixCta}
      />
      {guidance && results.length === 0 && (
        <div className="ag-address-guidance ">
          <Text type="body" size="large">
            Or move the map below to drop a pin
          </Text>
        </div>
      )}
      {results.length > 0 && (
        <List
          clickable
          onItemClicked={(key) => {
            onAddressClicked && onAddressClicked();
            const address = results.find((result) => result.id === key);
            setLoading(true);
            setResults([]);
            setJustSelected(true);
            if (address) {
              setAddress(address.address);
              locate(address.id)
                .then((result) => {
                  if (result === undefined) {
                    history.push("/login");
                    return;
                  }
                  setLoading(false);
                  onAddressSelected({ ...address, ...result });
                })
                .catch((e) => {
                  console.error(e);
                  setLoading(false);

                  showDismissableSnackbar(
                    "Error searching for address location details. Try placing a pin on the map instead."
                  );
                });
            }
          }}
          variant="secondary"
          items={results.map((result) => {
            const [street, ...rest] = result.address.split(",");
            return (
              <Fragment key={result.id}>
                <Text size="medium" type="body" as="p">
                  {street}
                </Text>
                <Text size="small" type="body" as="p">
                  {rest.join(",")}
                </Text>
              </Fragment>
            );
          })}
        />
      )}
      {addressNotFound && (
        <Card customColor="#EAEDF4">
          <Text type="body" size="large" as="p">
            No results found. Move the map to locate your property and then{" "}
            {isMobile ? "tap" : "click"} to drop a pin.
          </Text>
        </Card>
      )}
      {isStillSearching && (
        <Card customColor="#EAEDF4">
          <Text type="body" size="large" as="p">
            This is taking longer than expected, stick with us.
          </Text>
        </Card>
      )}
    </InMapWrap>
  );
};
