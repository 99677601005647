import { useRef, forwardRef, useImperativeHandle } from "react";
import { IonPage, IonContent } from "@ionic/react";
import { Header, HeaderProps } from "./Header";
import DesktopCta from "./DesktopCta";
import DownloadAppAlert from "./DownloadAppAlert";

import "./AgResponsePage.scss";
import clsx from "clsx";

type AgResponsePageProps = {
  showHeader?: boolean;
  title?: string;
  footer?: React.ReactNode;
  children: React.ReactNode;
  noPadding?: boolean;
  hideButton?: boolean;
  fullWidth?: boolean;
  hideCtas?: boolean;
} & Partial<Pick<HeaderProps, "buttonType" | "onButtonClick">>;

export type AgResponsePageRef = typeof IonPage & {
  scrollContentToTop: () => void;
};

export const AgResponsePage = forwardRef(
  (
    {
      showHeader,
      children,
      footer,
      title,
      buttonType,
      onButtonClick,
      noPadding,
      hideButton,
      fullWidth = false,
      hideCtas = false,
    }: AgResponsePageProps,
    ref
  ) => {
    const contentRef = useRef<HTMLIonContentElement>(null);
    const isHeaderShown = (hideButton && !title) || !!(showHeader || title);

    useImperativeHandle(ref, () => ({
      scrollContentToTop: () => {
        if (contentRef.current) {
          contentRef.current.scrollToTop();
        }
      },
    }));

    return (
      <IonPage
        className={clsx({
          "ag-app-maxw": fullWidth,
          "ion-margin-top": !isHeaderShown,
        })}
      >
        {isHeaderShown && (
          <Header
            title={title ? title : ""}
            hideButton={hideButton}
            buttonType={buttonType ? buttonType : "back"}
            onButtonClick={
              onButtonClick
                ? onButtonClick
                : () => {
                    console.error("err");
                  }
            }
          />
        )}
        <IonContent
          ref={contentRef}
          className={clsx("ag-app-content", {
            "ag-app-padding": !noPadding,
          })}
        >
          {!hideCtas && (
            <>
              <DownloadAppAlert />
              <DesktopCta />
            </>
          )}

          {children}
        </IonContent>
        {footer && footer}
      </IonPage>
    );
  }
);
