import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { AgResponsePage } from "../../components/AgResponsePage";
import {
  getDecodedToken,
  generateEmail,
  generatePassword,
  TokenResponse,
} from "../../api/snsw";
import { handleLogin, handleSignup } from "../../api/cognito";
import { logAnalyticsEvent } from "../../api/firebase";
import { Register } from "../Login/register";
import { addUser, getUser, updateUser, usersByEmail } from "../../api/user";
import { BottomSheetContext } from "../../components/ui/BottomSheet";
import { AccountCreatedState } from "../../components/bottomSheet/AccountCreated";
import FullscreenLoader from "../../components/ui/FullscreenLoader";
import { Device } from "@capacitor/device";

export const SnswRedirect = () => {
  const location = useLocation();
  const hasRun = useRef(false);
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(true);
  const [userSub, setUserSub] = useState<string>("");
  const [snswData, setSnswData] = useState<{
    email: string;
    sub: string;
    family_name: string;
    given_name: string;
  }>({
    email: "",
    sub: "",
    family_name: "",
    given_name: "",
  });
  const [loginDetails, setLoginDetails] = useState<{
    email: string;
    password: string;
  }>({
    email: "",
    password: "",
  });

  const { setBottomSheetProps } = useContext(BottomSheetContext);

  const handleConfirm = useCallback(() => {
    setBottomSheetProps({
      isOpen: true,
      isCloseCtaVisible: false,
      children: <AccountCreatedState />,
    });

    history.push("/emergencies");
  }, [history]);

  useEffect(() => {
    const isAndroidWeb = async () => {
      return await Device.getInfo().then(({ platform, operatingSystem }) => {
        return platform === "web" && operatingSystem === "android"
      });
    };

    const getToken = async (code: string, state: string) => {
      const token = await getDecodedToken(code, state)
        .then((res) => res)
        .catch((err) => {
          console.error(err);
          return undefined;
        });

      return token;
    };

    const handleOauthLogin = async (token: TokenResponse | undefined) => {
      if (token === undefined) {
        history.push("/login");
        return;
      }

      const { email, sub, family_name = "", given_name = "" } = token;

      const generatedEmail = generateEmail(sub);
      const generatedPassword = generatePassword(sub);

      const loggedIn = await handleLogin(generatedEmail, generatedPassword)
        .then((res) => {
          return res;
        })
        .catch((err) => {
          console.error({ err });
          return false;
        });

      if (loggedIn) {
        const user = await getUser();

        if (user && user.email !== email) {
          await updateUser({
            email,
          });
        }

        logAnalyticsEvent("Login", { email, snsw: true });
        history.push("/emergencies");
        return;
      }

      const appUser = await usersByEmail(email);

      setSnswData({ email, sub, family_name, given_name });

      const cogSub = await handleSignup(generatedEmail, generatedPassword)
        .then((res) => {
          console.error({ res });
          return res;
        })
        .catch((err) => {
          console.error({ err });
          return "";
        });

      if (cogSub === "") {
        return;
      }

      if (appUser) {
        //swap all the user data over to the new user
      }

      setUserSub(cogSub);
      setLoginDetails({
        email: generatedEmail,
        password: generatedPassword,
      });
      setLoading(false);
    };

    // Only want to run this once per render
    if (!hasRun.current) {
      hasRun.current = true;

      const urlParams = new URLSearchParams(location.search);
      const code = urlParams.get("code");
      const state = urlParams.get("state");
      const token = urlParams.get("token");

      //get the full url
      const url = window.location.href;
      //remove the schema
      const urlWithoutSchema = url.split("://")[1];
      //remove the query params
      const urlWithoutQueryParams = urlWithoutSchema.split("?")[0];

      if (code && state) {
        Promise.all([isAndroidWeb(), getToken(code, state)]).then(
          ([isAndroidWeb, token]) => {
            if (isAndroidWeb) {
              const t = btoa(JSON.stringify(token));
              const newUrl = `intent://${urlWithoutQueryParams}?token=${t}`
              window.location.href = newUrl;
            }
            setTimeout(
              () => {
                handleOauthLogin(token);
              },
              isAndroidWeb ? 5000 : 0
            );
          }
        );
      } else if (token) {
        handleOauthLogin(JSON.parse(atob(token)));
      }
    }
  }, []);

  return (
    // TODO: Center the loader
    <AgResponsePage>
      {loading && <FullscreenLoader />}
      {!loading && (
        <Register
          email={snswData.email}
          firstName={snswData.given_name}
          lastName={snswData.family_name}
          isSnsw={true}
          onSnswSubmit={async ({ email, firstName, lastName, thirdParty }) => {
            await addUser(userSub, {
              email,
              firstName,
              lastName,
              phone: "",
              raaDataShareOptIn: thirdParty,
              pushTokens: "",
              ABN: "",
              emailPreferred: false,
            });

            await handleLogin(loginDetails.email, loginDetails.password);

            await updateUser({
              snswId: snswData.sub,
            });

            logAnalyticsEvent("Login", { email: snswData.email, snsw: true });
            handleConfirm();
          }}
        />
      )}
    </AgResponsePage>
  );
};
