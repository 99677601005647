import { IonCard, IonIcon } from "@ionic/react";
import { resizeOutline } from "ionicons/icons";
import { useHistory } from "react-router";
import { AgMap } from "../AgMap";
import { GeoJSON } from "react-leaflet";
import { EmergencyMapData } from "../../project-types";
import { zoneColors } from "../../theme/zoneColours";
import "./SmallMap.scss";

type SmallMapProps = {
  mapData: EmergencyMapData;
  id: string;
};

const SmallMap = ({ mapData, id }: SmallMapProps) => {
  const history = useHistory();

  return (
    <IonCard
      className="map-card-wrapper"
      onClick={() => history.push(`/emergency/${id}/map`)}
    >
      <div className="map-expand-icon-wrapper">
        <IonIcon className="map-expand-icon" icon={resizeOutline} />
      </div>
      <div className="map-wrapper">
        <AgMap
          style={{
            flexGrow: 1,
          }}
          nonInteractive
          geojsonFocus={mapData}
        >
          {mapData &&
            mapData?.features.map((feature, i) => {
              return (
                <GeoJSON
                  key={`${feature.type}-${i}`}
                  data={feature}
                  style={{
                    fillColor: feature.properties.zoneType
                      ? zoneColors[feature.properties.zoneType - 1]
                      : i < 2
                      ? zoneColors[i]
                      : zoneColors[2],
                    fillOpacity: 0.5,
                    weight: 0,
                  }}
                />
              );
            })}
        </AgMap>
      </div>
    </IonCard>
  );
};

export default SmallMap;
