import React from "react";
import { IonFab, IonFabButton, IonIcon } from "@ionic/react";
import "./Fab.scss";

export type FabProps = {
  onClick?: () => void;
  disabled?: boolean;
  icon: string;
  variant?: "primary" | "secondary" | "tertiary";
  label?: string;
  size?: "large" | "small";
};

const Fab: React.FC<FabProps> = ({
  onClick,
  variant,
  disabled,
  icon,
  label,
  size,
}) => {
  const color =
    variant === "primary"
      ? "primary"
      : variant === "secondary"
      ? "dark"
      : "light";
  return (
    <IonFab
      className="ag-fab ion-text-center"
      onClick={() => {
        onClick && onClick();
      }}
    >
      <IonFabButton
        className={`ag-fab-${color} ${label && "ag-fab-label"} ${
          size === "small" ? "ag-fab-small" : ""
        }`}
        color={color}
        disabled={disabled}
      >
        <IonIcon icon={icon} />
      </IonFabButton>
      <span>{label}</span>
    </IonFab>
  );
};

export default Fab;
