export const formatDate = (dateInput: string) => {
  let date: Date;

  const isISOFormat = dateInput.includes("T");
  if (isISOFormat) {
    // Parse the "2023-05-01T11:05:48+10:00" format
    date = new Date(dateInput);
  } else {
    // Parse the "04-05-2023" format
    const [day, month, year] = dateInput.split("-");
    date = new Date(Number(year), Number(month) - 1, Number(day));
  }

  const dayOfWeek = new Intl.DateTimeFormat("en-US", {
    weekday: "short",
  }).format(date);
  const day = date.getDate();
  const month = new Intl.DateTimeFormat("en-US", { month: "long" }).format(
    date,
  );
  const year = date.getFullYear();

  return `${dayOfWeek} ${day} ${month} ${year}`;
};

export default formatDate;
