import "./Text.scss";

export type TextSizeProps = {
  type: "display" | "heading" | "body";
  size: "small" | "medium" | "large";
  bold?: boolean;
};

type TextProps = TextSizeProps & {
  as?: "h1" | "h2" | "h3" | "p" | "span";
  color?:
    | "primary"
    | "secondary"
    | "tertiary"
    | "info"
    | "success"
    | "warning"
    | "danger";
  shade?: "dark" | "darkGrey" | "grey" | "light";
  className?: string;
};

const buildClass = ({
  type,
  size,
  bold,
  color,
  shade,
  className,
}: TextProps) => {
  return `ag-text ag-text-${type}-${size} ${
    bold ? `ag-text-bold` : ""
  } ag-text-${color ? color : "primary"} ag-text-${shade ? shade : "dark"}
  ${className ? className : ""}`;
};
export const Text = ({
  as = "span",
  children,
  type,
  size,
  bold,
  color,
  shade,
  className,
}: React.PropsWithChildren<TextProps>) => {
  const compClassName = buildClass({
    type,
    size,
    bold,
    color,
    shade,
    className,
  });
  switch (as) {
    case "h1":
      return <h1 className={compClassName}>{children}</h1>;
    case "h2":
      return <h2 className={compClassName}>{children}</h2>;
    case "h3":
      return <h3 className={compClassName}>{children}</h3>;
    case "p":
      return <p className={compClassName}>{children}</p>;
    case "span":
    default:
      return (
        <div className={compClassName}>
          <span>{children}</span>
        </div>
      );
  }
};
