import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { logAnalyticsEvent } from "../api/firebase";
import { Capacitor } from "@capacitor/core";
import {
  ActionPerformed,
  PushNotifications,
} from "@capacitor/push-notifications";
import { useHistory } from "react-router-dom";

export const PushNotificationHandler = () => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      PushNotifications.addListener(
        "pushNotificationActionPerformed",
        ({ notification: { data } }: ActionPerformed) => {
          const {
            aps: { category },
          } = data;
          const query = category.split("?")[1];
          const params = query.split("&");

          const nextRoute = params[0].split("=")[1];
          const type = params[2].split("=")[1];

          logAnalyticsEvent("notification_opened", {
            platform: Capacitor.getPlatform(),
            notificationType: type,
          });

          history.push(nextRoute ? nextRoute : "/emergencies");
        }
      );
    }
  }, []);

  useEffect(() => {
    if (!Capacitor.isNativePlatform()) {
      const params = new URLSearchParams(location.search);
      const pushNotification = params.get("from-push");
      const type = params.get("type");

      if (pushNotification === "true") {
        logAnalyticsEvent("notification_opened", {
          platform: Capacitor.getPlatform(),
          notificationType: type,
        });
      }
    }
  }, [location]);
  return null;
};
