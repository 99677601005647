import React from "react";
import { IonToast } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import "./Snackbar.scss";

export type SnackbarProps = Pick<
  React.ComponentProps<typeof IonToast>,
  "isOpen" | "position" | "duration"
> & {
  message: string;
  closeButton?: boolean;
  dismissButton?: boolean;
  actionButton?: { text: string; handler: () => void; role?: string };
};

const Snackbar = ({
  closeButton,
  dismissButton,
  actionButton,
  ...snackbarProps
}: SnackbarProps) => {
  let buttons: Array<Object> = [];

  dismissButton &&
    buttons.push({
      role: "cancel",
      text: "Dismiss",
    });

  actionButton &&
    buttons.push({
      ...actionButton,
      role: actionButton?.role || "info",
    });

  closeButton &&
    buttons.push({
      role: "cancel",
      icon: closeOutline,
    });

  return <IonToast {...snackbarProps} buttons={buttons} />;
};

export default Snackbar;
