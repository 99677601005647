import { useContext } from "react";
import { ZoneRulesTopics } from "../../project-types";
import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { Text } from "../ui";
import Accordion from "../ui/Accordion";
import { informationCircleOutline } from "ionicons/icons";
import Button from "../ui/Button";
import { BottomSheetContext } from "../ui/BottomSheet";
import "./Restrictions.scss";

type RestrictionsProps = {
  zoneRules: Array<ZoneRulesTopics>;
};

const Restrictions = ({ zoneRules }: RestrictionsProps) => {
  const { setBottomSheetProps } = useContext(BottomSheetContext);

  return (
    <>
      {zoneRules &&
        zoneRules.map((zoneRule, index) => (
          <div key={zoneRule["topic-title"] || index}>
            {zoneRule.topics.length > 0 && (
              <>
                <IonGrid className="ion-no-padding">
                  <IonRow className="ion-align-items-center">
                    <IonCol>
                      <Text size="small" type="heading" as="h2" bold>
                        {zoneRule["topic-title"]}
                      </Text>
                    </IonCol>
                    <IonCol
                      size="2"
                      className="flex ion-justify-content-center"
                    >
                      {zoneRule["topic-description"] && (
                        <Button
                          variant="clear"
                          onClick={() => {
                            setBottomSheetProps({
                              isOpen: true,
                              title: `${zoneRule["topic-title"]}`,
                              children: (
                                <>
                                  {zoneRule["topic-description"] ? (
                                    <Text size="small" type="heading" as="h2">
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: zoneRule["topic-description"],
                                        }}
                                      />
                                    </Text>
                                  ) : (
                                    <Text type="body" size="large" as="p">
                                      No description has been provided yet.
                                    </Text>
                                  )}
                                </>
                              ),
                            });
                          }}
                          icon={informationCircleOutline}
                          size="small"
                          className="info-button"
                        ></Button>
                      )}
                    </IonCol>
                  </IonRow>
                </IonGrid>
                <Accordion
                  key={zoneRule["topic-title"]}
                  split
                  items={zoneRule["topics"].filter(
                    (topic) => Object.keys(topic).length > 1
                  )}
                />
              </>
            )}
          </div>
        ))}
    </>
  );
};

export default Restrictions;
