import L from "leaflet";
import { AddressResult, ByAddress } from "../../AddProperty/ByAddress";
import { AgResponsePage } from "../../../components/AgResponsePage";
import { FlowFooter, FlowFooterProps } from "../../../components/FlowFooter";
import { useCallback, useEffect, useState } from "react";
import {
  SwiperChildNullProps,
  SwiperFlow,
} from "../../../components/SwiperFlow";
import ConfirmAddress from "../../AddProperty/ByAddress/ConfirmAddress";
import {
  PropertyAddressDetails,
  PropertyDetailsWithBoundary,
} from "../../../project-types";
import { useLocation, useHistory } from "react-router-dom";
import { saveOutline } from "ionicons/icons";
import { UpdatePropertyType } from "..";

type EditPropertyAddressProps = {
  property: PropertyDetailsWithBoundary;
  isSaving: boolean;
  setUpdatedPropertyDetails: (
    update: Partial<PropertyDetailsWithBoundary>
  ) => void;
  onSave: (saveType?: UpdatePropertyType) => void;
};

export const EditPropertyAddressPage = ({
  property,
  isSaving,
  setUpdatedPropertyDetails,
  onSave,
}: EditPropertyAddressProps) => {
  const [nextClicked, setNextClicked] = useState(false);
  const [backClicked, setBackClicked] = useState(false);

  const [selectedAddress, setSelectedAddress] = useState<AddressResult>();
  const [footerProps, setFooterProps] = useState<Partial<FlowFooterProps>>({});

  const [canExit, setCanExit] = useState(false);
  const [slideJump, setSlideJump] = useState<number | undefined>(undefined);
  const [slideJumpReturn, setSlideJumpReturn] =
    useState<number | undefined>(undefined);

  const location = useLocation();
  const history = useHistory();

  const [initialAddress, setInitialAddress] =
    useState<
      | Pick<PropertyAddressDetails, "name" | "address" | "city" | "postcode">
      | undefined
    >();

  useEffect(() => {
    if (property) {
      setInitialAddress({
        name: property.name,
        address: property.address,
        city: property.city,
        postcode: property.postcode,
      });
    }
  }, [property]);

  useEffect(() => {
    if (location) {
      const params = new URLSearchParams(location.search);
      if (!params.has("noClose")) {
        setCanExit(true);
      }
    }

    // always start on the first slide
    setSlideJump(0);
  }, [location]);

  const onClickHandled = useCallback(() => {
    setNextClicked(false);
    setBackClicked(false);
  }, [setNextClicked, setBackClicked]);

  return (
    <AgResponsePage
      title="Edit Property"
      hideButton={!canExit}
      hideCtas
      footer={
        <FlowFooter
          onNext={() => {
            setNextClicked(true);
          }}
          onBack={() => {
            setBackClicked(true);
          }}
          {...footerProps}
          showLoading={isSaving}
        />
      }
      onButtonClick={() => {
        history.goBack();
      }}
    >
      <SwiperFlow
        updateFooter={setFooterProps}
        nextClicked={nextClicked}
        backClicked={backClicked}
        nextClickHandled={() => {
          if (slideJumpReturn !== undefined) {
            setSlideJump(slideJumpReturn);
            setSlideJumpReturn(undefined);
          }
          onClickHandled();
        }}
        backClickHandled={(didSlideIndexChange) => {
          onClickHandled();

          if (!didSlideIndexChange) {
            history.goBack();
          }
        }}
        slideJump={slideJump}
        onSlideJumpHandled={() => {
          setSlideJump(undefined);
        }}
      >
        <ByAddress
          key="ByAddress"
          {...SwiperChildNullProps}
          onAddressSelected={(address) => {
            const {
              propertyBounds,
              geo: { geometry },
            } = address;
            setSelectedAddress(address);
            // clear address for next step if no address selected
            if (
              !address.address &&
              // dodgy hack - if nothing is selected, coordinates returned are (0,0)
              geometry.coordinates[1] !== 0 &&
              geometry.coordinates[0] !== 0
            ) {
              setInitialAddress(undefined);
            }
            setUpdatedPropertyDetails({
              ...(propertyBounds
                ? {
                    boundary: propertyBounds,
                    boundaryIds: propertyBounds.features.map(({ id }) => {
                      return String(id);
                    }),
                  }
                : {}),
              location: new L.LatLng(
                geometry.coordinates[1],
                geometry.coordinates[0]
              ),
            });
          }}
          initialProperty={property}
          step={"Step 1 of 2"}
        />
        <ConfirmAddress
          key="ConfirmAddress"
          {...SwiperChildNullProps}
          selectedAddress={selectedAddress}
          onAddressConfirmed={(propertyAddress) => {
            setUpdatedPropertyDetails(propertyAddress);
          }}
          initialAddress={initialAddress}
          step={"Step 2 of 2"}
          footerProps={{
            nextText: "Save changes",
            overrideNextIcon: saveOutline,
            overrideNextIconPosition: "start",
          }}
          onFinished={() => {
            onSave("location");
          }}
        />
      </SwiperFlow>
    </AgResponsePage>
  );
};
