import { useCallback } from "react";
import Button from "./Button";
import { IonButton } from "@ionic/react";
import { logAnalyticsEvent } from "../../api/firebase";
import { useHistory } from "react-router";
import { handleLogout } from "../../api/cognito";
import { setGuestMode } from "../../api/localStorage";

type IonButtonProps = React.ComponentProps<typeof IonButton>;

export const GuestButton = (props: IonButtonProps) => {
  const history = useHistory();

  const handleGuestClick = useCallback(() => {
    setGuestMode(true);
    logAnalyticsEvent("guest-sign-in", {});
    handleLogout();
    history.push("/emergencies");
  }, []);

  return (
    <Button
      onClick={handleGuestClick}
      variant="tertiary"
      size="large"
      expand="block"
      {...props}
    >
      Skip for now
    </Button>
  );
};
