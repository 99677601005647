import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonIcon,
} from "@ionic/react";
import "./Card.scss";
import { Text } from "./Text";
import {
  alertOutline,
  checkmarkOutline,
  informationOutline,
} from "ionicons/icons";
import clsx from "clsx";

export type CardProps = {
  title?: string;
  titleIcon?: string;
  subtitle?: string;
  actions?: Array<React.ReactNode>;
  variant?: "primary" | "secondary" | "info" | "success" | "warning" | "danger";
  customColor?: string;
  borderColor?: string;
  cardOnClickAction?: () => void;
  rightIcon?: boolean;
  onIconClick?: () => void;
  map?: React.ReactNode;
  className?: string;
};

export const Card = ({
  children,
  title,
  titleIcon,
  subtitle,
  actions,
  variant,
  customColor,
  borderColor,
  cardOnClickAction,
  rightIcon,
  onIconClick,
  map,
  className,
}: React.PropsWithChildren<CardProps>) => {
  const alertVariant =
    variant && ["info", "success", "warning", "danger"].includes(variant)
      ? variant
      : null;

  const alertIcon = () => {
    switch (alertVariant) {
      case "info":
        return informationOutline;
      case "success":
        return checkmarkOutline;
      default:
        return alertOutline;
    }
  };

  return (
    <IonCard
      className={clsx("ag-card", `ag-${variant || ""}`, className, {
        "custom-border": !!borderColor,
      })}
      style={{
        borderColor: borderColor,
        background: customColor,
      }}
      onClick={cardOnClickAction}
    >
      {(titleIcon || title || subtitle || alertVariant) && (
        <IonCardHeader className="ag-card-header">
          {alertVariant && (
            <div
              className={`ag-alert-icon-wrapper ag-alert-icon-wrapper-${alertVariant}`}
            >
              <IonIcon
                className={`ag-alert-icon ag-alert-icon-color-${alertVariant}`}
                icon={alertVariant !== null ? alertIcon() : alertOutline}
              />
            </div>
          )}
          <div className={`${alertVariant ? "ag-card-is-alert-variant" : ""}`}>
            {titleIcon && (
              <IonIcon
                className={`ag-card-icon ${
                  rightIcon ? "ag-card-icon-right" : ""
                }`}
                icon={titleIcon}
                onClick={onIconClick}
              />
            )}
            {title && (
              <IonCardTitle className="ag-card-title">
                <Text type="heading" size="small">
                  {title}
                </Text>
              </IonCardTitle>
            )}
            {subtitle && (
              <IonCardSubtitle className="ag-card-subtitle margin-top-4">
                {subtitle}
              </IonCardSubtitle>
            )}
          </div>
        </IonCardHeader>
      )}

      {map && <div className="ion-no-padding">{map}</div>}

      <IonCardContent
        className={`${map ? "contains-map" : ""} ${
          alertVariant ? "ag-card-is-alert-variant" : ""
        }`}
      >
        {children && (
          <div
            className={`${
              alertVariant ? "ag-children-alert-card-content" : ""
            }`}
          >
            {children}
          </div>
        )}

        {actions && (
          <div
            className={`${
              alertVariant ? "ag-children-card-alert-actions" : ""
            } margin-top-12`}
          >
            {actions && actions.map((action) => action)}
          </div>
        )}
      </IonCardContent>
    </IonCard>
  );
};

export default Card;
