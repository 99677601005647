import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  handleForgotPassword,
  handleLogin,
  handleResetPassword,
} from "../../api/cognito";
import { Input } from "../../components/ui";
import Button from "../../components/ui/Button";
import { Text } from "../../components/ui";
import VerificationCode from "../../components/ui/VerificationCode";
import { logAnalyticsEvent } from "../../api/firebase";
import PasswordValidation from "../../components/PasswordValidation";
import { isPasswordValid } from "../../utils/passwordValidation";

type LoginProps = {
  email: string;
};

export const Login = ({ email }: LoginProps) => {
  const [state, setState] = useState<"login" | "resetPassword">("login");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [code, setCode] = useState<string>("");
  const [nextRoute, setNextRoute] = useState<string | undefined>(undefined);

  const history = useHistory();

  useEffect(() => {
    const nr = new URLSearchParams(window.location.search).get("nextRoute");
    if (nr) {
      setNextRoute(nr);
    }
  }, []);

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setLoading(true);
      setError("");

      const handleError = (err: Error) => {
        const message = err.toString().split(":")[1];

        // Push the user to the confrirmation page if they are not confirmed
        if (message === " User is not confirmed.") {
          history.push("/login", { email, state: "email-confirm" });
        }

        setLoading(false);
        setError(message);
      };

      const handleSuccess = () => {
        setLoading(false);
        history.push(nextRoute ? nextRoute : "/emergencies");
      };

      if (state === "login") {
        handleLogin(email, password)
          .then(() => {
            logAnalyticsEvent("Login", { email });
            handleSuccess();
          })
          .catch(handleError);
        return;
      }

      handleResetPassword(email, code, password)
        .then(() => {
          handleLogin(email, password)
            .then(handleSuccess)
            .catch(() => {
              setError("");
            });
        })
        .catch(() => {
          setError("");
        });
    },
    [email, password, code, state, history],
  );

  return (
    <form action="post" onSubmit={handleSubmit}>
      {state === "resetPassword" && (
        <>
          <Text type="body" size="medium" as="p">
            Please enter the verification code sent to your email address and
            your new password.
          </Text>
          <VerificationCode
            noAlert
            service={(value) => {
              setCode(value);
              return Promise.resolve(true);
            }}
          />
        </>
      )}
      <Input
        label="Password"
        placeholder="Enter password"
        type="password"
        value={password}
        onChange={(value) => setPassword(value)}
        error={error}
        errorIcon={error !== ""}
        loading={loading}
        className="margin-top-16"
      />
      {state === "resetPassword" && <PasswordValidation password={password} />}
      <Button
        type="submit"
        disabled={!isPasswordValid(password) || loading}
        loading={loading}
        variant="primary"
        size="large"
        expand="block"
        className="margin-y-32"
      >
        Continue
      </Button>
      <Button
        type="button"
        onClick={() => {
          if (state === "resetPassword") {
            setState("login");
            return;
          }

          handleForgotPassword(email)
            .then((res) => {
              if (!res) {
                return;
              }
              setState("resetPassword");
            })
            .catch(() => {});
        }}
        variant="outline"
        expand="block"
      >
        {state === "login" ? "Forgotten password" : "Back to login"}
      </Button>
    </form>
  );
};
