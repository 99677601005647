import { Form, getForm } from "../api/form";
import { getUser } from "../api/user";
import { getProperty, getPropertyFullAddress } from "../api/user";
import { UserDetails } from "../project-types";
import { useEffect, useState } from "react";

type UserDataCallback = (userData: any) => string;

const formFieldToUserDataMap: Array<{
  formField: string;
  userDataField: keyof UserDetails | UserDataCallback;
}> = [
  { formField: "First_Name", userDataField: "firstName" },
  { formField: "Last_Name", userDataField: "lastName" },
  { formField: "Email", userDataField: "email" },
  { formField: "Phone", userDataField: "phone" },
  { formField: "Contact_number", userDataField: "phone" },
  {
    formField: "ApplicantName",
    userDataField: (userData) =>
      `${userData.firstName} ${userData.lastName}`.trim(),
  },
];

const useForm = (formId: string, propertyId: string | null | undefined) => {
  const [formData, setFormData] = useState<Form>();
  const [formResponse, setFormResponse] = useState<Record<string, any>>({});
  const [isFormComplete, setIsFormComplete] = useState<boolean>(false);

  useEffect(() => {
    const allRequiredFieldsFilled = formData?.elements
      .filter((element) => element.required)
      .every((element) => formResponse[element.name]);

    if (allRequiredFieldsFilled) {
      setIsFormComplete(true);
    }
  }, [formResponse, formData]);

  useEffect(() => {
    let isCancelled = false;

    const getFormAndPrefill = async () => {
      try {
        const [formInfo, userData] = await Promise.all([
          getForm(formId).live, //only get live data for forms
          getUser(),
        ]);

        if (formInfo === undefined) {
          throw new Error("Form data not loaded");
        }

        if (isCancelled) {
          return;
        }

        setFormData(formInfo);

        const getInitFormResponseForPage = (elements: Form["elements"]) => {
          return elements.reduce(
            (acc, element) => {
              if (!["heading", "html"].includes(element.type) && element.name) {
                acc[element.name] = null;
              }
              return acc;
            },
            {} as Record<string, any>,
          );
        };

        const initFormResponse: Record<string, any> = formInfo.isMultiPage
          ? getInitFormResponseForPage(
              formInfo.elements.map((i: any) => i.elements).flat(),
            )
          : getInitFormResponseForPage(formInfo.elements);

        const prefilledFields = userData
          ? formFieldToUserDataMap.reduce(
              (acc, { formField, userDataField }) => {
                if (Object.keys(initFormResponse).includes(formField)) {
                  if (typeof userDataField === "function") {
                    acc[formField] = userDataField(userData);
                  } else if (userDataField in userData) {
                    acc[formField] = userData[userDataField];
                  }
                }
                return acc;
              },
              {} as Record<string, any>,
            )
          : {};

        if (propertyId) {
          const property = await getProperty([propertyId]).live;

          const address = property
            ? getPropertyFullAddress(property)
            : "";

          if (address !== "") {
            prefilledFields["Apiary_Location_By_Address"] = address;
          }
        }

        if (isCancelled) {
          return;
        }

        setFormResponse({ ...initFormResponse, ...prefilledFields });
      } catch (err) {
        console.error(err);
      }
    };

    getFormAndPrefill();

    return () => {
      isCancelled = true;
    };
  }, [formId, propertyId]);

  return { formData, formResponse, setFormResponse, isFormComplete };
};

export default useForm;
