import { Marker, GeoJSON } from "react-leaflet";
import { useHistory } from "react-router-dom";
import { getPropertyFullAddress } from "../../api/user";
import { AgMap } from "../../components/AgMap";
import { Block, Card, Tag, Text } from "../../components/ui";
import { featureCollection, point } from "@turf/helpers";
import Buffer from "@turf/buffer";
import { icons } from "../../components/AgMap";
import { useEffect, useState } from "react";
import Button from "../../components/ui/Button";
import { latLng } from "leaflet";
import { crops, animals } from "../../api/assets";

import { PropertyDetailsWithBoundary } from "../../project-types";
import { pencilOutline } from "ionicons/icons";
import { propertyBoundsStyle } from "../../theme/geoStyles";

import "./View.scss";

type ViewPropertyProps = {
  property: PropertyDetailsWithBoundary;
};

export const ViewProperty = ({ property }: ViewPropertyProps) => {
  const history = useHistory();

  const [propertyCrops, setPropertyCrops] = useState<string[]>([]);
  const [propertyAnimals, setPropertyAnimals] = useState<string[]>([]);

  const [mapFocus, setMapFocus] = useState<GeoJSON.FeatureCollection>();

  useEffect(() => {
    let buffered: GeoJSON.FeatureCollection;
    if (property.boundary) {
      buffered = Buffer(property.boundary, 0.05, { units: "kilometers" });
    } else {
      buffered = featureCollection([
        Buffer(point([property.location.lng, property.location.lat]), 0.25, {
          units: "kilometers",
        }),
      ]);
    }

    setMapFocus(buffered);

    setPropertyCrops(property.holdings.filter((a) => crops.includes(a)));
    setPropertyAnimals(property.holdings.filter((a) => animals.includes(a)));
  }, [property]);

  return (
    <>
      <Card title="Location details" className="card-property-view">
        <ViewSectionBlock title="Map location">
          <div className="ag-map-wrapper">
            <AgMap geojsonFocus={mapFocus} nonInteractive>
              {property.boundary && (
                <GeoJSON
                  data={property.boundary}
                  style={propertyBoundsStyle(false, -1)}
                />
              )}
              <Marker
                position={latLng(property.location)}
                icon={icons.property.white}
              />
            </AgMap>
          </div>
        </ViewSectionBlock>
        <ViewSectionBlock title="Address">
          <Text size="large" type="body">
            {getPropertyFullAddress(property)}
          </Text>
        </ViewSectionBlock>
        <Button
          icon={pencilOutline}
          className="margin-top-32"
          variant="tertiary"
          expand="block"
          onClick={() => {
            history.push("/property/" + property.propertyId + "/edit/address");
          }}
        >
          Edit location or address
        </Button>
      </Card>
      <div className="ion-padding-top">
        <Card title="Property details" className="card-property-view">
          <ViewSectionBlock title="Name">
            <Text size="large" type="body">
              {property.name}
            </Text>
          </ViewSectionBlock>
          <ViewSectionBlock title="Animals and crops">
            {property.holdings.length === 0 && (
              <Text size="large" type="body">
                You haven't selected any animals or crops
              </Text>
            )}
            {property.holdings.length > 0 && (
              <div>
                {[...propertyAnimals, ...propertyCrops].map((asset) => {
                  return (
                    <Tag variant="secondary" key={asset}>
                      {asset}
                    </Tag>
                  );
                })}
              </div>
            )}
          </ViewSectionBlock>
          <Button
            icon={pencilOutline}
            className="margin-top-32"
            variant="tertiary"
            expand="block"
            onClick={() => {
              history.push(
                "/property/" + property.propertyId + "/edit/details"
              );
            }}
          >
            Edit details
          </Button>
        </Card>
      </div>
    </>
  );
};

const ViewSectionBlock = ({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) => {
  return (
    <Block variant="transparent" fullWidth>
      <div className={"margin-top-24 margin-bottom-8"}>
        <Text size="medium" type="body" as="p" className="ag-text-grey">
          {title}
        </Text>
      </div>
      {children}
    </Block>
  );
};
