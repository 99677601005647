import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import Snackbar, { SnackbarProps } from "./ui/Snackbar";

type SnackbarHandlerContextProps = {
  setSnackbarProps: (snackbarProps: SnackbarProps) => void;
  showDismissableSnackbar: (message: string, duration?: number) => void;
};

export const SnackbarHandlerContext =
  React.createContext<SnackbarHandlerContextProps>(
    {} as SnackbarHandlerContextProps
  );

export const SnackbarHandler = ({ children }: PropsWithChildren) => {
  const [snackbarProps, setSnackbarProps] =
    useState<SnackbarProps | undefined>();

  const showDismissableSnackbar = useCallback(
    (message: string, duration = 5000) => {
      setSnackbarProps({
        message,
        dismissButton: true,
        duration,
      });
    },
    []
  );

  const value = useMemo(() => {
    return {
      setSnackbarProps,
      showDismissableSnackbar,
    };
  }, [setSnackbarProps]);

  useEffect(() => {
    if (snackbarProps) {
      setSnackbarProps(undefined);
    }
  }, [snackbarProps]);

  return (
    <SnackbarHandlerContext.Provider value={value}>
      {children}
      {snackbarProps && <Snackbar {...snackbarProps} isOpen={true} />}
    </SnackbarHandlerContext.Provider>
  );
};
