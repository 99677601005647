import { useContext } from "react";
import { ZoneActionDetails } from "../../project-types";
import { Card, Text } from "../ui";
import { arrowForwardOutline } from "ionicons/icons";
import { useHistory } from "react-router";
import { BottomSheetContext } from "../ui/BottomSheet";

type TasksProps = {
  tasks: Array<ZoneActionDetails>;
};

const Tasks = ({ tasks }: TasksProps) => {
  const history = useHistory();
  const { setBottomSheetProps } = useContext(BottomSheetContext);

  return (
    <>
      {tasks.length > 0 ? (
        tasks.map((task, i) => (
          <div key={task["action-id"] || i}>
            {task["action-title"] && (
              <Card
                rightIcon
                titleIcon={arrowForwardOutline}
                title={task["action-title"]}
                variant="secondary"
                borderColor="#22272B"
                cardOnClickAction={() => {
                  setBottomSheetProps({
                    isOpen: false,
                  });
                  history.push(`/form/${task["action-id"]}`);
                }}
              />
            )}
          </div>
        ))
      ) : (
        <Text size="large" type="body">
          There are currently no tasks for this zone.
        </Text>
      )}
    </>
  );
};

export default Tasks;
