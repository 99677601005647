import {
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { arrowBack, close } from "ionicons/icons";
import { Text } from "./ui";
import "./Header.scss";

export type HeaderProps = {
  buttonType: "back" | "close";
  onButtonClick: () => void;
  title: string;
  hideButton?: boolean;
};

export function Header({
  title,
  buttonType,
  onButtonClick,
  hideButton,
}: HeaderProps) {
  // do not show header if there's no button or title to be shown
  if (hideButton && !title) return null;

  return (
    <IonHeader className="ag-header">
      <IonToolbar className="ion-no-padding">
        {!hideButton && (
          <>
            <IonButtons slot="start" onClick={onButtonClick}>
              <IonButton className="ag-title-icon">
                <IonIcon
                  slot="icon-only"
                  icon={buttonType === "back" ? arrowBack : close}
                />
              </IonButton>
            </IonButtons>
            <IonButtons slot="end">
              <IonButton disabled>
                <IonIcon slot="icon-only" icon="" />
              </IonButton>
            </IonButtons>
          </>
        )}
        <IonTitle className="ion-text-center ag-title-container">
          <Text type="heading" size="medium" className="ag-title-text">
            {title}
          </Text>
        </IonTitle>
      </IonToolbar>
    </IonHeader>
  );
}
