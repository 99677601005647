import { useContext, useMemo } from "react";
import { BottomSheetContext } from "../ui/BottomSheet";
import { BottomSheetWithCta } from "./BottomSheetWithCta";
import { paperPlaneOutline } from "ionicons/icons";

export const EmergencyFormSubmitSuccess = () => {
  const { setBottomSheetProps } = useContext(BottomSheetContext);

  const button = useMemo(() => {
    return {
      children: "Got it",
      onClick: () => {
        setBottomSheetProps({
          isOpen: false,
        });
      },
    };
  }, []);

  return (
    <BottomSheetWithCta
      icon={paperPlaneOutline}
      color="info"
      header={"Form Submitted"}
      body={
        "We have sent a copy to your email. Thank you for helping us manage this response."
      }
      buttons={[button]}
    />
  );
};
