import { IonChip, IonIcon, IonLabel } from "@ionic/react";
import { PropsWithChildren } from "react";
import "./Tag.scss";

export type TagProps = {
  startIcon?: string;
  endIcon?: string;
  variant?:
    | "primary"
    | "secondary"
    | "tertiary"
    | "light"
    | "success"
    | "warning"
    | "danger"
    | "info";
};

export const Tag = ({
  endIcon,
  startIcon,
  children,
  variant,
}: PropsWithChildren<TagProps>) => {
  return (
    <IonChip className={`ag-tag ag-tag-${variant ? variant : "primary"}`}>
      {startIcon && <IonIcon className="ag-tag-icon" icon={startIcon} />}
      <IonLabel>{children}</IonLabel>
      {endIcon && <IonIcon className="ag-tag-icon" icon={endIcon} />}
    </IonChip>
  );
};
