import React, { useEffect } from "react";
import { useState } from "react";
import SwiperCore from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { FlowFooterProps } from "./FlowFooter";

export type SharedProps = {
  updateFooter: (options: Partial<FlowFooterProps>) => void;
  nextClicked: boolean;
  backClicked: boolean;
  nextClickHandled: () => void;
  backClickHandled: (didSlideIndexChange: boolean) => void;
  onFlowFinished?: () => void;
  slideJump?: number;
  onSlideJumpHandled?: () => void;
};

export type SwiperFlowChildProps = SharedProps & {
  active: boolean;
  onStepFinished: () => void;
  visible: boolean;
};

export const SwiperChildNullProps = {
  active: false,
  onStepFinished: () => {},
  visible: false,
  updateFooter: () => {},
  nextClicked: false,
  backClicked: false,
  nextClickHandled: () => {},
  backClickHandled: () => {},
  flowFinished: () => {},
};

type SwiperFlowProps = SharedProps & {
  children: Array<React.ReactElement<SwiperFlowChildProps> | false>;
};

export const SwiperFlow = ({
  backClicked,
  children,
  nextClicked,
  slideJump,
  onSlideJumpHandled,
  updateFooter,
  nextClickHandled,
  backClickHandled,
  onFlowFinished: flowFinished,
}: SwiperFlowProps) => {
  const [swiper, setSwiper] = useState<SwiperCore>();
  const [visibleIndexes, setVisibleIndexes] = useState<Array<number>>([0]);
  const [activeIndex, setActiveIndex] = useState<number | undefined>(0);
  const [filteredChildren, setFilteredChildren] = useState<
    Array<React.ReactElement<SwiperFlowChildProps>>
  >([]);

  useEffect(() => {
    if (slideJump !== undefined) {
      swiper?.slideTo(slideJump);
      onSlideJumpHandled?.();
    }
  }, [slideJump]);

  useEffect(() => {
    if (backClicked) {
      const beforeSlidePrev = swiper?.activeIndex;
      swiper?.slidePrev();
      const afterSlidePrev = swiper?.activeIndex;
      const didSlideIndexChange = beforeSlidePrev !== afterSlidePrev;

      backClickHandled(didSlideIndexChange);
    }
  }, [backClicked, activeIndex]);

  useEffect(() => {
    const filteredChildren = React.Children.toArray(
      children.filter((child) => child !== false)
    ) as Array<React.ReactElement<SwiperFlowChildProps>>;
    setFilteredChildren(filteredChildren);
  }, [children]);

  const onSlideChange = (
    activeIndex: number,
    type: "changeStart" | "changeEnd"
  ) => {
    if (type === "changeStart") {
      setActiveIndex(undefined);
      setVisibleIndexes((prev) => [...prev, activeIndex]);
    } else if (type === "changeEnd") {
      setVisibleIndexes([activeIndex]);
      setActiveIndex(activeIndex);
    }
  };

  return (
    <Swiper
      allowTouchMove={false}
      onSwiper={setSwiper}
      onSlideChangeTransitionEnd={({ activeIndex }) =>
        onSlideChange(activeIndex, "changeEnd")
      }
      onSlideChangeTransitionStart={({ activeIndex }) =>
        onSlideChange(activeIndex, "changeStart")
      }
      autoHeight={true}
    >
      {filteredChildren.map(
        (child: React.ReactElement<SwiperFlowChildProps>, i) => (
          <SwiperSlide key={child.key}>
            {React.cloneElement<SwiperFlowChildProps>(child, {
              onStepFinished: () => {
                swiper?.slideNext();
              },
              nextClickHandled,
              onFlowFinished: () => {
                flowFinished?.();
              },
              visible: visibleIndexes.includes(i),
              active: activeIndex === i,
              updateFooter,
              nextClicked,
            })}
          </SwiperSlide>
        )
      )}
    </Swiper>
  );
};
