import { Text } from "../../components/ui";
import { useEffect, useState } from "react";
import { SwiperFlowChildProps } from "../../components/SwiperFlow";
import { SelectAssets } from "../../components/SelectAssets";

type AddAssetsProps = SwiperFlowChildProps & {
  updateHoldings: (holdings: string[]) => void;
};

export const AddAssets = ({
  active,
  nextClicked,
  visible,
  updateFooter,
  updateHoldings,
  onStepFinished,
  nextClickHandled,
}: AddAssetsProps) => {
  const [selectedCrops, setSelectedCrops] = useState<string[]>([]);
  const [selectedAnimals, setSelectedAnimals] = useState<string[]>([]);

  useEffect(() => {
    if (active && visible) {
      updateFooter({
        disableNext: false,
      });
      if (nextClicked) {
        updateHoldings([...selectedAnimals, ...selectedCrops]);

        onStepFinished();
        nextClickHandled();
      }
    }
  }, [active, visible, nextClicked, selectedAnimals, selectedCrops]);

  return (
    <>
      <Text size="large" type="body">
        Step 4 of 5
      </Text>

      <Text type="display" size="small" as="h2" bold className="margin-y-16">
        Add animals and crops (optional)
      </Text>
      <Text type="body" size="large">
        Select any animals and crops that you manage on this property for which
        you would like to receive emergency alerts.
      </Text>
      <SelectAssets
        selectedCrops={selectedCrops}
        setSelectedCrops={setSelectedCrops}
        selectedAnimals={selectedAnimals}
        setSelectedAnimals={setSelectedAnimals}
      />
    </>
  );
};
