import React, { PropsWithChildren } from "react";
import { IonCheckbox, IonLabel } from "@ionic/react";
import "./Checkbox.scss";
import { Block } from "./Block";

export type CheckboxProps = {
  variant?: "primary" | "secondary";
  checked: boolean;
  onChange?: (checked: boolean) => void;
  slot?: "start" | "end";
  fullWidth?: boolean;
  wrapperClassName?: string;
  interactiveLabel?: boolean;
};

const Checkbox = ({
  variant,
  fullWidth,
  children,
  onChange,
  wrapperClassName,
  interactiveLabel = false,
  ...rest
}: PropsWithChildren<CheckboxProps>) => {
  const checkboxAndLabel = (
    <>
      <div className="ag-checkbox-border">
        <IonCheckbox
          className={`ag-checkbox-checkbox ag-checkbox-${variant}`}
          onIonChange={(e) => onChange && onChange(e.detail.checked)}
          {...rest}
        />
      </div>
      {children && (
        <IonLabel className={`ag-checkbox-label ion-text-wrap`}>
          {children}
        </IonLabel>
      )}
    </>
  );

  if (interactiveLabel) {
    return (
      <div className={`ag-checkbox ${fullWidth ? "ag-textarea-full" : ""}`}>
        {checkboxAndLabel}
      </div>
    );
  }

  return (
    <Block
      inline
      variant="transparent"
      className={`ag-checkbox ${fullWidth ? "ag-textarea-full" : ""}`}
    >
      {checkboxAndLabel}
    </Block>
  );
};

export default Checkbox;
