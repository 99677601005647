import { IonRouterOutlet } from "@ionic/react";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  Route,
  RouteComponentProps,
  matchPath,
  useHistory,
  useLocation,
} from "react-router-dom";
import { deleteProperty, getProperty, updateProperty } from "../../api/user";
import { AgResponsePage } from "../../components/AgResponsePage";
import { NavFooter } from "../../components/NavFooter";
import { ViewProperty } from "./View";
import { PropertyDetailsWithBoundary } from "../../project-types";
import Button from "../../components/ui/Button";
import { BottomSheetContext } from "../../components/ui/BottomSheet";
import { EditPropertyDetailsPage } from "./Edit/Details";
import { animals, crops } from "../../api/assets";
import { logAnalyticsEvent } from "../../api/firebase";
import { EditPropertyAddressPage } from "./Edit/Address";
import { ConfirmDeleteProperty } from "../../components/bottomSheet/ConfirmDeleteProperty";
import { SnackbarHandlerContext } from "../../components/SnackbarHandler";
import { GenericFail } from "../../components/bottomSheet/GenericFail";
import FullscreenLoader from "../../components/ui/FullscreenLoader";
import { useCacheLiveData } from "../../hooks/useCacheLiveData";
import { warning, warningOutline } from "ionicons/icons";
import EmptyState from "../../components/ui/EmptyState";

type ExpectedSubroutes = {
  id: string;
};

export type UpdatePropertyType = "location" | "details";

export const PropertyPage: React.FC<RouteComponentProps<ExpectedSubroutes>> = ({
  match,
}) => {
  const history = useHistory();
  const location = useLocation();

  const { showDismissableSnackbar } = useContext(SnackbarHandlerContext);
  const [property, setProperty] = useState<PropertyDetailsWithBoundary>();
  const [selectedCrops, setSelectedCrops] = useState<string[]>([]);
  const [selectedAnimals, setSelectedAnimals] = useState<string[]>([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [saveType, setSaveType] = useState<UpdatePropertyType | undefined>();
  const [updatedPropertyDetails, setUpdatedPropertyDetails] =
    useState<PropertyDetailsWithBoundary>();
  const { setBottomSheetProps } = useContext(BottomSheetContext);

  const {
    data: propertyData,
    state: { hasLiveErrored, haveBothLoaded },
  } = useCacheLiveData(getProperty, [match.params.id]);

  useEffect(() => {
    if (hasLiveErrored) {
      showDismissableSnackbar("Error retrieving property");
      return;
    }

    if (propertyData === undefined) return;

    setProperty(propertyData);
    setUpdatedPropertyDetails(propertyData);
    setSelectedCrops(
      (propertyData.holdings || []).filter((a) => crops.includes(a))
    );
    setSelectedAnimals(
      (propertyData.holdings || []).filter((a) => animals.includes(a))
    );
  }, [propertyData]);

  useEffect(() => {
    if (!isSaving || !updatedPropertyDetails) return;

    updateProperty({
      ...updatedPropertyDetails,
      holdings: [...selectedCrops, ...selectedAnimals],
    }).then(async (res) => {
      if (res === undefined) {
        history.push("/login");
      } else if (res) {
        const property = await getProperty([match.params.id]).live;
        if (property) {
          setProperty(property);

          logAnalyticsEvent("Assets changed", {
            address: updatedPropertyDetails!.address,
            animals: selectedAnimals,
            crops: selectedCrops,
          });

          switch (saveType) {
            case "location":
              showDismissableSnackbar("Property location saved");
              break;
            case "details":
              showDismissableSnackbar("Property details saved");
              break;
          }

          history.push(`/property/${property.propertyId}`);
        }
      } else {
        setBottomSheetProps({
          isOpen: true,
          isCloseCtaVisible: false,
          children: <GenericFail />,
        });
      }
      setIsSaving(false);
      setSaveType(undefined);
    });
  }, [
    isSaving,
    saveType,
    updatedPropertyDetails,
    selectedCrops,
    selectedAnimals,
  ]);

  const onSaveChanges = useCallback(
    (saveType?: UpdatePropertyType) => {
      if (!updatedPropertyDetails) {
        return;
      }

      setIsSaving(true);
      setSaveType(saveType);
    },
    [updatedPropertyDetails]
  );

  const isAddressEditPath = useMemo(() => {
    return (
      matchPath(location.pathname, {
        path: "/property/:id/edit/address",
        exact: false,
      }) !== null
    );
  }, [location.pathname]);
  const isDetailsEditPath = useMemo(() => {
    return (
      matchPath(location.pathname, {
        path: "/property/:id/edit/details",
        exact: false,
      }) !== null
    );
  }, [location.pathname]);

  const title = useMemo(() => {
    if (isAddressEditPath) {
      return "Edit location or address";
    } else if (isDetailsEditPath) {
      return "Edit details";
    }

    return property?.name || "Property";
  }, [property, isAddressEditPath, isDetailsEditPath]);

  if (!property) {
    return haveBothLoaded ? (
      <EmptyState
        {...(hasLiveErrored
          ? {
              icon: warning,
              header: "Offline Mode",
              body: "We are unable to retrieve this property. Please check your internet connection.",
            }
          : {
              icon: warningOutline,
              body: "We are able to retrieve this property. Please restart the app.",
            })}
      />
    ) : (
      <LoadingPage title={title} />
    );
  } else {
    return (
      <IonRouterOutlet className="ion-padding" style={{ overflowY: "scroll" }}>
        <Route exact path="/property/:id">
          <ViewPage
            title={title}
            property={property}
            propertyId={match.params.id}
          />
        </Route>
        <Route exact path="/property/:id/edit/address">
          <EditPropertyAddressPage
            onSave={onSaveChanges}
            property={property}
            isSaving={isSaving}
            setUpdatedPropertyDetails={(updated) => {
              //create a new version of property with the updated details
              setUpdatedPropertyDetails((prevDetails) => {
                if (!prevDetails) {
                  return undefined;
                }
                return {
                  ...prevDetails,
                  ...updated,
                  propertyId: prevDetails.propertyId,
                };
              });
            }}
          />
        </Route>
        <Route exact path="/property/:id/edit/details">
          <EditPropertyDetailsPage
            onSave={onSaveChanges}
            title={title}
            property={property}
            updatedPropertyDetails={
              updatedPropertyDetails as PropertyDetailsWithBoundary
            }
            setUpdatedPropertyDetails={(updated) => {
              if (!updatedPropertyDetails) {
                return;
              }
              setUpdatedPropertyDetails((prevDetails) => {
                if (!prevDetails) {
                  return undefined;
                }
                return {
                  ...prevDetails,
                  ...updated,
                  propertyId: prevDetails.propertyId,
                };
              });
            }}
            selectedAnimals={selectedAnimals}
            setSelectedAnimals={setSelectedAnimals}
            selectedCrops={selectedCrops}
            setSelectedCrops={setSelectedCrops}
            isSaving={isSaving}
          />
        </Route>
      </IonRouterOutlet>
    );
  }
};

const LoadingPage = ({ title }: { title: string }) => {
  const history = useHistory();
  return (
    <AgResponsePage
      title={title}
      buttonType="back"
      onButtonClick={() => {
        history.push("/properties");
      }}
      footer={<NavFooter />}
    >
      <FullscreenLoader />
    </AgResponsePage>
  );
};

const ViewPage = ({
  title,
  property,
  propertyId,
}: {
  title: string;
  property: PropertyDetailsWithBoundary;
  propertyId: string;
}) => {
  const history = useHistory();
  const { setBottomSheetProps } = useContext(BottomSheetContext);
  const { showDismissableSnackbar } = useContext(SnackbarHandlerContext);

  const handleDeleteProperty = useCallback(() => {
    setBottomSheetProps({
      isOpen: true,
      isCloseCtaVisible: false,
      children: (
        <ConfirmDeleteProperty
          onConfirm={async () => {
            try {
              const res = await deleteProperty(propertyId);
              setBottomSheetProps({ isOpen: false });

              if (res) {
                showDismissableSnackbar("Property deleted");
                history.push("/properties");
              } else {
                throw new Error("Unable delete user's property");
              }
            } catch (e) {
              console.error(e);

              setBottomSheetProps({
                isOpen: true,
                isCloseCtaVisible: false,
                children: <GenericFail />,
              });
            }
          }}
        />
      ),
    });
  }, [showDismissableSnackbar]);

  return (
    <AgResponsePage
      title={title}
      buttonType="back"
      onButtonClick={() => {
        history.push("/properties");
      }}
      footer={<NavFooter />}
    >
      <ViewProperty property={property} />
      <div className="ion-text-center cancel-btn-wrapper margin-top-32">
        <Button variant="outline" onClick={handleDeleteProperty}>
          Delete this property
        </Button>
      </div>
    </AgResponsePage>
  );
};
