import React from "react";
import { IonSpinner } from "@ionic/react";
import "./FullscreenLoader.scss";
import Card from "./Card";

type FullscreenLoaderProps = {
  loadingMessage?: string;
};

export const FullscreenLoader: React.FC<FullscreenLoaderProps> = ({
  loadingMessage,
}: FullscreenLoaderProps) => {
  return (
    <>
      <div
        className="fullscreen-loader overlay"
        role="status"
        aria-live="polite"
        aria-label="Loading content overlay"
      />
      <div
        className="fullscreen-loader"
        role="status"
        aria-live="polite"
        aria-label="Loading content"
      >
        <div className={"loading-content-wrapper"}>
          <IonSpinner className="ion-padding" />
          {loadingMessage && (
            <div className="ion-margin-vertical">
              <Card title={loadingMessage} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default FullscreenLoader;
