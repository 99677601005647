import { Text } from "../../components/ui";
import { Fragment, useContext, useEffect, useMemo, useState } from "react";
import { SwiperFlowChildProps } from "../../components/SwiperFlow";
import { useHistory } from "react-router-dom";
import { PropertyDetailsWithBoundaryIds } from "../../project-types";
import { featureCollection } from "@turf/helpers";
import { GeoJSON, Marker } from "react-leaflet";
import { AgMap, icons } from "../../components/AgMap";
import { AddressResult } from "./ByAddress";
import buffer from "@turf/buffer";
import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { addProperty, getPropertyFullAddress } from "../../api/user";
import Button from "../../components/ui/Button";
import { animals, crops } from "../../api/assets";
import { checkmark } from "ionicons/icons";
import { logAnalyticsEvent } from "../../api/firebase";
import { BottomSheetContext } from "../../components/ui/BottomSheet";
import { PropertyCreationFailed } from "../../components/bottomSheet/PropertyCreationFailed";
import { PropertyCreated } from "../../components/bottomSheet/PropertyCreated";

import "./CheckConfirm.scss";

type CheckConfirmProps = SwiperFlowChildProps & {
  property?: PropertyDetailsWithBoundaryIds;
  selectedAddress?: AddressResult;
  onEdit: (targetSlide: number, returnSlide: number) => void;
};

export const CheckConfirm = ({
  active,
  nextClicked,
  nextClickHandled,
  visible,
  updateFooter,
  property,
  selectedAddress,
  onEdit,
}: CheckConfirmProps) => {
  const history = useHistory();

  const { setBottomSheetProps } = useContext(BottomSheetContext);

  const [mapFocus, setMapFocus] = useState<GeoJSON.FeatureCollection>();

  const filteredAnimals = useMemo(() => {
    if (!property) return [];
    return property.holdings.filter((a) => animals.includes(a));
  }, [property]);

  const filteredCrops = useMemo(() => {
    if (!property) return [];
    return property.holdings.filter((a) => crops.includes(a));
  }, [property]);

  const editableData = useMemo(() => {
    if (!property) return [];
    return [
      {
        title: "Address",
        data: getPropertyFullAddress(property),
        edit: () => {
          onEdit(2, 6);
        },
      },
      {
        title: "Property name",
        data: property.name,
        edit: () => {
          onEdit(3, 5);
        },
      },
      {
        title: "Animals",
        data:
          filteredAnimals.length === 0
            ? "You haven't selected any animals"
            : filteredAnimals.join(", "),
        edit: () => {
          onEdit(4, 5);
        },
      },
      {
        title: "Crops",
        data:
          filteredCrops.length === 0
            ? "You haven't selected any crops"
            : filteredCrops.join(", "),
        edit: () => {
          onEdit(4, 5);
        },
      },
    ];
  }, [property]);

  useEffect(() => {
    if (active && visible) {
      updateFooter({
        disableBack: false,
        disableNext: false,
        nextText: "Add to profile",
        overrideNextIcon: checkmark,
      });
    }
  }, [active, visible]);

  useEffect(() => {
    if (active && visible && nextClicked && property) {
      updateFooter({
        disableBack: true,
        disableNext: true,
        showLoading: true,
      });

      addProperty(property)
        .then((res) => {
          if (!res) return Promise.reject();

          logAnalyticsEvent("New property", {
            holdingName: property.name,
            holdingAddress: getPropertyFullAddress(property),
          });

          setBottomSheetProps({
            isOpen: true,
            isCloseCtaVisible: false,
            children: <PropertyCreated />,
          });

          history.push("/properties");
        })
        .catch(() => {
          updateFooter({
            disableBack: false,
            disableNext: false,
            showLoading: false,
            nextText: "Add to profile",
            overrideNextIcon: checkmark,
          });
          setBottomSheetProps({
            isOpen: true,
            isCloseCtaVisible: false,
            children: <PropertyCreationFailed />,
          });

          return false;
        });
    }
    nextClickHandled && nextClickHandled();
  }, [nextClicked]);

  useEffect(() => {
    if (active && selectedAddress) {
      if (selectedAddress.propertyBounds) {
        setMapFocus(selectedAddress.propertyBounds);
      } else {
        const buffered = featureCollection([
          buffer(selectedAddress.geo.geometry, 0.25, {
            units: "kilometers",
          }),
        ]);
        setMapFocus(buffered);
      }
    }
  }, [active, selectedAddress]);

  return property ? (
    <>
      <Text size="large" type="body">
        Step 5 of 5
      </Text>

      <Text type="display" size="small" as="h2" bold className="margin-y-16">
        Check and confirm
      </Text>

      <Text type="body" size="large">
        Check the property details below are correct before you add it to your
        profile.
      </Text>

      <div className="margin-top-16 margin-bottom-32">
        <Text type="body" size="small" color="secondary">
          Map location
        </Text>
        <div className="ag-map-wrapper margin-top-8">
          <AgMap
            style={{
              height: "200px",
              width: "100%",
              flexGrow: 1,
            }}
            geojsonFocus={mapFocus}
            nonInteractive
          >
            <div className="ag-address-search leaflet-top ">
              <div className="leaflet-control ion-padding-horizontal">
                <Button
                  variant="light"
                  size="small"
                  className="ion-float-right"
                  onClick={() => {
                    onEdit(1, 6);
                  }}
                >
                  Edit
                </Button>
              </div>
            </div>
            {selectedAddress && selectedAddress.propertyBounds && (
              <GeoJSON data={selectedAddress.propertyBounds} />
            )}

            {property.location.lat !== 0 && (
              <Marker
                position={property.location}
                icon={icons.property.white}
              />
            )}
          </AgMap>
        </div>
      </div>

      {editableData &&
        editableData.map(({ data, edit, title }) => (
          <IonGrid
            className="editable-data-wrapper margin-bottom-32"
            key={title}
          >
            <IonRow>
              <IonCol className="editable-data-copy-wrapper padding-right-24">
                <Text
                  type="body"
                  size="small"
                  color="secondary"
                  className="margin-bottom-4"
                >
                  {title}
                </Text>
                <Text type="body" size="large">
                  {data}
                </Text>
              </IonCol>
              <IonCol className="editable-data-cta-wrapper">
                <Button
                  variant="tertiary"
                  size="small"
                  onClick={() => {
                    edit();
                  }}
                >
                  Edit
                </Button>
              </IonCol>
            </IonRow>
          </IonGrid>
        ))}
    </>
  ) : null;
};
