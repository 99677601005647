import { RouteComponentProps, useHistory } from "react-router";
import { Text } from "../../components/ui";
import NavCard from "../../components/ui/NavCard";
import { useContext, useEffect, useState, useRef } from "react";
import {
  EmergencyDetails,
  PropertyDetailsWithBoundary,
  ZoneDetails,
  ZoneRules,
} from "../../project-types";
import { IonContent } from "@ionic/react";
import ZoneSelector from "../../components/ui/ZoneSelector";
import ShowHideContent from "../../components/ui/ShowHideContent";
import { BottomSheetContext } from "../../components/ui/BottomSheet";
import Tasks from "../../components/emergency/Tasks";
import Restrictions from "../../components/emergency/Restrictions";
import Properties from "../../components/emergency/Properties";
import SmallMap from "../../components/emergency/SmallMap";
import { isPropertyAffected, isPropertyInZone } from "../../utils/properties";

type OverviewProps = {
  scrollToZone: number | null;
  emergencyData: EmergencyDetails | undefined;
  properties: PropertyDetailsWithBoundary[] | undefined;
};

const Overview: React.FC<OverviewProps & RouteComponentProps<{ id: string }>> =
  ({ match, scrollToZone, emergencyData, properties }) => {
    const { id } = match.params;
    const history = useHistory();
    const { setBottomSheetProps } = useContext(BottomSheetContext);
    const [selectedZone, setSelectedZone] =
      useState<ZoneDetails & { zoneRules?: ZoneRules }>();
    const [augmentedProperties, setAugmentedProperties] =
      useState<Array<PropertyDetailsWithBoundary & { zoneId: number }>>();
    const [affectedProperties, setAffectedProperties] =
      useState<Array<PropertyDetailsWithBoundary>>();
    const [qtyAffectedProperties, setQtyAffectedProperties] =
      useState<Record<number, number>>();
    const [isPropertyAccordionShown, setIsPropertyAccordionShown] =
      useState<boolean>(true);

    const contentRef = useRef<HTMLIonContentElement>(null);

    useEffect(() => {
      if (emergencyData && scrollToZone !== null) {
        setTimeout(() => {
          if (contentRef.current) {
            contentRef.current.scrollToBottom(400);
          }
        }, 1000);
        handleZoneSelected(scrollToZone);
      }

      if (
        typeof scrollToZone === "number" &&
        contentRef.current &&
        contentRef.current.scrollToPoint
      ) {
        setTimeout(() => {
          contentRef?.current?.scrollToPoint(0, 800, 600);
        }, 1000);
      }
    }, [emergencyData, scrollToZone]);

    const navCardButtons = [
      {
        name: "To do",
        icon: "/assets/checkmark.svg",
        action: () => {
          if (
            emergencyData?.producerInstructions &&
            emergencyData?.emergencyDescription
          ) {
            setBottomSheetProps({
              isOpen: true,
              title: "What you should be doing",
              children: (
                <Text
                  className="list-type-exclamation"
                  type="body"
                  size="large"
                  as="p"
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: emergencyData?.producerInstructions,
                    }}
                  />
                </Text>
              ),
            });
          }
        },
      },
      {
        name: "Latest",
        icon: "/assets/latest.svg",
        action: () => history.push(`/emergency/${id}/latest`),
      },
      {
        name: "Support",
        icon: "/assets/phone.svg",
        action: () => history.push(`/emergency/${id}/support`),
      },
      {
        name: "About",
        icon: "/assets/information.svg",
        action: () => history.push(`/emergency/${id}/about`),
      },
    ];

    const handleZoneSelected = (zoneId: number) => {
      setSelectedZone((previous) => {
        if (previous?.zoneId === zoneId) {
          return previous;
        }

        const zone = emergencyData?.zones.find((z) => z.zoneId === zoneId);
        if (!zone) {
          return previous;
        }

        const zoneRules = emergencyData?.["zones-rules"]?.find(
          (rule) => rule.zoneId === zoneId
        );

        return {
          ...zone,
          zoneRules,
        };
      });
      if (contentRef.current && contentRef.current.scrollToPoint) {
        contentRef.current.scrollToPoint(0, 800, 600);
      }
    };

    useEffect(() => {
      if (!emergencyData) return;

      if (properties === undefined) {
        setAffectedProperties([]);
        setIsPropertyAccordionShown(false);
        return;
      }

      const augmentedProperties = properties.reduce(
        (accumulatedProperties, currentProperty) => {
          const hasAsset = isPropertyAffected(currentProperty, emergencyData);
          const features = emergencyData?.mapData?.features;

          if (!features || !hasAsset) {
            return accumulatedProperties;
          }

          const zoneId = features.reduce((currentIndex, zone) => {
            const isAffected = isPropertyInZone(currentProperty, zone);
            return isAffected
              ? Math.min(zone.properties.zoneType, currentIndex)
              : currentIndex;
          }, 999);

          return zoneId < 999
            ? [
                ...accumulatedProperties,
                {
                  ...currentProperty,
                  zoneId,
                },
              ]
            : accumulatedProperties;
        },
        [] as Array<PropertyDetailsWithBoundary & { zoneId: number }>
      );

      setAugmentedProperties(augmentedProperties);

      const propertiesCount = augmentedProperties.reduce((acc, { zoneId }) => {
        const zone = acc[zoneId];
        if (zone === undefined) {
          return { ...acc, [zoneId]: 1 };
        }
        acc[zoneId] = zone + 1;
        return acc;
      }, {} as Record<number, number>);

      setQtyAffectedProperties(propertiesCount);
    }, [id, emergencyData, properties]);

    useEffect(() => {
      if (augmentedProperties !== undefined && selectedZone !== null) {
        const filteredProperties = augmentedProperties.filter(
          (ap) => ap.zoneId === selectedZone?.zoneId
        );
        setAffectedProperties(filteredProperties);
      }
    }, [selectedZone]);
    
    return (
      <IonContent ref={contentRef}>
        <div className="margin-x-24 margin-y-32">
          {emergencyData && (
            <>
              <NavCard
                image={emergencyData.emergencyImage}
                imageAltText={`Hero image for: ${emergencyData.emergencyTitle}`}
                buttons={navCardButtons}
              />
              <Text
                type="heading"
                size="large"
                as="h2"
                className="margin-top-32 margin-bottom-0"
              >
                Emergency overview
              </Text>
              {emergencyData.emergencyDescription ? (
                <Text type="body" size="large" as="p">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: emergencyData.emergencyDescription,
                    }}
                  />
                </Text>
              ) : (
                <Text type="body" size="large" as="p">
                  No overview description available.
                </Text>
              )}
              <Text
                type="heading"
                size="large"
                as="h2"
                className="margin-top-32 margin-bottom-16"
              >
                Emergency zones
              </Text>

              {emergencyData.mapData && (
                <SmallMap mapData={emergencyData.mapData} id={id} />
              )}

              <div className="margin-y-32" />
              {emergencyData.zones && (
                <ZoneSelector
                  zones={emergencyData.zones.map((zone) => {
                    let title = zone["zone-title"];
                    const zoneCount = qtyAffectedProperties?.[zone.zoneId];

                    return {
                      zoneId: zone.zoneId,
                      ...(zoneCount !== undefined && zoneCount !== 0
                        ? {
                            zoneSubtitle: `${zoneCount} ${
                              zoneCount === 1 ? "Property" : "Properties"
                            } affected`,
                          }
                        : {}),
                      zoneTitle: title,
                    };
                  })}
                  onZoneButtonSelected={handleZoneSelected}
                  selectedButton={selectedZone?.zoneId}
                  showToggleCheveron={false}
                />
              )}

              {emergencyData.zones && selectedZone !== undefined && (
                <div className="margin-top-32">
                  <Text type="heading" size="large" as="h2">
                    {selectedZone["zone-title"]}
                  </Text>
                  <Text type="body" size="large" as="p">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: selectedZone["zone-description"],
                      }}
                    />
                  </Text>

                  {isPropertyAccordionShown && (
                    <ShowHideContent
                      title={`Properties ${
                        affectedProperties && affectedProperties.length > 0
                          ? `(${affectedProperties.length})`
                          : ""
                      }`}
                    >
                      {affectedProperties && (
                        <Properties
                          affectedProperties={affectedProperties}
                          tasks={emergencyData.emergencyActions}
                          selectedZone={selectedZone.zoneId}
                          assets={emergencyData.assets}
                        />
                      )}
                    </ShowHideContent>
                  )}
                  <ShowHideContent title="Tasks">
                    {emergencyData.emergencyActions && (
                      <div className="margin-top-24">
                        <Tasks
                          tasks={emergencyData.emergencyActions.filter(
                            (action) =>
                              action["action-assigned-zones"].includes(
                                `Zone ${selectedZone.zoneId}`
                              )
                          )}
                        />
                      </div>
                    )}
                  </ShowHideContent>

                  <ShowHideContent title="Restrictions">
                    {selectedZone && selectedZone.zoneRules && (
                      <Restrictions
                        zoneRules={selectedZone.zoneRules["rules-topics"]}
                      />
                    )}
                  </ShowHideContent>
                </div>
              )}
            </>
          )}
        </div>
      </IonContent>
    );
  };

export default Overview;
