import { Block, Text } from "../../components/ui";
import { IonIcon, IonGrid, IonRow, IonCol } from "@ionic/react";
import Button from "./Button";
import { useEffect, useState } from "react";

type EmptyStateProps = {
  icon: string;
  title?: string;
  header?: string;
  body: string;
  buttons?: Array<{
    text: string;
    action: () => void;
    icon?: string;
  }>;
};

const EmptyState = ({
  icon,
  title,
  header,
  body,
  buttons,
}: EmptyStateProps) => {
  const [isDownloadAppAlertVisible, setIsDownloadAppAlertVisible] =
    useState<boolean>(false);
  useEffect(() => {
    // super dodgy method to push down the empty state when the app alert is showing
    const target = document.querySelector<Element>(".ag-download-app-alert");

    if (target) {
      setIsDownloadAppAlertVisible(true);
      const observer = new MutationObserver(function (mutations) {
        // check for removed target
        mutations.forEach(function (mutation) {
          const nodes = Array.from(mutation.removedNodes);
          const directMatch = nodes.indexOf(target) > -1;
          const parentMatch = nodes.some((parent) => parent.contains(target));
          if (directMatch) {
            setIsDownloadAppAlertVisible(false);
          } else if (parentMatch) {
            setIsDownloadAppAlertVisible(false);
          }
        });
      });

      const config = {
        subtree: true,
        childList: true,
      };
      observer.observe(document.body, config);
    } else {
      setIsDownloadAppAlertVisible(false);
    }
  }, []);

  return (
    <Block className="ion-text-center">
      {title && (
        <IonGrid>
          <IonRow>
            <IonCol>
              <Text type="display" size="large">
                {title}
              </Text>
            </IonCol>
          </IonRow>
        </IonGrid>
      )}
      <IonGrid
        style={{
          position: "fixed",
          top: isDownloadAppAlertVisible ? "55%" : "48%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "300px",
        }}
      >
        <IonRow>
          <IonCol>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100px",
                height: "100px",
                borderRadius: "50%",
                backgroundColor: "#FFE2C8",
                margin: "auto",
              }}
            >
              <IonIcon
                icon={icon}
                color="primary"
                style={{ fontSize: "50px" }}
              />
            </div>
          </IonCol>
        </IonRow>
        {header && (
          <IonRow>
            <IonCol className="ion-padding-top ion-padding-bottom">
              <Text size="medium" type="display">
                {header}
              </Text>
            </IonCol>
          </IonRow>
        )}
        <IonRow>
          <IonCol>
            <Text size="large" type="body">
              {body}
            </Text>
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonGrid
        style={{
          position: "fixed",
          bottom: 90, // changed from 70 to 90 to accomodate for the "Sign in or create account button"
          left: 0,
          right: 0,
          zIndex: 1000,
        }}
      >
        {buttons &&
          buttons.map((button, index) => (
            <IonRow key={index}>
              <IonCol>
                <Button
                  icon={button.icon}
                  expand="block"
                  onClick={button.action}
                >
                  {button.text}
                </Button>
              </IonCol>
            </IonRow>
          ))}
      </IonGrid>
    </Block>
  );
};

export default EmptyState;
