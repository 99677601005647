import { useContext, useEffect } from "react";
import { RouteComponentProps } from "react-router";
import { Card } from "../../components/ui";
import { getDiseaseInfo } from "../../api/emergencies";
import Accordion from "../../components/ui/Accordion";
import Button from "../../components/ui/Button";
import { openOutline } from "ionicons/icons";
import { SnackbarHandlerContext } from "../../components/SnackbarHandler";
import FullscreenLoader from "../../components/ui/FullscreenLoader";
import { useCacheLiveData } from "../../hooks/useCacheLiveData";
import { warning, warningOutline } from "ionicons/icons";
import EmptyState from "../../components/ui/EmptyState";

type AboutProps = {
  id: string;
};

const About: React.FC<RouteComponentProps<AboutProps>> = ({ match }) => {
  const { id } = match.params;
  const { showDismissableSnackbar } = useContext(SnackbarHandlerContext);

  const {
    data: infoData,
    state: { hasLiveErrored, haveBothLoaded },
  } = useCacheLiveData(getDiseaseInfo, [id]);

  useEffect(() => {
    if (hasLiveErrored) {
      showDismissableSnackbar("Error retrieving emergency about page");
      return;
    }
  }, [infoData]);

  return (
    <>
      <div className="margin-y-32 margin-x-24">
        {infoData === undefined && haveBothLoaded && (
          <EmptyState
            {...(hasLiveErrored
              ? {
                  icon: warning,
                  header: "Offline Mode",
                  body: "We are unable to retrieve emergency about page. Please check your internet connection.",
                }
              : {
                  icon: warningOutline,
                  body: "No emergency information available.",
                })}
          />
        )}
        {infoData === undefined && !haveBothLoaded && <FullscreenLoader />}
        {infoData && (
          <>
            {infoData.facts && (
              <Accordion headerSlug="header" split items={infoData.facts} />
            )}
            <div className="margin-top-32">
              <Card
                title="Want to find out more?"
                variant="info"
                actions={[
                  <Button
                    key={infoData.link}
                    onClick={() => window.open(infoData.link, "_blank")}
                    icon={openOutline}
                    iconPosition="end"
                    variant="info"
                  >
                    More info
                  </Button>,
                ]}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default About;
