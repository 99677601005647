import { useEffect, useState } from "react";
import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { Text } from "./ui";
import {
  passwordRequirements,
  passwordValidation,
} from "../utils/passwordValidation";

const PasswordValidation = ({ password = "" }) => {
  const [passwordValid, setPasswordValid] = useState(
    passwordValidation(password),
  );

  useEffect(() => {
    setPasswordValid(passwordValidation(password));
  }, [password]);

  return (
    <IonGrid>
      {Object.keys(passwordRequirements).map((key) => {
        const index = key as keyof typeof passwordRequirements;
        const label = passwordRequirements[index].label;
        const ValidationIcon = passwordValid[index] ? ValidIcon : InvalidIcon;

        return (
          <IonRow key={key}>
            <IonCol size="1">
              <ValidationIcon />
            </IonCol>
            <IonCol>
              <Text type="body" size="medium" className="margin-left-8">
                {label}
              </Text>
            </IonCol>
          </IonRow>
        );
      })}
    </IonGrid>
  );
};

const svgStyle = {
  height: "24px",
  position: "relative",
  top: "-2px",
  left: "-2px",
} as const;

const ValidIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    style={svgStyle}
  >
    <path
      fill="#008A07"
      d="M9.45 18.2L3.5 12.25l1.45-1.43 4.5 4.5 9.57-9.57 1.46 1.42L9.45 18.2z"
    ></path>
  </svg>
);

const InvalidIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    style={svgStyle}
  >
    <path
      fill="#B81237"
      d="M6.23 19.18l-1.4-1.4L10.6 12 4.83 6.23l1.4-1.4L12 10.6l5.78-5.77 1.4 1.4L13.4 12l5.78 5.78-1.4 1.4L12 13.4l-5.77 5.78z"
    ></path>
  </svg>
);

export default PasswordValidation;
