

import sentimentDissatisfied from "../sentimentDissatisfied.svg"
import { useContext, useMemo } from "react";
import { BottomSheetContext } from "../ui/BottomSheet";
import { BottomSheetWithCta } from "./BottomSheetWithCta";

export const PropertyCreationFailed = () => {

  const { setBottomSheetProps } = useContext(BottomSheetContext);

  const button = useMemo(() => {
    return {
      children: "Got it",
      onClick: () => {
        setBottomSheetProps({
          isOpen: false,
        });
      },
    };
  }, []);

  return (
    <BottomSheetWithCta
      icon={sentimentDissatisfied}
      color="error"
      header={"Looks like that didn’t work"}
      body={
        "We had an issue saving your property. Please try again or come back in a few minutes."
      }
      buttons={[button]}
    />
  );
};
