

import addHomeWork from "../addHomeWork.svg";
import { useContext, useMemo } from "react";
import { BottomSheetContext } from "../ui/BottomSheet";
import { BottomSheetWithCta } from "./BottomSheetWithCta";

export const PropertyCreated = () => {

  const { setBottomSheetProps } = useContext(BottomSheetContext);

  const button = useMemo(() => {
    return {
      children: "Got it",
      onClick: () => {
        setBottomSheetProps({
          isOpen: false,
        });
      },
    };
  }, []);

  return (
    <BottomSheetWithCta
      icon={addHomeWork}
      header={"Property added!"}
      body={
        "We can now send you a notification when this property is close to a future biosecurity outbreak zone."
      }
      buttons={[button]}
    />
  );
};
