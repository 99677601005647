import { personOutline } from "ionicons/icons";
import { useMemo } from "react";
import { useHistory } from "react-router";
import { BottomSheetWithCta } from "./BottomSheetWithCta";
import { ButtonPropVariant } from "../ui";

export const AccountCreatedState = () => {
  const history = useHistory();
  const addPropertyButton = useMemo(() => {
    return {
      children: "Add a property",
      onClick: () => {
        history.push("/add-property");
      },
    };
  }, []);

  const doThisLaterButton = useMemo(() => {
    return {
      children: "I'll do this later.",
      variant: "outline" as ButtonPropVariant,
    };
  }, []);

  return (
    <BottomSheetWithCta
      icon={personOutline}
      header={"Account created!"}
      body={
        "You can now get alerts for biosecurity oubreaks near you. Add a property to your account to start."
      }
      buttons={[addPropertyButton, doThisLaterButton]}
    />
  );
};
