import { FeatureCollection, Polygon, MultiPolygon } from "geojson";

export type PropertyBounds = FeatureCollection<Polygon | MultiPolygon, {}>;

type PropertyDetails = {
  propertyId: string;
  name: string;
  address: string;
  city: string;
  postcode: string;
  primaryContactName: string;
  primaryContactPhone: string;
  primaryContactEmail: string;
  holdings: Array<string>;
  location: L.LatLngLiteral;
};

export type PropertyDetailsWithBoundaryIds = PropertyDetails & {
  boundaryIds: Array<string>;
};

export type PropertyDetailsWithBoundary = PropertyDetailsWithBoundaryIds & {
  boundary?: PropertyBounds;
};

//create a typeguard for PropertyDetails
export const isPropertyDetails = (row: any): row is PropertyDetails => {
  return (
    typeof row.propertyId === "string" &&
    typeof row.name === "string" &&
    typeof row.address === "string" &&
    typeof row.city === "string" &&
    typeof row.postcode === "string" &&
    typeof row.primaryContactName === "string" &&
    typeof row.primaryContactPhone === "string" &&
    typeof row.primaryContactEmail === "string" &&
    Array.isArray(row.holdings) &&
    row.location !== undefined
  );
};

export const nullPropertyDetailsWithBoundary =
  (): PropertyDetailsWithBoundary => {
    return {
      propertyId: "",
      name: "",
      address: "",
      city: "",
      postcode: "",
      primaryContactName: "",
      primaryContactPhone: "",
      primaryContactEmail: "",
      holdings: [],
      location: { lat: 0, lng: 0 },
      boundaryIds: [],
    };
  };

export type PropertyAddressDetails = Pick<
  PropertyDetails,
  "name" | "address" | "city" | "postcode" | "location"
>;
