import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AgResponsePage } from "../../components/AgResponsePage";
import { NavFooter } from "../../components/NavFooter";
import { getProperties } from "../../api/user";
import { Card, Text } from "../../components/ui";
import { addOutline, arrowForward, homeOutline } from "ionicons/icons";
import { FullscreenLoader } from "../../components/ui/FullscreenLoader";
import Button from "../../components/ui/Button";

import { PropertyDetailsWithBoundary } from "../../project-types";
import EmptyState from "../../components/ui/EmptyState";
import { isGuestUser } from "../../api/guest";
import { GuestState } from "../../components/ui/GuestState";
import { SnackbarHandlerContext } from "../../components/SnackbarHandler";
import { useCacheLiveData } from "../../hooks/useCacheLiveData";
import { warning } from "ionicons/icons";

export const PropertiesPage: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [showGuestScreen, setShowGuestScreen] = useState(false);
  const { showDismissableSnackbar } = useContext(SnackbarHandlerContext);

  const history = useHistory();

  const {
    data: propertiesData,
    state: { hasLiveErrored, haveBothLoaded },
  } = useCacheLiveData(getProperties);

  useEffect(() => {
    isGuestUser().then((isGuest) => {
      if (!isGuest) {
        if (hasLiveErrored) {
          showDismissableSnackbar("Error retrieving properties");
          return;
        }
        setLoading(false);
      } else {
        setShowGuestScreen(true);
        setLoading(false);
      }
    });
  }, [propertiesData]);

  return (
    <AgResponsePage footer={<NavFooter />}>
      <Text
        as="h1"
        type="display"
        size="large"
        className="margin-bottom-32 margin-top-0"
      >
        Properties
      </Text>
      {loading && <FullscreenLoader />}

      {!loading && !showGuestScreen && !propertiesData && haveBothLoaded && (
        <EmptyState
          {...(hasLiveErrored
            ? {
                icon: warning,
                header: "Offline Mode",
                body: "We are unable to retrieve properties. Please check your internet connection.",
              }
            : {
                icon: homeOutline,
                header: "No properties yet",
                body: "Unlock all the features by adding a property and telling us what you're farming.",
                buttons: [
                  {
                    icon: addOutline,
                    text: "Add a property",
                    action: () => {
                      history.push("/add-property");
                    },
                  },
                ],
              })}
        />
      )}
      {!loading && !showGuestScreen && propertiesData && (
        <LoggedInUser properties={propertiesData} />
      )}
      {!loading && showGuestScreen && (
        <GuestState
          body="Sign in to view and manage your properties."
          nextRoute={"/properties"}
        />
      )}
    </AgResponsePage>
  );
};

const LoggedInUser = ({
  properties,
}: {
  properties: PropertyDetailsWithBoundary[];
}) => {
  const history = useHistory();

  return (
    <>
      {properties.length > 0 &&
        properties.map((property, index) => (
          <Card
            key={index}
            cardOnClickAction={() => {
              history.push(`/property/${property.propertyId}`);
            }}
            title={property.name}
            subtitle={property.address}
            rightIcon
            titleIcon={arrowForward}
            variant="secondary"
          />
        ))}
      <Button
        icon={addOutline}
        expand="block"
        onClick={() => {
          history.push("/add-property");
        }}
        className="margin-top-32"
      >
        Add a property
      </Button>
    </>
  );
};
