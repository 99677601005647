import axios from "axios";
import { getJWT } from "./localStorage";
import { clearCache, clearKey, cachedGet } from "./offlineCacher";
import { REACT_APP_API_URL } from "../env";
import {
  PropertyDetailsWithBoundary,
  PropertyDetailsWithBoundaryIds,
  UserDetails,
} from "../project-types";

export const addUser = (userSub: string, userDetails: Partial<UserDetails>) => {
  return axios
    .post<boolean>(
      `${REACT_APP_API_URL}/user`,
      { userSub, userDetails },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => res.status === 200)
    .catch((e) => {
      console.error(e);
      return false;
    });
};

export const getUser = (jwt?: string) => {
  if (jwt) {
    return axios
      .get<Required<UserDetails> & { jwtExpiry: number }>(
        `${REACT_APP_API_URL}/user`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwt}`,
          },
        }
      )
      .then((res) => res.data)
      .catch(() => {
        return undefined;
      });
  }

  return cachedGet<Required<UserDetails> & { jwtExpiry: number }>(
    `${REACT_APP_API_URL}/user`
  )
    .live.then((user) => user)
    .catch(() => {
      return undefined;
    });
};

export const updateUser = async (user: Partial<UserDetails>) => {
  const token = await getJWT()
    .then((token) => token)
    .catch(() => undefined);

  if (!token) {
    console.error("No token");
    return undefined;
  }

  const success = await axios
    .patch<boolean>(
      `${REACT_APP_API_URL}/user`,
      { updated: user },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(async (res) => {
      return res.status === 200;
    })
    .catch((e) => {
      console.error(e);
      return false;
    });

  if (success) {
    await clearKey(`${REACT_APP_API_URL}/user`);
    await getUser();
  }

  return success;
};

export const addProperty = async (property: PropertyDetailsWithBoundaryIds) => {
  const token = await getJWT()
    .then((token) => token)
    .catch(() => undefined);
  if (!token) return undefined;

  return axios
    .post<boolean>(
      `${REACT_APP_API_URL}/user/add-property`,
      { property },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => {
      clearKey(`${REACT_APP_API_URL}/user/properties`);
      return res.status === 200;
    })
    .catch((e) => {
      console.error(e);
      return false;
    });
};

export const getProperties = () => {
  return cachedGet<Array<PropertyDetailsWithBoundary>>(
    `${REACT_APP_API_URL}/user/properties`
  );
};

export const getProperty = (propertyId: string[]) => {
  const { cache, live } = cachedGet<Array<PropertyDetailsWithBoundary>>(
    `${REACT_APP_API_URL}/user/properties`
  );
  return {
    cache: cache.then((properties) => {
      return properties
        ? properties.find((p) => p.propertyId === propertyId[0])
        : undefined;
    }),
    live: live.then((properties) => {
      return properties
        ? properties.find((p) => p.propertyId === propertyId[0])
        : undefined;
    }),
  };
};

export const getPropertyFullAddress = (
  property: PropertyDetailsWithBoundary | PropertyDetailsWithBoundaryIds
) => {
  return `${property.address}, ${property.city}, NSW, ${property.postcode}`;
};

export const updateProperty = async (property: PropertyDetailsWithBoundary) => {
  const token = await getJWT()
    .then((token) => token)
    .catch(() => undefined);
  if (!token) return undefined;

  const success = await axios
    .patch<boolean>(
      `${REACT_APP_API_URL}/user/property`,
      { property },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(async (res) => {
      return res.status === 200;
    })
    .catch((e) => {
      console.error(e);
      return false;
    });
  if (success) {
    await clearKey(`${REACT_APP_API_URL}/user/properties`);
  }
  return success;
};

export const deleteProperty = async (id: string) => {
  const token = await getJWT()
    .then((token) => token)
    .catch(() => undefined);

  if (!token) return undefined;

  const success = await axios
    .delete<boolean>(`${REACT_APP_API_URL}/user/property?id=${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async (res) => {
      return res.status === 200;
    })
    .catch((e) => {
      console.error(e);
      return false;
    });

  if (success) {
    await clearKey(`${REACT_APP_API_URL}/user/properties`);
  }

  return success;
};

// TODO: add bottom sheet error notification if this function fails
export const addNewPushToken = async (deviceId: string, pushToken: string) => {
  const token = await getJWT()
    .then((token) => token)
    .catch(() => undefined);
  if (!token) return undefined;

  return axios
    .post<boolean>(
      `${REACT_APP_API_URL}/user/add-push-token`,
      { token: `${deviceId}|${pushToken}` },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => {
      return res.status === 200;
    })
    .catch((e) => {
      console.error(e);
      return false;
    });
};

export const doesDeviceHavePushToken = async (deviceId: string) => {
  const user = await getUser();
  if (user === undefined || user.pushTokens === undefined) {
    return false;
  }
  return (
    user.pushTokens
      .split(",")
      //@ts-ignore
      .map((devicePushToken) => devicePushToken.split("|")[0])
      .includes(deviceId)
  );
};

export const deleteUser = async () => {
  try {
    const token = await getJWT();

    if (!token) {
      throw new Error("No token");
    }

    const response = await axios.delete<boolean>(`${REACT_APP_API_URL}/user`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (response?.status === 200) {
      await clearCache();
      return true;
    }

    throw new Error(`DELETE_STATUS_${response?.status || "MISSING"}_ERROR`);
  } catch (e) {
    console.error("DELETE_USER_ERROR", e);
  }

  return false;
};

export const usersByEmail = async (email: string) => {
  return axios
    .post<UserDetails[]>(`${REACT_APP_API_URL}/user/search`, {
      email,
    })
    .then((res) => {
      return res.data;
    })
    .catch(() => {
      return undefined;
    });
};

export const getNotifications = () => {
  return cachedGet<
    Array<{
      body: string;
      title: string;
      sent: string;
      emergencyId: string;
      hasRead: boolean;
      notificationId: string;
      deepLink: string;
    }>
  >(`${REACT_APP_API_URL}/user/notifications`);
};

export const updateNotification = async (notificationId: string) => {
  const token = await getJWT()
    .then((token) => token)
    .catch(() => undefined);

  if (!token) return undefined;

  return await axios
    .patch<boolean>(
      `${REACT_APP_API_URL}/user/updateNotification/${notificationId}`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(async (res) => {
      return res.status === 200;
    })
    .catch((e) => {
      console.error(e);
      return false;
    });
};
