import {
  FirebaseMessaging,
  NotificationReceivedListener,
} from "@capacitor-firebase/messaging";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { addNewPushToken, doesDeviceHavePushToken } from "./user";
import { Capacitor } from "@capacitor/core";
import { PushNotifications, Token } from "@capacitor/push-notifications";
import { getUserSub } from "./localStorage";
import { Device } from "@capacitor/device";

export const getAllFirebaseEnvVars = () => {
  const envVars = [
    "REACT_APP_FIREBASE_API_KEY",
    "REACT_APP_FIREBASE_AUTH_DOMAIN",
    "REACT_APP_FIREBASE_PROJECT_ID",
    "REACT_APP_FIREBASE_STORAGE_BUCKET",
    "REACT_APP_FIREBASE_MESSAGING_SENDER_ID",
    "REACT_APP_FIREBASE_APP_ID",
    "REACT_APP_FIREBASE_MEASUREMENT_ID",
  ];

  const envVarValues: Array<string> = envVars.filter(
    (key) => process.env[key] !== undefined
  );
  if (
    envVarValues.length !== envVars.length ||
    envVarValues.some((value) => value === undefined || value === "")
  ) {
    return undefined;
  }

  return {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  };
};

//create a singleton for the analytics object
let analytics: ReturnType<typeof getAnalytics> | undefined;

export const setupFirebase = () => {
  const firebaseVars = getAllFirebaseEnvVars();

  if (!firebaseVars) {
    return undefined;
  }
  const app = initializeApp(firebaseVars);

  analytics = getAnalytics(app);
};

export const pushNotificationsEnabled = async () => {
  const { receive: checkReceive } = await FirebaseMessaging.checkPermissions();
  return checkReceive === "granted";
};

export const setupNotifications = async () => {
  const { identifier } = await Device.getId();

  // if this step takes too long, await FirebaseMessaging.requestPermissions();
  // doesn't trigger the permissions popup for some browsers
  const hasPushToken = await doesDeviceHavePushToken(identifier);
  if (hasPushToken) {
    return;
  }

  if (Capacitor.isNativePlatform()) {
    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermissions().then(async (result) => {
      if (result.receive === "granted") {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();

        // if (Capacitor.getPlatform() === "ios") {
        //   await FirebaseMessaging.checkPermissions();
        //   await FirebaseMessaging.requestPermissions();
        //   const token = await getToken();
        //   await addNewPushToken(token);
        //   logAnalyticsEvent("Notification opt-in", {});
        // }
      } else {
        // Show some error
      }
    });

    // On success, we should be able to receive notifications
    PushNotifications.addListener("registration", async (token: Token) => {
      await FirebaseMessaging.checkPermissions();
      await FirebaseMessaging.requestPermissions();

      await checkAndSavePushNotificationToken();
    });

    // Some issue with our setup and push will not work
    PushNotifications.addListener("registrationError", (error: any) => {
      console.error("Error on registration: " + JSON.stringify(error));
    });
    return;
  }

  await checkAndSavePushNotificationToken();

  return;
};

export const checkAndSavePushNotificationToken = async () => {
  const { identifier } = await Device.getId();
  const hasPushToken = await doesDeviceHavePushToken(identifier);
  if (hasPushToken) {
    return;
  }

  const { receive: checkReceive } = await FirebaseMessaging.checkPermissions();

  try {
    if (checkReceive !== "denied") {
      const { receive: promptReceive } =
        await FirebaseMessaging.requestPermissions();

      if (promptReceive === "granted") {
        const token = await getToken();

        // so recheck after the user has granted permissions to see if there is a double up of tokens
        const hasPushToken = await doesDeviceHavePushToken(identifier);
        
        if (!hasPushToken) {
          await addNewPushToken(identifier, token);
          logAnalyticsEvent("Notification opt-in", {});
        }
      } else {
        logAnalyticsEvent("Notification opt-out", {});
        return false;
      }
    }
  } catch (e) {
    console.error({ e });
  }
};

export const isNotificationPromptStatus = async () => {
  // let promptStatus:PermissionState;
  try {
    if (Capacitor.isNativePlatform()) {
      const permStatus = await PushNotifications.checkPermissions();
      return permStatus.receive;
    } else {
      const { receive: checkReceive } =
        await FirebaseMessaging.checkPermissions();
      return checkReceive; //=== "prompt" || checkReceive === "prompt-with-rationale";
    }
  } catch (e) {
    console.error({ e });
  }
};

export const getToken = async () => {
  const result = await FirebaseMessaging.getToken({
    vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
  });
  return result.token;
};

export const deleteToken = async () => {
  await FirebaseMessaging.deleteToken();
};

export const getDeliveredNotifications = async () => {
  const result = await FirebaseMessaging.getDeliveredNotifications();
  return result.notifications;
};

export const removeDeliveredNotifications = async () => {
  await FirebaseMessaging.removeDeliveredNotifications({
    notifications: [],
  });
};

export const removeAllDeliveredNotifications = async () => {
  await FirebaseMessaging.removeAllDeliveredNotifications();
};

export const subscribeToTopic = async (topic: string) => {
  await FirebaseMessaging.subscribeToTopic({ topic });
};

export const unsubscribeFromTopic = async (topic: string) => {
  await FirebaseMessaging.unsubscribeFromTopic({ topic });
};

export const addTokenReceivedListener = async () => {
  await FirebaseMessaging.addListener("tokenReceived", () => {});
};

export const addNotificationActionPerformedListener = async (
  listenerFunc: NotificationReceivedListener
) => {
  await FirebaseMessaging.addListener(
    "notificationActionPerformed",
    listenerFunc
  );
};

export const removeAllListeners = async () => {
  await FirebaseMessaging.removeAllListeners();
};

// Function to log an action to Google Analytics through the Firebase SDK
export const logAnalyticsEvent = (eventName: string, eventParams: any) => {
  getUserSub().then((sub) => {
    if (!analytics) {
      return;
    }
    let userId = "";
    if (sub) {
      userId = sub;
    }

    logEvent(analytics, eventName, { ...eventParams, userId });
  });
};
