import booleanIntersects from "@turf/boolean-intersects";
import PointInPolygon from "@turf/boolean-point-in-polygon";
import { Polygon, point } from "@turf/helpers";
import { Feature } from "geojson";

import {
  EmergencyDetails,
  PropertyDetailsWithBoundary,
} from "../project-types";
import { assetIncludesAllProperties } from "./emergencies";

type EmergencyDetailsProp = Pick<
  EmergencyDetails,
  "id" | "mapData" | "assets" | "emergencyTitle" | "updated"
>;

export const isPropertyAffected = (
  property: PropertyDetailsWithBoundary,
  emergency: EmergencyDetailsProp
): boolean => {
  const hasAllPropertiesAsset = assetIncludesAllProperties(emergency);

  return (
    hasAllPropertiesAsset ||
    property.holdings.some((holding) => {
      return emergency.assets.includes(holding);
    })
  );
};

export const isPropertyInZone = (
  property: PropertyDetailsWithBoundary,
  zone: Feature<Polygon>
): boolean => {
  if (property.boundary && property.boundary.features) {
    return property.boundary.features.reduce((acc, bound) => {
      if (acc) {
        return acc;
      }
      return booleanIntersects(bound, zone);
    }, false);
  } else {
    return PointInPolygon(
      point([property.location.lng, property.location.lat]),
      zone
    );
  }
};
