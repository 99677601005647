import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { useEffect, useState, useRef } from "react";
import Note from "./Note";
import { Input } from "./Input";
import FullscreenLoader from "./FullscreenLoader";

export type VerificationCodeProps = {
  numOfDigits?: number;
  onVerified?: () => void;
  onFailed?: () => void;
  service: (code: string) => Promise<boolean>;
  id?: string;
  noAlert?: boolean;
};

const VerificationCode = ({
  onVerified,
  onFailed,
  numOfDigits = 6,
  service,
  id,
  noAlert,
}: VerificationCodeProps) => {
  const [userPassCode, setUserPassCode] = useState<Array<string>>([]);
  const [statusCodeMessage, setStatusCodeMessage] = useState({});
  const numbersRef = useRef<Array<HTMLIonInputElement>>([]);
  const [loading, setLoading] = useState<boolean>(false);

  // const checkPassCode = (newValue: string, key: string, index: number) => {
  //   const newArr = [...userPassCode];
  //   newArr[index] = newValue;
  //   setUserPassCode(newArr);

  //   const ignoredKeys = [
  //     "Backspace",
  //     "Delete",
  //     "ArrowLeft",
  //     "ArrowRight",
  //     "Tab",
  //     "Shift",
  //   ];

  //   if (key === "Backspace") {
  //     if (numbersRef.current[index - 1]) {
  //       numbersRef.current[index - 1].children[0].nodeValue = "";
  //       //@ts-ignore
  //       numbersRef.current[index - 1]?.children[0].select();
  //     }
  //   }

  //   if (!ignoredKeys.includes(key) && index !== numOfDigits - 1) {
  //     //@ts-ignore
  //     numbersRef.current[index + 1]?.children[0].focus();
  //   }
  // };

  useEffect(() => {
    if (userPassCode.length === numOfDigits && !userPassCode.includes("")) {
      // let verification = undefined;
      setLoading(true);

      service(userPassCode.join("")).then((verified) => {
        if (verified) {
          !noAlert &&
            setStatusCodeMessage({
              success: true,
              children: "Code verified successfully",
            });
          onVerified && onVerified();
        } else {
          !noAlert &&
            setStatusCodeMessage({
              error: true,
              children: "Invalid code. Please try again.",
            });

          numbersRef.current.map(
            (numberRef) => (numberRef.children[0].nodeValue = "")
          );
          //@ts-ignore
          numbersRef.current[0]?.children[0].focus();
          onFailed && onFailed();
        }
        setLoading(false);
      });
    } else {
      setStatusCodeMessage({});
      setLoading(false);
    }
  }, [userPassCode]);

  return (
    <IonGrid>
      <IonRow>
        <IonCol>
          <Input
            inputMode="numeric"
            disabled={loading}
            onIonInput={({ target: { value } }) => {
              if (value && value.toString().trim().length === numOfDigits) {
                setUserPassCode(value.toString().split(""));
              }
            }}
          />
          {/* {[...Array(numOfDigits)].map((_, index) => (
          <IonCol key={index}>
            <IonItem fill="outline">
              <IonInput
                onKeyUp={(e) => {
                  const value = e.currentTarget.value
                    ? e.currentTarget.value.toString()
                    : "";
                  return checkPassCode(value, e.key, index);
                }}
                ref={(ref) => ref && numbersRef.current.push(ref)}
                inputMode="numeric"
                maxlength={1}
              />
            </IonItem>
          </IonCol>
        ))} */}
        </IonCol>
      </IonRow>
      {loading && <FullscreenLoader />}
      {Object.keys(statusCodeMessage).length > 0 && (
        <IonRow>
          <IonCol>
            <Note {...statusCodeMessage} className="margin-top-4" />
          </IonCol>
        </IonRow>
      )}
    </IonGrid>
  );
};

export default VerificationCode;
