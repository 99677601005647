import { IonItem, IonList, IonSelect, IonSelectOption } from "@ionic/react";
import "./SelectAssets.scss";
import { Block, Text } from "./ui";
import { Dispatch, SetStateAction } from "react";
import { animals, crops } from "../api/assets";
import { useDevice } from "../hooks/useDevice";

type SelectAssetsProps = {
  selectedCrops: string[];
  setSelectedCrops: Dispatch<SetStateAction<string[]>>;
  selectedAnimals: string[];
  setSelectedAnimals: Dispatch<SetStateAction<string[]>>;
};

export const SelectAssets = ({
  selectedCrops,
  setSelectedCrops,
  selectedAnimals,
  setSelectedAnimals,
}: SelectAssetsProps) => {
  const { isMobile } = useDevice();

  return (
    <IonList>
      <Block>
        <Text type="heading" size="small">
          Animals
        </Text>
      </Block>
      <IonItem className="fake-input-wrapper">
        <IonSelect
          placeholder="Select all animals that apply"
          multiple={true}
          value={selectedAnimals}
          interface={isMobile ? "alert" : "popover"}
          onIonChange={(e) => {
            setSelectedAnimals(e.detail.value);
          }}
        >
          {animals.map((animal) => (
            <IonSelectOption key={animal} value={animal}>
              {animal}
            </IonSelectOption>
          ))}
        </IonSelect>
      </IonItem>
      <Block>
        <Text type="heading" size="small">
          Crops
        </Text>
      </Block>
      <IonItem className="fake-input-wrapper">
        <IonSelect
          placeholder="Select all crops that apply"
          multiple={true}
          value={selectedCrops}
          interface={isMobile ? "alert" : "popover"}
          onIonChange={(e) => {
            setSelectedCrops(e.detail.value);
          }}
        >
          {crops.map((crop) => (
            <IonSelectOption key={crop} value={crop}>
              {crop}
            </IonSelectOption>
          ))}
        </IonSelect>
      </IonItem>
    </IonList>
  );
};
