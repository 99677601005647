import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  handleForgotPassword,
  handleLogin,
  handleLogout,
  handleResetPassword,
} from "../../api/cognito";
import { Input } from "../../components/ui";
import Button from "../../components/ui/Button";
import { Text } from "../../components/ui";
import VerificationCode from "../../components/ui/VerificationCode";
import { refreshOutline } from "ionicons/icons";
import EditDetailsCard from "../../components/ui/EditDetailsCard";
import { UserDetails } from "../../project-types";
import PasswordValidation from "../../components/PasswordValidation";
import { isPasswordValid } from "../../utils/passwordValidation";
import { BottomSheetContext } from "../../components/ui/BottomSheet";
import { deleteUser } from "../../api/user";
import { GenericFail } from "../../components/bottomSheet/GenericFail";
import FullscreenLoader from "../../components/ui/FullscreenLoader";

type LoginAndSecurityProps = {
  user: UserDetails;
  onLoad: (options: { title: string }) => void;
  onEdit: (editEnabled: boolean) => void;
};

const LoginAndSecurity = ({ user, onLoad, onEdit }: LoginAndSecurityProps) => {
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [resetPasswordMode, setResetPasswordMode] = useState<boolean>(false);
  const [code, setCode] = useState<string>("");
  const history = useHistory();
  const { setBottomSheetProps } = useContext(BottomSheetContext);

  useEffect(() => {
    onLoad({
      title: "Login & security",
    });
  }, []);

  const handleDeleteAccount = useCallback(() => {
    setBottomSheetProps({
      isOpen: true,
      title: "Delete account?",
      children: (
        <>
          <Text type="body" size="large" as="p">
            If you delete your account you will lose all of your saved
            properties and no longer receive alerts for biosecurity emergencies.
          </Text>
          <Button
            variant="primary"
            loading={loading}
            onClick={async () => {
              try {
                setLoading(true);
                const res = await deleteUser();
                setBottomSheetProps({ isOpen: false });

                if (res) {
                  handleLogout().then(() => {
                    history.push("/login");
                  });
                } else {
                  throw new Error("Could not delete user's account");
                }
              } catch (e) {
                console.error(e);
                console.error("Account deletion failed. Please try again.");

                setBottomSheetProps({
                  isOpen: true,
                  isCloseCtaVisible: false,
                  children: <GenericFail />,
                });
              }
            }}
          >
            Delete account
          </Button>
        </>
      ),
    });
  }, []);

  return (
    <>
      <div className="ion-margin-bottom">
        <Text type="body" size="small">
          Email
        </Text>
        <Text type="body" size="large">
          {user.email}
        </Text>
      </div>
      <EditDetailsCard
        editableFields={{}}
        labels={{}}
        onEdit={() => {
          handleForgotPassword(user.email).then((res) => {
            if (res) {
              setResetPasswordMode(true);
              onEdit(true);
            }
          });
        }}
        editButtonIcon={refreshOutline}
        editButtonText="Reset password"
        hideSaveButton
        title="Password"
        nonEditModeChildren={
          <div className="margin-y-16">
            <Text type="body" size="large">
              Password
            </Text>
            <Text type="body" size="small">
              ***************
            </Text>
          </div>
        }
      />

      {resetPasswordMode && (
        <>
          <Text type="body" size="medium" as="p">
            Please enter the verification code sent to your email address and
            your new password.
          </Text>
          <VerificationCode
            noAlert
            service={(value) => {
              setCode(value);
              return Promise.resolve(true);
            }}
          />

          <Input
            label="Password"
            placeholder="Enter password"
            type="password"
            autocomplete="new-password"
            value={password}
            onChange={(value) => setPassword(value)}
            error={error}
            errorIcon={error !== ""}
          />
          <PasswordValidation password={password} />
          {loading && <FullscreenLoader loadingMessage="Resetting password" />}
          <Button
            onClick={() => {
              setLoading(true);
              setError("");

              if (!isPasswordValid(password)) {
                setLoading(false);
                setError("Password does not meet the requirements.");
                return;
              }

              handleResetPassword(user.email, code, password)
                .then(() => {
                  handleLogin(user.email, password).then(() => {
                    setLoading(false);
                    history.push("/emergencies");
                  });
                })
                .catch((err) => {
                  setLoading(false);
                  setError(err.toString().split(":")[1]);
                });
            }}
            disabled={
              password.length === 0 || loading || !isPasswordValid(password)
            }
            variant="primary"
            size="large"
            expand="block"
          >
            Continue
          </Button>
        </>
      )}

      {!resetPasswordMode && (
        <div className="ion-text-center cancel-btn-wrapper margin-top-32">
          <Button variant="outline" onClick={handleDeleteAccount}>
            Delete account
          </Button>
        </div>
      )}
    </>
  );
};

export default LoginAndSecurity;
