import axios from "axios";
import { cachedGet } from "./offlineCacher";

import { FormTypes } from "@oneblink/types";
import { getJWT } from "./localStorage";
import { REACT_APP_API_URL } from "../env";

export type Element = FormTypes.FormElement & {
  name: string;
  required?: boolean;
  label?: string;
  options: Array<{ id: string; label: string; value: string }>;
};

export type Form = Omit<FormTypes.Form, "elements"> & {
  elements: Array<Element>;
};

export interface OptionsEntity {
  id: string;
  label: string;
  value: string;
}

export interface ConditionallyShowPredicatesEntity {
  elementId: string;
  optionIds?: Array<string>;
  type: string;
}

export const getForm = (formId: string) => {
  return cachedGet<Form>(`${REACT_APP_API_URL}/form/${formId}`, true);
};

export const formSubmission = async (
  formId: string,
  submission: Record<string, any>,
  optionals?: {
    propertyId?: string;
    submissionId?: string;
    success?: boolean;
  },
) => {
  const token = await getJWT()
    .then((token) => token)
    .catch(() => undefined);
  if (!token) return undefined;

  return await axios
    .post<boolean>(
      `${REACT_APP_API_URL}/form/${formId}`,
      { submission, ...optionals },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((res) => res.status === 200)
    .catch(() => {
      return false;
    });
};
