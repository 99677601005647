import L from "leaflet";
import { useEffect, useState } from "react";
import { Marker, GeoJSON } from "react-leaflet";
import {
  AddressSearchResult,
  AddressLocateResult,
  verifyPoint,
  getPropertyBounds,
} from "../../../api/addressSearch";
import { AgAddressSearch } from "../../../components/AgAddressSearch";
import { AgMap, icons } from "../../../components/AgMap";
import { SwiperFlowChildProps } from "../../../components/SwiperFlow";
import { Text } from "../../../components/ui";
import { logAnalyticsEvent } from "../../../api/firebase";
import { propertyBoundsStyle } from "../../../theme/geoStyles";
import {
  PropertyBounds,
  PropertyDetailsWithBoundary,
} from "../../../project-types";
import "./index.scss";

export type AddressResult = AddressSearchResult &
  AddressLocateResult & {
    propertyBounds?: PropertyBounds;
  };
type ByAddressProps = SwiperFlowChildProps & {
  onAddressSelected: (address: AddressResult) => void;
  initialProperty?: PropertyDetailsWithBoundary;
  step?: string;
};

const selectedAddressNull: AddressSearchResult & AddressLocateResult = {
  address: "",
  hasGeo: false,
  geo: {
    geometry: {
      type: "Point",
      coordinates: [0, 0],
    },
    geoFeature: "",
  },
  id: "",
  rank: 0,
  addressDetails: {
    cadastralIdentifier: "",
    localityName: "",
    stateTerritory: "",
    postcode: "",
    formattedAddress: "",
    streetName: "",
    streetNumber1: "",
    streetNumber2: "",
    streetType: "",
  },
};

export const ByAddress = ({
  active,
  nextClickHandled,
  nextClicked,
  onStepFinished,
  updateFooter,
  visible,
  onAddressSelected,
  initialProperty,
  step,
}: ByAddressProps) => {
  const [selectedAddress, setSelectedAddress] =
    useState<AddressSearchResult & AddressLocateResult>(selectedAddressNull);

  const [droppedPin, setDroppedPin] =
    useState<{
      point: L.LatLng;
      doPropertyBounds: boolean;
      newProperty: boolean;
    }>();
  const [showPin, setShowPin] = useState(false);
  const [clearSearch, setClearSearch] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [propertyBounds, setPropertyBounds] =
    useState<PropertyBounds | undefined>(undefined);
  const [mapFocus, setMapFocus] = useState<GeoJSON.FeatureCollection>();

  useEffect(() => {
    if (initialProperty) {
      setSelectedAddress({
        ...selectedAddressNull,
        address: initialProperty.address,
        hasGeo: true,
        geo: {
          geometry: {
            type: "Point",
            coordinates: [
              initialProperty.location.lng,
              initialProperty.location.lat,
            ],
          },
          geoFeature: "Placed Pin",
        },
      });
      setPropertyBounds(initialProperty.boundary);
      setDroppedPin({
        point: new L.LatLng(
          initialProperty.location.lat,
          initialProperty.location.lng
        ),
        doPropertyBounds: false,
        newProperty: false,
      });
    }
  }, [initialProperty]);

  useEffect(() => {
    if (active) {
      if (selectedAddress.address === "" && droppedPin === undefined) {
        updateFooter({
          disableNext: true,
        });
      } else {
        updateFooter({
          disableNext: false,
        });
      }
    }
  }, [active]);

  useEffect(() => {
    if (active && visible && selectedAddress) {
      onAddressSelected({ ...selectedAddress, propertyBounds });
    }
  }, [selectedAddress, propertyBounds]);

  useEffect(() => {
    if (visible && nextClicked) {
      nextClickHandled();
      onStepFinished();
    }
  }, [nextClicked]);

  useEffect(() => {
    if (droppedPin) {
      if (!droppedPin.doPropertyBounds) {
        setShowPin(true);
        updateFooter({
          disableNext: false,
        });
        return;
      }
      setLoading(true);
      const tempSelectedAddress: AddressSearchResult & AddressLocateResult = {
        ...(droppedPin.newProperty ? selectedAddressNull : selectedAddress),
        hasGeo: true,
        geo: {
          geometry: {
            type: "Point",
            coordinates: [droppedPin.point.lng, droppedPin.point.lat],
          },
          geoFeature: "Placed Pin",
        },
      };
      verifyPoint(droppedPin.point).then((result) => {
        if (result) {
          getPropertyBounds(droppedPin.point)
            .then((bounds) => {
              if (bounds && bounds.features.length > 0) {
                setPropertyBounds(bounds);
              }
            })
            .catch((e) => {
              console.error(e);
            })
            .then(() => {
              setShowPin(true);
              setLoading(false);
            });
        }
        setSelectedAddress(tempSelectedAddress);
        setLoading(false);

        updateFooter({
          disableNext: false,
        });
      });
    }
  }, [droppedPin]);

  useEffect(() => {
    if (visible && active && propertyBounds) {
      setMapFocus(propertyBounds);
    }
  }, [active, visible, propertyBounds]);

  useEffect(() => {
    if (loading) {
      updateFooter({
        disableNext: true,
      });
    }
  }, [loading]);

  return (
    <div className="ag-by-address-wrapper">
      <Text size="large" type="body">
        {step || "Step 1 of 5"}
      </Text>

      <Text type="display" size="small" as="h2" bold className="margin-y-16">
        Find your property
      </Text>
      {/* 
        <Text type="body" size="large" className="margin-bottom-16">
          Search for your property, or move and tap on the map to drop a pin.
        </Text>
      */}
      <AgMap
        style={{
          flexGrow: 1,
        }}
        zoomControl="default"
        onMapClick={(e) => {
          const classList: Array<string> =
            //@ts-ignore
            e.originalEvent.target.classList.value.split(" ");

          if (classList.includes("leaflet-container")) {
            setPropertyBounds(undefined);
            setShowPin(false);
            setClearSearch(true);
            setDroppedPin({
              point: e.latlng,
              doPropertyBounds: true,
              newProperty: true,
            });

            logAnalyticsEvent("add_property_by_pin_drop", e.latlng);
          }
        }}
        geojsonFocus={mapFocus}
        flyTo={
          selectedAddress &&
          selectedAddress.hasGeo &&
          !isNaN(selectedAddress.geo.geometry.coordinates[1]) &&
          !isNaN(selectedAddress.geo.geometry.coordinates[0]) //&& droppedPin === undefined
            ? {
                latLng: {
                  lat: selectedAddress.geo.geometry.coordinates[1],
                  lng: selectedAddress.geo.geometry.coordinates[0],
                },
                zoom: 13,
                duration: 0.5,
              }
            : undefined
        }
      >
        {propertyBounds && (
          <GeoJSON
            data={propertyBounds}
            style={propertyBoundsStyle(false, -1)}
          />
        )}

        {showPin && selectedAddress && selectedAddress.hasGeo && (
          <Marker
            position={{
              lat: selectedAddress.geo.geometry.coordinates[1],
              lng: selectedAddress.geo.geometry.coordinates[0],
            }}
            icon={icons.property.white}
          />
        )}

        <AgAddressSearch
          overrideBackground={
            propertyBounds !== undefined ? "#EAF3FF" : undefined
          }
          onAddressClicked={() => {
            setPropertyBounds(undefined);
            setDroppedPin(undefined);
            setSelectedAddress(selectedAddressNull);
            setShowPin(false);
          }}
          forceLoading={loading}
          onAddressSelected={(address) => {
            setPropertyBounds(undefined);
            setDroppedPin(undefined);
            setSelectedAddress(address);
            setShowPin(false);

            updateFooter({
              disableNext: true,
            });

            if (address.hasGeo) {
              const [lng, lat] = address.geo.geometry.coordinates;
              setDroppedPin({
                point: new L.LatLng(lat, lng),
                doPropertyBounds: true,
                newProperty: false,
              });
            }
          }}
          inMap
          clearSearch={clearSearch}
          clearSearchHandled={() => {
            setClearSearch(false);
          }}
        />
      </AgMap>
    </div>
  );
};
