import { useEffect, useMemo, useState, useContext } from "react";
import { AgResponsePage } from "../../components/AgResponsePage";
import { NavFooter } from "../../components/NavFooter";
import { Text } from "../../components/ui";
import { getNotifications, updateNotification } from "../../api/user";
import NotificationCard from "../../components/ui/NotificationCard";
import { FullscreenLoader } from "../../components/ui/FullscreenLoader";
import { IonCol, IonGrid, IonIcon, IonRow } from "@ionic/react";
import { checkmarkOutline, settingsOutline } from "ionicons/icons";
import { useHistory } from "react-router";
import Button from "../../components/ui/Button";

import formatDate from "../../utils/formatDate";
import { isGuestUser } from "../../api/guest";
import { GuestState } from "../../components/ui/GuestState";
import EmptyState from "../../components/ui/EmptyState";
import { useCacheLiveData } from "../../hooks/useCacheLiveData";
import { SnackbarHandlerContext } from "../../components/SnackbarHandler";

export const NotificationsPage = () => {
  const history = useHistory();
  const [showGuestScreen, setShowGuestScreen] = useState(false);
  const [notifications, setNotifications] =
    useState<
      Array<{
        body: string;
        title: string;
        sent: string;
        emergencyId: string;
        notificationId: string;
        hasRead: boolean;
        deepLink: string;
      }>
    >();
  const { showDismissableSnackbar } = useContext(SnackbarHandlerContext);
  const {
    data: notificationsData,
    state: { hasLiveErrored },
  } = useCacheLiveData(getNotifications);

  useEffect(() => {
    isGuestUser().then((isGuest) => {
      if (hasLiveErrored) {
        showDismissableSnackbar("Error retrieving new notifications");
      }

      if (isGuest) {
        setShowGuestScreen(true);
        setNotifications([]);
        return;
      }

      if (!notificationsData) return;

      const lastThirtyNotifications = notificationsData.slice(-30);
      setNotifications(lastThirtyNotifications);

      isGuestUser().then((isGuest) => {
        if (isGuest) {
          setShowGuestScreen(true);
          setNotifications([]);
        }
      });
    });
  }, [notificationsData]);

  const handleClickedNotification = (
    notificationId: string,
    deepLink: string,
    isRead: boolean
  ) => {
    !isRead && updateNotification(notificationId);

    const [url, ...queries] = deepLink.split("&");
    const newUrl = url.split("=")[1] + "?" + queries.join("&");

    history.push(newUrl);
  };

  const showLoader = useMemo(() => {
    return !notifications;
  }, [notifications]);

  const showNotifications = useMemo(() => {
    return notifications && !showGuestScreen && notifications.length > 0;
  }, [notifications, showGuestScreen]);

  const showEmptyState = useMemo(() => {
    return notifications && !showGuestScreen && notifications.length === 0;
  }, [notifications, showGuestScreen]);

  return (
    <AgResponsePage footer={<NavFooter />}>
      <IonGrid className="ion-no-padding">
        <IonRow class="ion-align-items-center">
          <IonCol>
            <Text
              type="display"
              size="large"
              as="h1"
              className="margin-bottom-32 margin-top-0"
            >
              Notifications
            </Text>
          </IonCol>
          {!showGuestScreen && (
            <IonCol
              size="2"
              className="ion-text-right padding-bottom-16 padding-left-8"
            >
              <Button
                variant="clear"
                onClick={() => history.push("/settings/notifications")}
              >
                <Text type="display" size="small" as="span">
                  <IonIcon icon={settingsOutline} />
                </Text>
              </Button>
            </IonCol>
          )}
        </IonRow>
      </IonGrid>

      {showLoader && <FullscreenLoader />}
      {showNotifications &&
        notifications!.map(
          ({ title, body, sent, notificationId, hasRead, deepLink }) => (
            <NotificationCard
              key={sent}
              subtitle={`${title} • ${formatDate(sent)}`}
              content={body}
              cardOnClick={() =>
                handleClickedNotification(notificationId, deepLink, hasRead)
              }
              read={hasRead ? true : false}
            />
          )
        )}
      {showEmptyState && (
        <EmptyState
          icon={checkmarkOutline}
          header="No notifications"
          body="We will let you know when there's a new biosecurity emergency"
        />
      )}
      {showGuestScreen && (
        <GuestState
          body="Sign in to get notifications about Biosecurity emergencies"
          nextRoute={"/notifications"}
        />
      )}
    </AgResponsePage>
  );
};
