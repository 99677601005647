import { REACT_APP_API_URL } from "../env";
import axios from "axios";
import { Buffer } from "buffer";

export type TokenResponse = {
  sub: string;
  aud: string;
  email_verified: boolean;
  family_name: string;
  given_name: string;
  iss: string;
  exp: number;
  iat: number;
  email: string;
  jti: string;
  sid: string;
};

export async function getDecodedToken(code: string, state: string) {
  const { data } = await axios.post<TokenResponse>(
    `${REACT_APP_API_URL}/token`,
    { code, state },
    {
      headers: {
        "Content-Type": "application/json",
      },
    },
  );

  return data;
}

export const generateEmail = (sub: string) => {
  const subParts = Array(2)
    .fill("")
    .map((_, i) =>
      sub
        .replace(/[^a-zA-Z0-9]/g, "")
        .split("")
        .filter((_, j) => j % 2 === i)
        .join(""),
    );
  return `${subParts[0]}@${subParts[1]}.snsw`;
};

export const generatePassword = (sub: string) => {
  const pwa = Buffer.from(sub, "base64").toString("hex").substring(0, 10);
  const pwb = Buffer.from(sub, "base64").toString("base64").substring(0, 10);
  return `123${pwa}!@#${pwb}`;
};
