import { useEffect, useState } from "react";
import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { SwiperFlowChildProps } from "../../../components/SwiperFlow";
import { Input, Text } from "../../../components/ui";
import { getUser } from "../../../api/user";
import { useHistory } from "react-router-dom";
import { AddressResult } from ".";
import { PropertyAddressDetails } from "../../../project-types";
import { FlowFooterProps } from "../../../components/FlowFooter";

type ConfirmAddressProps = SwiperFlowChildProps & {
  selectedAddress: AddressResult | undefined;
  onAddressConfirmed: (
    address: Pick<
      PropertyAddressDetails,
      "address" | "city" | "postcode" | "location" | "name"
    >
  ) => void;
  initialAddress?: {
    name: string;
    address: string;
    city: string;
    postcode: string;
  };
  step?: string;
  footerProps?: Partial<FlowFooterProps>;
  onFinished?: () => void;
};

const ConfirmAddress = ({
  active,
  nextClickHandled,
  nextClicked,
  onStepFinished,
  updateFooter,
  visible,
  selectedAddress,
  onAddressConfirmed,
  initialAddress,
  step,
  footerProps,
  onFinished,
}: ConfirmAddressProps) => {
  const [addressFields, setAddressFields] = useState<PropertyAddressDetails>({
    name: "",
    city: "",
    address: "",
    postcode: "",
    location: {
      lat: 0,
      lng: 0,
    },
  });

  const history = useHistory();

  useEffect(() => {
    if (selectedAddress) {
      let location = {
        lat: selectedAddress.geo.geometry.coordinates[1],
        lng: selectedAddress.geo.geometry.coordinates[0],
      };

      const {
        postcode,
        streetName,
        streetNumber1,
        streetNumber2,
        streetType,
        localityName,
        complexUnitIdentifier,
        complexUnitType,
        siteName,
      } = selectedAddress.addressDetails;
      if (selectedAddress.addressDetails.formattedAddress !== "") {
        const unitString =
          `${complexUnitType ? complexUnitType : ""} ` +
          `${complexUnitIdentifier ? complexUnitIdentifier : ""} `;

        setAddressFields({
          name: siteName ? siteName : "",
          address: `${
            unitString.trim() !== "" ? unitString : ""
          }${streetNumber1}${
            streetNumber2 ? `-${streetNumber2}` : ""
          } ${streetName} ${streetType ? streetType : ""}`,
          city: localityName,
          postcode,
          location,
        });
      } else {
        setAddressFields({
          name: "",
          address: "",
          city: "",
          postcode: "",
          location,
          ...initialAddress,
        });
      }
    }
  }, [selectedAddress, initialAddress]);

  useEffect(() => {
    if (active && visible) {
      updateFooter({
        ...footerProps,
        disableNext: true,
      });

      if (addressFields) {
        if (
          addressFields.address !== "" &&
          addressFields.city !== "" &&
          addressFields.postcode !== ""
        ) {
          updateFooter({
            ...footerProps,
            disableNext: false,
          });
        } else {
          updateFooter({
            ...footerProps,
            disableNext: true,
          });
        }
      }
    }
  }, [active, visible, addressFields]);

  useEffect(() => {
    if (active && visible && nextClicked) {
      getUser().then((user) => {
        if (user === undefined) {
          history.push("/login");
          return;
        }

        onAddressConfirmed(addressFields);
        onStepFinished();
        nextClickHandled();
        onFinished?.();
      });
    }
  }, [nextClicked]);

  return (
    <>
      {selectedAddress ? (
        <div>
          <Text size="large" type="body">
            {step || "Step 2 of 5"}
          </Text>

          <Text
            type="display"
            size="small"
            as="h2"
            bold
            className="margin-y-16"
          >
            Confirm address
          </Text>

          <div className="margin-bottom-32">
            <Text type="body" size="large">
              Please make sure these details are correct. We use your address to
              help locate your property during an emergency.
            </Text>
          </div>
          <Input
            value={addressFields.address}
            label="Address"
            onChange={(value) => {
              setAddressFields({
                ...addressFields,
                address: value,
              });
            }}
          />

          <Input
            value={addressFields.city}
            label="City"
            onChange={(value) => {
              setAddressFields({
                ...addressFields,
                city: value,
              });
            }}
          />
          <IonGrid>
            <IonRow>
              <IonCol
                style={{
                  marginLeft: "-9px",
                }}
              >
                <Input value="NSW" label="State" disabled />
              </IonCol>
              <IonCol
                style={{
                  marginRight: "-9px",
                }}
              >
                <Input
                  label="Postcode"
                  value={addressFields.postcode}
                  onChange={(value) => {
                    setAddressFields({
                      ...addressFields,
                      postcode: value,
                    });
                  }}
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      ) : null}
    </>
  );
};

export default ConfirmAddress;
