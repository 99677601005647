import { useEffect, useCallback, useContext, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { formSubmission } from "../../api/form";
import { AgResponsePage } from "../../components/AgResponsePage";
import { submissionService, OneBlinkAppsError } from "@oneblink/apps";
import { v4 } from "uuid";
import { logAnalyticsEvent } from "../../api/firebase";
import useForm from "../../hooks/useForm";
import { IsOfflineContextProvider, OneBlinkForm } from "@oneblink/apps-react";
import "material-icons/iconfont/material-icons.css";
import "./Form.scss";
import { NewFormSubmission } from "@oneblink/apps/dist/types/submissions";
import { BottomSheetContext } from "../../components/ui/BottomSheet";
import Snackbar from "../../components/ui/Snackbar";
import { Network } from "@capacitor/network";
import { EmergencyFormSubmitSuccess } from "../../components/bottomSheet/EmergencyFormSubmitSuccess";

// TODO: Replace with our Google Maps API key
// A Google Maps API Key is required if the form contains a location form element.
// See https://github.com/oneblink/apps-react/blob/master/docs/OneBlinkForm.md#props
const googleMapsApiKey = "";

// Are we expected to handle other types of OneBlink form elements?
// e.g. An ABN Lookup Authentication Guid is required if the form contains a abn form element.
// e.g. A reCAPTCHA Site Key is required if the form contains a captcha form element.

type ExpectedSubroutes = {
  id: string;
  propertyId: string;
};

const FormPageContent: React.FC<RouteComponentProps<ExpectedSubroutes>> = ({
  match,
}) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { setBottomSheetProps } = useContext(BottomSheetContext);
  const history = useHistory();

  const propertyId = new URLSearchParams(window.location.search).get(
    "propertyId"
  );

  const { formData, formResponse } = useForm(match.params.id, propertyId);

  useEffect(() => {
    // OneBlink hijacks the scrolling in multi-page forms, and unfortunately
    // this means that the page gets scrolled down and causes some of the
    // bottom sheet to be displayed.
    // This is a workaround to completely disable the rendering of the bottom
    // sheet when a form is being displayed.
    // (`renderBottomSheet` will automatically be set back to true when the
    // bottom sheet is opened programatically on any other page)
    setBottomSheetProps((values) => ({
      ...values,
      renderBottomSheet: false,
    }));
  }, []);

  const submitForm = useCallback(
    async (newFormSubmission: NewFormSubmission) => {
      setErrorMessage(null);

      const { connected } = await Network.getStatus();

      if (!connected) {
        setErrorMessage(
          "You cannot submit this form while offline, please try again when connectivity is restored."
        );
        return;
      }

      const submissionId = v4();

      if (!formData) {
        setErrorMessage(
          "Form data is still loading. Please try again in a moment."
        );
        return;
      }

      try {
        Object.entries(newFormSubmission.submission).forEach(
          ([key, value]) => {
            if (typeof value === "string") {
              newFormSubmission.submission[key] = value.replace(/&/g, "&amp;")
              .replace(/</g, "&lt;")
              .replace(/>/g, "&gt;")
              .replace(/"/g, "&quot;");
            }
          }
        );

        const result = await submissionService.submit({
          formSubmission: {
            formsAppId: formData.formsAppIds[0],
            submission: newFormSubmission.submission,
            definition: formData,
            captchaTokens: [],
            draftId: null,
            jobId: null,
            preFillFormDataId: null,
            externalId: submissionId,
          },
          shouldRunExternalIdGeneration: false,
          shouldRunServerValidation: false,
          isPendingQueueEnabled: false,
        });

        if (!!result?.isOffline && !result?.isInPendingQueue) {
          setErrorMessage(
            "You cannot submit this form while offline, please try again when connectivity is restored."
          );

          throw new OneBlinkAppsError(
            "You cannot submit this form while offline, please try again when connectivity is restored.",
            { isOffline: true }
          );
        }

        logAnalyticsEvent("action complete", {
          formId: formData.id,
        });

        await formSubmission(match.params.id, formResponse, {
          submissionId,
          success: true,
        });

        setBottomSheetProps({
          isOpen: true,
          isCloseCtaVisible: false,
          children: <EmergencyFormSubmitSuccess />,
        });

        history.goBack();
      } catch (err) {
        try {
          await formSubmission(match.params.id, formResponse, {
            submissionId,
            success: false,
          });
        } catch (err) {
          /* */
        }

        console.error("error", err);
        setErrorMessage("Form submission failed. Please try again.");
      }
    },
    [formData, formResponse, history, match.params.id, setErrorMessage]
  );

  if (!formData) {
    return null;
  }

  return (
    <AgResponsePage
      title={formData ? formData.name : "Loading"}
      onButtonClick={() => {
        logAnalyticsEvent("action close", {
          formId: formData?.id,
        });
        history.goBack();
      }}
      hideCtas
    >
      <div
        className={`oneblink-form ${
          !!formData?.isMultiPage ? "multipage" : ""
        }`}
      >
        <OneBlinkForm
          googleMapsApiKey={googleMapsApiKey}
          form={formData}
          onCancel={() => false}
          onSubmit={submitForm}
          attachmentRetentionInDays={1}
          initialSubmission={formResponse}
        />
      </div>
      <Snackbar
        message={errorMessage || "Something went wrong"}
        isOpen={!!errorMessage}
        actionButton={{
          text: "OK",
          role: "cancel",
          handler: () => {
            setErrorMessage(null);
          },
        }}
      />
    </AgResponsePage>
  );
};

export const FormPage: React.FC<RouteComponentProps<ExpectedSubroutes>> = (
  props
) => (
  <IsOfflineContextProvider>
    <FormPageContent {...props} />
  </IsOfflineContextProvider>
);
