import { useEffect, useState } from "react";
import { CacheAndLiveData } from "../api/offlineCacher";

type DataSet<T> = {
  finished: boolean;
  data?: T;
  hasErrored: boolean;
};

export const useCacheLiveData = <T>(
  getter: (...args: any[]) => CacheAndLiveData<T>,
  args?: any[]
) => {
  const [dataSet, setDataSet] = useState<{
    cache: DataSet<T>;
    live: DataSet<T>;
  }>({
    cache: {
      finished: false,
      hasErrored: false,
      data: undefined,
    },
    live: {
      finished: false,
      hasErrored: false,
      data: undefined,
    },
  });

  useEffect(() => {
    const { cache, live } = getter(args ? args : []);
    cache.then((data) => {
      if (dataSet.live.finished || dataSet.cache.data !== undefined) return;
      setDataSet((old) => ({
        ...old,
        cache: { data, hasErrored: !data, finished: true },
      }));
    });

    live.then((data) => {
      setDataSet((old) => ({
        ...old,
        live: { data, hasErrored: !data, finished: true },
      }));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getter]);

  return {
    //prefer live, duh
    data: dataSet.live?.data || dataSet.cache?.data || undefined,
    state: {
      hasCacheLoaded: !!dataSet.cache?.finished,
      hasLiveLoaded: !!dataSet.live?.finished,
      haveBothLoaded: !!dataSet.cache?.finished && !!dataSet.live?.finished,
      hasLiveErrored: dataSet.live?.hasErrored,
    },
  };
};
