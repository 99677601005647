import { IonButton } from "@ionic/react";
import { logAnalyticsEvent } from "../../api/firebase";
import "./Button.scss";
import "./ServiceNswLoginButton.scss";
// import { v4 as uuidv4 } from "uuid";
// import { Browser } from "@capacitor/browser";
import { Auth } from "@aws-amplify/auth";
import Button from "./Button";
import clsx from "clsx";

// const { REACT_APP_SNSW_OAUTH_CLIENT_ID, REACT_APP_SNSW_OAUTH_URL } =
//   process.env;

type IonButtonProps = React.ComponentProps<typeof IonButton>;

export const loginWithSnsw = async () => {
  logAnalyticsEvent("login-with-snsw", {});
  Auth.federatedSignIn({
    customProvider: "ServiceNSW",
  });
};

const ServiceNswLoginButton = ({
  children,
  fill = "default",
  expand = "block",
  className = "",
  ...props
}: IonButtonProps) => (
  <>
    <Button
      variant="snsw"
      aria-label="Continue with Service New South Wales"
      className={clsx(className, "snsw-button")}
      onClick={loginWithSnsw}
      size="large"
      expand={expand}
      {...props}
    >
      Continue with Service NSW
    </Button>
    {/*
    <div className="snsw-container">
      <button
        aria-label="Continue with Service New South Wales"
        className="snsw-button"
        onClick={loginWithSnsw}
      >
        Continue with Service NSW
      </button>
    </div>
*/}
    <p className="ag-snsw-blurb">
      By continuing, you consent to sharing your name and email address in your
      MyServiceNSW Account with Department of Primary Industries.
    </p>
  </>
);

export default ServiceNswLoginButton;
