import { pencilOutline, saveOutline } from "ionicons/icons";
import Card from "./Card";
import { Text } from "./Text";
import { Input, InputProps } from "./Input";
import React, { useEffect, useState } from "react";
import Button from "./Button";

export type EditDetailsCardProps<T extends Record<string, string>> = {
  title?: string;
  editableFields: T;
  labels: Record<keyof T, string>;
  onSave?: (editedFields: T) => void;
  onEdit?: () => void;
  nonEditModeChildren?: React.ReactNode;
  editModeChildren?: React.ReactNode;
  textType?: InputProps["type"];
  editButtonText?: string;
  saveButtonText?: string;
  editButtonIcon?: string;
  saveButtonIcon?: string;
  hideSaveButton?: boolean;
  setEditMode?: boolean;
};

const EditDetailsCard = <T extends Record<string, string>>({
  title,
  editableFields,
  labels,
  onSave,
  onEdit,
  editModeChildren,
  nonEditModeChildren,
  textType,
  editButtonText,
  saveButtonText,
  editButtonIcon,
  saveButtonIcon,
  hideSaveButton,
  setEditMode,
}: EditDetailsCardProps<T>) => {
  const [edit, setEdit] = useState<boolean>(false);
  const [editedFields, setEditedFields] = useState<T>(editableFields);

  const handleSave = () => {
    if (editedFields === undefined) {
      return;
    }
    onSave && onSave(editedFields);
    setEdit(false);
  };
  useEffect(() => {
    if (setEditMode !== undefined) setEdit(setEditMode);
  }, [setEditMode]);

  return (
    <>
      {edit && editedFields !== undefined ? (
        <>
          {Object.keys(editedFields).length > 0 &&
            Object.entries(editedFields).map(([key, value]) => (
              <div key={key} className="margin-top-8">
                <Input
                  fullWidth
                  placeholder={
                    value !== "Not provided" ? `Enter ${labels[key]}` : value
                  }
                  value={value}
                  label={labels[key]}
                  type={textType && textType}
                  onChange={(value) => {
                    setEditedFields((prevFields) => ({
                      ...prevFields,
                      [key]: value,
                    }));
                  }}
                />
              </div>
            ))}
          {editModeChildren}
          {!hideSaveButton && (
            <Button
              icon={!saveButtonIcon ? saveOutline : saveButtonIcon}
              expand="block"
              onClick={handleSave}
              className="margin-top-32"
            >
              {saveButtonText ? saveButtonText : "Save"}
            </Button>
          )}
        </>
      ) : (
        <Card
          title={title}
          rightIcon
          titleIcon={!editButtonIcon ? pencilOutline : editButtonIcon}
          onIconClick={() => {
            setEdit(true);
            onEdit && onEdit();
          }}
        >
          {Object.entries(editableFields || {}).map(([key, value]) => (
            <div key={`${key}-${value}`} className="margin-top-16">
              <Text type="body" size="small" as="p">
                {labels[key]}
              </Text>
              <Text type="body" size="large" as="p">
                {value === "" || !value ? "Not provided" : value}
              </Text>
            </div>
          ))}
          {nonEditModeChildren}
          <Button
            className="margin-y-16"
            onClick={() => {
              setEdit(true);
              onEdit && onEdit();
            }}
            expand="block"
            icon={!editButtonIcon ? pencilOutline : editButtonIcon}
            iconPosition="end"
          >
            {editButtonText ? editButtonText : "Edit"}
          </Button>
        </Card>
      )}
    </>
  );
};

export default EditDetailsCard;
