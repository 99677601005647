import React, { useContext, useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router";
import { IonRouterOutlet } from "@ionic/react";
import { Route } from "react-router-dom";
import { getEmergencyDetails } from "../../api/emergencies";
import { AgResponsePage } from "../../components/AgResponsePage";
import Support from "./Support";
import Latest from "./Latest";
import About from "./About";
import Overview from "./Overview";
import Map from "./Map";
import { SnackbarHandlerContext } from "../../components/SnackbarHandler";
import { useCacheLiveData } from "../../hooks/useCacheLiveData";
import { getProperties } from "../../api/user";
import { warning, warningOutline } from "ionicons/icons";
import EmptyState from "../../components/ui/EmptyState";
import FullscreenLoader from "../../components/ui/FullscreenLoader";
import "./index.scss";

type ExpectedSubroutes = {
  id: string;
};

export const EmergencyPage: React.FC<RouteComponentProps<ExpectedSubroutes>> =
  ({ match }) => {
    const [title, setTitle] = useState<string>("loading");
    const { id } = match.params;
    const history = useHistory();
    const { pathname } = history.location;
    const [scrollToZone, setScrollToZone] = useState<number | null>(null);
    const { showDismissableSnackbar } = useContext(SnackbarHandlerContext);

    const scrollAndSelectZone = (zoneNumber: number) => {
      setScrollToZone(zoneNumber);
    };

    const {
      data: emergencyData,
      state: { hasLiveErrored, haveBothLoaded },
    } = useCacheLiveData(getEmergencyDetails, [id]);
    const { data: propertiesData } = useCacheLiveData(getProperties);

    useEffect(() => {
      if (hasLiveErrored) {
        showDismissableSnackbar("Error retrieving new all emergencies");
        return;
      }

      if (!emergencyData) return;

      switch (pathname) {
        case `/emergency/${id}/support`:
          setTitle("Support");
          break;
        case `/emergency/${id}/latest`:
          setTitle("Latest updates");
          break;
        case `/emergency/${id}/about`:
          setTitle("About");
          break;
        case `/emergency/${id}/map`:
          setTitle("Emergency zones map");
          break;
        default:
          setTitle(
            emergencyData.emergencyTitle ? emergencyData.emergencyTitle : " "
          );
          break;
      }

      // get teh selected zone from the query string
      const urlParams = new URLSearchParams(window.location.search);
      const zone = urlParams.get("zone");
      if (zone) {
        setScrollToZone(parseInt(zone));
      }
    }, [emergencyData, pathname]);

    return (
      <AgResponsePage
        title={title}
        onButtonClick={() => {
          switch (history.location.pathname) {
            case `/emergency/${id}`:
              history.push("/emergencies");
              break;
            default:
              history.push(`/emergency/${id}`);
              break;
          }
          setScrollToZone(null);
        }}
        noPadding
        hideCtas
      >
        {emergencyData === undefined && haveBothLoaded && (
          <EmptyState
            {...(hasLiveErrored
              ? {
                  icon: warning,
                  header: "Offline Mode",
                  body: "We are unable to retrieve this emergency. Please check your internet connection.",
                }
              : {
                  icon: warningOutline,
                  body: "No emergency information available.",
                })}
          />
        )}
        {emergencyData === undefined && !haveBothLoaded && <FullscreenLoader />}
        <IonRouterOutlet className={"emergency-route-wrapper"}>
          <Route
            path="/emergency/:id/map"
            render={(routeProps: RouteComponentProps<{ id: string }>) => (
              <Map
                {...routeProps}
                scrollAndSelectZone={scrollAndSelectZone}
                emergencyData={emergencyData}
                properties={propertiesData}
              />
            )}
            exact
          />
          <Route
            path="/emergency/:id/"
            render={(routeProps: RouteComponentProps<{ id: string }>) => (
              <Overview
                {...routeProps}
                scrollToZone={scrollToZone}
                emergencyData={emergencyData}
                properties={propertiesData}
              />
            )}
            exact
          />
          <Route
            path={"/emergency/:id/support"}
            render={(routeProps: RouteComponentProps<{ id: string }>) => (
              <Support {...routeProps} />
            )}
            exact
          />
          <Route
            path={"/emergency/:id/latest"}
            render={(routeProps: RouteComponentProps<{ id: string }>) => (
              <Latest {...routeProps} />
            )}
            exact
          />
          <Route
            path="/emergency/:id/about"
            render={(routeProps: RouteComponentProps<{ id: string }>) => (
              <About {...routeProps} />
            )}
            exact
          />
        </IonRouterOutlet>
      </AgResponsePage>
    );
  };
