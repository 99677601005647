export const animals = [
  "Alpaca",
  "Beehives",
  "Bison",
  "Buffalo",
  "Camels",
  "Cattle, beef",
  "Cattle, dairy",
  "Deer",
  "Emus",
  "Fisheries",
  "Goats",
  "Horses",
  "Llamas",
  "Mussels",
  "Ostrich",
  "Oysters",
  "Pigs",
  "Poultry",
  "Prawns",
  "Sheep",
];

export const crops = [
  "Avocado",
  "Banana",
  "Berries",
  "Fibre",
  "Fruit trees",
  "Grain",
  "Grapes",
  "Hardwood",
  "Nuts",
  "Oilseed",
  "Pasture",
  "Pulses",
  "Softwood",
  "Sugarcane",
  "Turf",
  "Vegetables",
  "Virgin bush",
];
