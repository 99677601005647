import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { IonInput } from "@ionic/react";
import { Card, Input, Text } from "../../components/ui";
import Button from "../../components/ui/Button";
import Checkbox from "../../components/ui/Checkbox";
import {
  handleLogin,
  handleSignup,
  handleEmailVerification,
} from "../../api/cognito";
import VerificationCode from "../../components/ui/VerificationCode";
import { useHistory } from "react-router-dom";
import { logAnalyticsEvent } from "../../api/firebase";
import Divider from "../../components/ui/Divider";
import { isPasswordValid } from "../../utils/passwordValidation";
import PasswordValidation from "../../components/PasswordValidation";
import Note from "../../components/ui/Note";
import { GlobalContentDetails } from "../../project-types";
import { getGlobalContent } from "../../api/emergencies";
import { BottomSheetContext } from "../../components/ui/BottomSheet";
import { AccountCreatedState } from "../../components/bottomSheet/AccountCreated";
import { SnackbarHandlerContext } from "../../components/SnackbarHandler";

type RegisterProps = {
  email: string;
  initialState?: "register" | "confirm";
  firstName?: string;
  lastName?: string;
  isSnsw?: boolean;
  onSnswSubmit?: (fields: RegistrationFields) => void;
};

type RegistrationFields = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  tAndC: boolean;
  thirdParty: boolean;
};

const trackAnalytics = (email: string, isThirdPartyEnabled = false) => {
  try {
    logAnalyticsEvent("Account registration", { email });

    if (isThirdPartyEnabled) {
      logAnalyticsEvent("RAA-data-sharing opt-in", {});
    }
  } catch (err) {
    // Don't fail if analytics fails
  }
};

export const Register = ({
  email,
  initialState = "register",
  firstName = "",
  isSnsw = false,
  lastName = "",
  onSnswSubmit,
}: RegisterProps) => {
  const history = useHistory();
  const [state, setState] = useState<"register" | "confirm">(initialState);
  const [userSub, setUserSub] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [isValidCode, setIsValidCode] = useState<boolean>(false);
  const [privacyCollectionNoticeUrl, setPrivacyCollectionNoticeUrl] =
    useState<GlobalContentDetails["contentPrivacyCollectionId"]>();
  const { showDismissableSnackbar } = useContext(SnackbarHandlerContext);

  const formElements: Array<
    {
      key: keyof RegistrationFields;
      placeholder?: string;
      label: React.ReactNode;
      inputType: "text" | "password" | "checkbox";
    } & Pick<React.ComponentProps<typeof IonInput>, "autocomplete">
  > = [
    {
      key: "firstName",
      placeholder: "Enter your first name",
      label: "First Name",
      inputType: "text",
      autocomplete: "given-name",
    },
    {
      key: "lastName",
      placeholder: "Enter your last name",
      label: "Last Name",
      inputType: "text",
      autocomplete: "family-name",
    },
    {
      key: "password",
      placeholder: "Enter password",
      label: "Password",
      inputType: "password",
      autocomplete: "new-password",
    },
    {
      key: "tAndC",
      label: (
        <>
          I have read and agreed to the{" "}
          <a
            href={privacyCollectionNoticeUrl}
            target="_blank"
            rel="noreferrer"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            Privacy Collection Notice
          </a>
          .
        </>
      ),
      inputType: "checkbox",
    },
    {
      key: "thirdParty",
      label: (
        <>
          I agree to my profile and information being shared with the{" "}
          <a
            href="https://www.raa.nsw.gov.au/"
            target="_blank"
            rel="noreferrer"
          >
            Rural Assistance Authority
          </a>{" "}
          for the purpose of recovery support.
        </>
      ),
      inputType: "checkbox",
    },
  ];

  useEffect(() => {
    let isMounted = true;
    const getGlobalContentDetails = async () => {
      try {
        const globalContent = await getGlobalContent();
        if (!isMounted) return;
        setPrivacyCollectionNoticeUrl(
          `https://www.dpi.nsw.gov.au/?a=${globalContent.contentPrivacyCollectionId}`
        );
      } catch (err) {
        console.error("Error ", err);

        showDismissableSnackbar("Error getting global content");
      }
    };
    getGlobalContentDetails();
    return () => {
      isMounted = false;
    };
  }, []);

  const [registration, setRegistration] = useState<RegistrationFields>({
    firstName,
    lastName,
    email,
    password: "",
    tAndC: false,
    thirdParty: false,
  });

  const canContinue =
    state === "confirm"
      ? isValidCode
      : (isPasswordValid(registration.password) || isSnsw) &&
        registration.tAndC;

  const buttonText = state === "confirm" ? "Continue" : "Agree and continue";

  const handleChange = useCallback(
    (key: keyof RegistrationFields) => (value: string | boolean) => {
      setRegistration((prevValues) => ({
        ...prevValues,
        [key]: value,
      }));
    },
    [setRegistration]
  );

  const handleRegister = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setLoading(true);

      if (isSnsw) {
        onSnswSubmit && onSnswSubmit(registration);
        return;
      }

      const failWithError = (err: any, message = "Cannot register user.") => {
        console.error(err);
        setError(message);
        setLoading(false);
      };

      let newUserSub = await handleSignup(email, registration.password)
        .then((sub) => sub)
        .catch((err) => {
          console.error(err);
          return undefined;
        });

      if (!newUserSub) {
        return failWithError({}, "Cannot sign up user.");
      }
      setUserSub(newUserSub);

      trackAnalytics(email, registration.thirdParty);
      setState("confirm");
      setLoading(false);
    },
    [email, history, registration]
  );

  const { setBottomSheetProps } = useContext(BottomSheetContext);

  const handleConfirm = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      setBottomSheetProps({
        isOpen: true,
        isCloseCtaVisible: false,
        children: <AccountCreatedState />,
      });

      history.push("/emergencies");
    },
    [history]
  );

  const verificationService = useCallback(
    (code: string) =>
      handleEmailVerification(
        userSub ? userSub : "",
        email,
        registration.firstName,
        registration.lastName,
        "", //phone
        code,
        false, //emailPreferred
        "", //snswId
        "", //ABN
        registration.thirdParty,
        "" //pushTokens
      ),
    [email, registration, userSub]
  );

  const handleVerificationCode = useCallback(async () => {
    try {
      setLoading(true);
      await handleLogin(email, registration.password);
      setIsValidCode(true);
    } catch (err) {
      console.error(err);
      setError("Invalid verification code.");
    }
    setLoading(false);
  }, [email, registration.password]);

  return (
    <form
      method="post"
      onSubmit={state === "confirm" ? handleConfirm : handleRegister}
    >
      {state === "register" && (
        <>
          <Text type="body" size="medium" shade="grey" className="padding-4">
            Email
          </Text>
          <Text type="body" size="large" className="padding-4 margin-bottom-8">
            {email}
          </Text>
          {formElements
            // .filter(({ key }) => (isSnsw ? key !== "password" : true))
            .map(
              ({ key, placeholder, label, inputType, autocomplete }, index) => {
                if (key === "password" && isSnsw) {
                  return (
                    <Card title="Personal details" variant="info">
                      <Text size="medium" type="body">
                        We’ve pre-filled your personal details from your
                        MyServiceNSW Account. Any changes you make here will not
                        update your MyServiceNSW Account.
                      </Text>
                    </Card>
                  );
                }

                if (inputType === "checkbox") {
                  return (
                    <Checkbox
                      key={key}
                      checked={!!registration[key]}
                      onChange={handleChange(key)}
                      interactiveLabel
                    >
                      <Text type="body" size="medium" as="p">
                        {label}
                      </Text>
                    </Checkbox>
                  );
                }

                return (
                  <Fragment key={key}>
                    <Input
                      name={key}
                      type={inputType}
                      label={
                        /*dodgy type casting to make it work. doesn't seem to break anything yet*/
                        label as any
                      }
                      value={`${registration[key]}`}
                      onChange={handleChange(key)}
                      placeholder={placeholder}
                      className="margin-bottom-12"
                      autocomplete={autocomplete}
                      autofocus={index === 0}
                    />
                    {key === "password" && (
                      <>
                        <PasswordValidation password={registration.password} />
                        <Divider className="margin-y-32" />
                      </>
                    )}
                  </Fragment>
                );
              }
            )}
        </>
      )}

      {state === "confirm" && (
        <>
          <Text type="body" size="medium" as="p">
            Please enter the verification code sent to your email address.
          </Text>
          <VerificationCode
            onVerified={handleVerificationCode}
            service={verificationService}
          />
        </>
      )}

      {error && <Note error>{error}</Note>}

      <Button
        type="submit"
        variant="primary"
        expand="block"
        disabled={!canContinue || !!error}
        loading={loading}
        className="margin-top-24"
      >
        {buttonText}
      </Button>
    </form>
  );
};
