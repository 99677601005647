import { useMemo } from "react";
import { BottomSheetWithCta } from "./BottomSheetWithCta";
import { ButtonPropVariant } from "../ui";
import { setNotificationsFlag } from "../../api/localStorage";
import { setupNotifications } from "../../api/firebase";
import { Notifications } from "../svgs";
import "./NotificationsCta.scss";

export const NotificationsCta = () => {
  const addPropertyButton = useMemo(() => {
    return {
      children: "Yes",
      onClick: async () => {
        setNotificationsFlag("yes");
        setupNotifications();
      },
    };
  }, []);
  /*
  const doThisLaterButton = useMemo(() => {
    return {
      children: "Ask again later",
      variant: "secondary" as ButtonPropVariant,
      onClick: () => {
        setNotificationsFlag("maybe");
      },
    };
  }, []);
*/
  const doNotAskAgainButton = useMemo(() => {
    return {
      children: "Not now",
      variant: "secondary" as ButtonPropVariant,
      onClick: () => {
        setNotificationsFlag("no");
      },
    };
  }, []);

  return (
    <BottomSheetWithCta
      icon={
        <div className="notification-icon-wrapper">
          <div className="notifications-unread" />
          <Notifications className="icon" />
        </div>
      }
      header={"Turn on push notifications?"}
      body={
        "Turn on push notifications for real-time alerts. You can also do this later in the Settings tab."
      }
      buttons={[addPropertyButton, doNotAskAgainButton]}
    />
  );
};
