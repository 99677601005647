import React from "react";
import { IonButton, IonIcon, IonText } from "@ionic/react";
import clsx from "clsx";
import "./Button.scss";

export type ButtonPropVariant =
  | "primary"
  | "secondary"
  | "tertiary"
  | "light"
  | "info"
  | "success"
  | "success-alt"
  | "outline"
  | "clear"
  | "snsw"
  | "link";
export type ButtonProps = React.ComponentProps<typeof IonButton> & {
  variant?: ButtonPropVariant;
  icon?: string;
  iconPosition?: "start" | "end";
  size?: "small" | "default" | "large";
  className?: string;
  loading?: boolean;
};

const Button = ({
  variant = "primary",
  icon,
  iconPosition,
  children,
  size,
  className = "",
  loading = false,
  disabled = false,
  ...props
}: ButtonProps) => {
  const buttonClassNames = clsx(
    "ag-button",
    `ag-button-${variant || "primary"}`,
    {
      [`ag-button-${size}`]: size,
      "ag-button-loading": loading,
    },
    className
  );

  return (
    <IonButton
      className={buttonClassNames}
      color={variant}
      fill={variant === ("outline" || "clear") ? "outline" : "default"}
      disabled={disabled || loading}
      {...props}
    >
      <IonText color={variant === "tertiary" ? "dark" : "light"}>
        {children}
      </IonText>
      {icon && (
        <IonIcon
          icon={icon}
          slot={iconPosition ? iconPosition : !children ? "icon-only" : "start"}
        />
      )}
    </IonButton>
  );
};

export default Button;
