import React from "react";
import { IonIcon, IonNote } from "@ionic/react";
import clsx from "clsx";
import { checkmarkCircle } from "ionicons/icons";
import { Text } from "./Text";
import "./Note.scss";

type NoteProps = React.ComponentProps<typeof IonNote> & {
  error?: boolean;
  success?: boolean;
};

const Note = ({
  error = false,
  success = false,
  className,
  children,
  ...props
}: NoteProps) => (
  <IonNote
    className={clsx(
      "ag-note",
      error && "ag-note-error",
      success && "ag-note-success",
      className,
    )}
    {...props}
  >
    {error && <IonIcon icon="/assets/error-message.svg" />}
    {success && <IonIcon icon={checkmarkCircle} />}
    <Text type="heading" size="small" color="primary">
      {children}
    </Text>
  </IonNote>
);

export default Note;
