export const passwordRequirements = {
  length: {
    label: "At least 8 characters",
    validate: (password: string) => password.length > 8,
  },
  mixedCase: {
    label: "Lowercase & uppercase letter",
    validate: (password: string) =>
      !!password.match(/[a-z]/g) && !!password.match(/[A-Z]/g),
  },
  number: {
    label: "Number",
    validate: (password: string) => !!password.match(/[0-9]/g),
  },
  special: {
    label: "Symbol",
    validate: (password: string) => !!password.match(/[^a-zA-Z0-9\d]/g),
  },
} as const;

export type PasswordValidation = Record<
  keyof typeof passwordRequirements,
  boolean
>;

export const passwordValidation = (password: string = "") =>
  Object.fromEntries(
    Object.entries(passwordRequirements).map(([key, value]) => [
      key,
      value.validate(password),
    ]),
  ) as PasswordValidation;

export const isPasswordValid = (password: string = "") => {
  const validation = passwordValidation(password);
  return Object.values(validation).every(Boolean);
};
