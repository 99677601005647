import { personOutline, stopwatchOutline } from "ionicons/icons";
import { Text } from "../../components/ui/Text";
import { Tag } from "../../components/ui/Tag";
import { SwiperFlowChildProps } from "../../components/SwiperFlow";
import { useEffect } from "react";
import { Card } from "../../components/ui";
import { IonCol, IonGrid, IonIcon, IonList, IonRow } from "@ionic/react";
import { Emergencies, Notifications } from "../../components/svgs";
import "./Landing.scss";

const listCopy = [
  {
    icon: <Emergencies />,
    copy: "Get alerts for new biosecurity outbreaks near you.",
  },
  {
    icon: <Notifications />,
    copy: "Stay updated on emergencies as they change.",
  },
  {
    icon: <IonIcon icon={personOutline} color="primary" />,
    copy: "Understand what you need to do during an emergency.",
  },
];

export const Landing = ({
  active,
  nextClickHandled,
  nextClicked,
  onStepFinished,
  updateFooter,
  visible,
}: SwiperFlowChildProps) => {
  useEffect(() => {
    if (active && visible) {
      updateFooter({
        showBack: false,
        disableNext: false,
      });
    }
  }, [active, visible]);

  useEffect(() => {
    if (visible && nextClicked) {
      onStepFinished();
      nextClickHandled();
    }
  }, [active, nextClicked]);

  return visible ? (
    <>
      <Text
        type="display"
        size="medium"
        as="h2"
        className="margin-top-0 margin-bottom-32"
      >
        Add a property to your profile
      </Text>
      <Tag endIcon={stopwatchOutline} variant="secondary">
        <strong>Estimated time: 3 mins</strong>
      </Tag>
      <IonList className="margin-y-32">
        {listCopy.map(({ icon, copy }) => (
          <Card key={copy} variant="primary">
            <IonGrid>
              <IonRow className="ion-align-items-center">
                <IonCol size="auto" className="list-item-icon">
                  {icon}
                </IonCol>
                <IonCol>
                  <Text type="body" size="large" as="p" bold>
                    {copy}
                  </Text>
                </IonCol>
              </IonRow>
            </IonGrid>
          </Card>
        ))}
      </IonList>
    </>
  ) : null;
};
