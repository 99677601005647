import { useMemo } from "react";
import { BottomSheetWithCta } from "./BottomSheetWithCta";

export const ConfirmDeleteProperty = ({
  onConfirm,
}: {
  onConfirm: () => void;
}) => {
  const yesButton = useMemo(() => {
    return {
      children: "Yes, delete",
      onClick: onConfirm,
    };
  }, []);
  const noButton = useMemo(() => {
    return {
      variant: "tertiary",
      children: "No, cancel",
      onClick: () => {},
    };
  }, []);

  return (
    <BottomSheetWithCta
      header={"Delete this property?"}
      body={"Are you sure? You'll have to enter all the details again."}
      buttons={[yesButton, noButton]}
    />
  );
};
